import * as React from "react";
import { Formik, Form } from "formik";
import {
  Box,
  Button,
  CardActions,
  Fab,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import * as Yup from "yup";
import { a11yProps, TabPanel } from "../Definition/DefinitionEditor";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router";
import PromptIfDirty from "../common/PromptIfDirty";

type ProfileProps = {
  userinfo: {
    firstName: string;
    lastName: string;
  };
  password: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
  handleFormikUserProfile: (values: any) => void;
  handleFormSubmit: (values: any) => void;
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("This field is required"),
  newPassword: Yup.string()
    .required("This field is required")
    .min(6, "Must be at least 6 characters long")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, "Must have a number, Uppercase, Lowercase and special case character"),
  confirmPassword: Yup.string()
    .when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("newPassword")], "Both password need to be the same"),
    })
    .required("This field is required")
    .min(6, "Must be at least 6 characters long")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, "Must have a number, Uppercase, Lowercase and special case character"),
});

const profileValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

const UserProfile: React.FC<ProfileProps> = ({
  userinfo,
  password,
  handleFormikUserProfile,
  handleFormSubmit,
}) => {
  const history = useHistory();
  const [tableValue, setTableValue] = React.useState(0);

  // Set Tab value when changed
  const handleChangeTabBar = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTableValue(newValue);
  };

  const navigateBack = () => {
    history.goBack();
  };

  const userProfileForm = () => {
    return (
      <Formik
        enableReinitialize // required in order to reinitialize after adding more questions
        initialValues={userinfo!}
        validationSchema={profileValidationSchema}
        onSubmit={handleFormikUserProfile}
      >
        {({ values, dirty }) => {
          return (
            <Form id="userForm">
              <PromptIfDirty />
              <Paper style={{ padding: "10px" }}>
                <Grid container spacing={4}>
                  <Grid container item justify="center" xs={12}>
                    <Typography variant="h5">Update user info</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="firstName"
                      value={values?.firstName!}
                      type="input"
                      placeholder="First Name"
                      label="First Name *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="lastName"
                      value={values?.lastName!}
                      type="input"
                      placeholder="Last Name"
                      label="Last Name *"
                      fullWidth
                    />
                  </Grid>
                  <Grid container spacing={1} justify="flex-end" item xs={12}>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!dirty}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const changePassword = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={password}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <PromptIfDirty />
              <Paper style={{ padding: "10px" }}>
                <Grid container spacing={4}>
                  <Grid container item justify="center" xs={12}>
                    <Typography variant="h5">Change your password</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="currentPassword"
                      value={values.currentPassword}
                      type="password"
                      placeholder="Current Password"
                      label="Current Password *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="newPassword"
                      value={values.newPassword}
                      type="password"
                      placeholder="New Password"
                      label="New Password *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      type="password"
                      placeholder="Confirm Password"
                      label="Confirm Password *"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end">
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty}
                    >
                      Save
                    </Button>
                  </CardActions>
                </Box>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Grid container justify="center">
      <Grid container item xs={12} sm={7} justify="center">
        <Grid item xs={12} sm={1}>
          <Fab onClick={navigateBack} size="small">
            <Tooltip title="Back" aria-label="Back">
              <ArrowBack />
            </Tooltip>
          </Fab>
        </Grid>

        <Grid container justify="center" item xs={12} sm={11}>
          <Tabs
            value={tableValue}
            onChange={handleChangeTabBar}
            aria-label="tabs menu"
          >
            <Tab label="Edit Info" {...a11yProps(0)} />
            <Tab label="Reset Password" {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={7}>
        <TabPanel value={tableValue} index={0}>
          {userProfileForm()}
        </TabPanel>
      </Grid>
      <Grid container item xs={12} sm={7}>
        <TabPanel value={tableValue} index={1}>
          {changePassword()}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
