import * as React from "react";
import { Formik, Form } from "formik";
import { Box, Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { AuthenticateCommand } from "../../helpers/ApiResources";
import AuthContext from "../../context/Authentication/AuthContext";
import LoadingProgressCustom from "../common/LoadingProgressCustom";
import { Redirect, useHistory } from "react-router";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {},
  cardSize: {
    maxWidth: 400,
  },
  errorMessage: {
    color: "red",
  },
  marginText: {
    paddingBottom: "3em",
  },
  cardDivContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    alignItems: "center",
  },
});

const LoginForm: React.FC = () => {
  let history = useHistory();
  const classes = useStyles();
  const user = useAuth(); // User state Context

  const initalValues: AuthenticateCommand = { username: "", password: "" };

  const validationSchema = Yup.object({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("password is required"),
  });

  const handleFormSubmit = (values: AuthenticateCommand) => {
    user.loginUser(values);
  };

  React.useEffect(() => {
    if (user.user?.token) {
      <Redirect to="/dashboard" />;
    }
  }, [user, history]);

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Formik
          enableReinitialize
          initialValues={initalValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, isSubmitting, dirty, isValid }) => {
            return (
              <Form style={{width:"33em"}}>
                <Paper style={{padding:"2em"}}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        Sign in
                      </Typography>
                    </Grid>

                    <Grid item>
                      <TextFieldWithValidation
                        as={TextField}
                        name="username"
                        value={values.username}
                        type="input"
                        placeholder="Username"
                        label="Username *"
                        fullWidth
                      />
                    </Grid>

                    <Grid item>
                      <TextFieldWithValidation
                        as={TextField}
                        className={classes.marginText}
                        name="password"
                        value={values.password}
                        type="password"
                        placeholder="Password"
                        label="Password *"
                        fullWidth
                      />
                    </Grid>

                    <Grid container item direction="row" justify="space-between" alignItems="baseline">
                      <Link to="/forgotpassword">
                       <Typography variant="subtitle2" color="primary">
                      Forgot password?
                      </Typography>
                      </Link>
                   
                      
                      <Button type="submit" variant="contained" color="primary">
                      <LoadingProgressCustom isLoading={user.loading} size={25} marginTop={0} color="secondary"/>
                        Sign in
                      </Button> 
                    </Grid>
                  </Grid>
                </Paper> 
              </Form>
            );
          }}
        </Formik>
      </Box>
    </div>
  );
};

export default LoginForm;
