import * as React from "react";
import { Query, QueryResult } from "material-table";
import { useHistory } from "react-router";
import { Edit, Business, Add, Delete } from "@material-ui/icons";
import { Box, Button } from "@material-ui/core";
import { Client, SiteSummaryModel } from "../../helpers/ApiResources";
import { useConfirm } from "material-ui-confirm";
import CreateSiteDialog from "../Dialogs/CreateSiteDialog";
import useAsyncTask from "../../hooks/useAsyncTask";
import TableCustom from "../controls/TableCustom";

const SitesList: React.FC = () => {
  const history = useHistory();
  const confirm = useConfirm();
  const tableRef = React.useRef<any>();

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const [sites, setSites] = React.useState<SiteSummaryModel[]>([]);
  const [siteId, setSiteId] = React.useState<string>();

  const loadSiteTask = useAsyncTask(async (c: Client, q: Query<object>) => {
    const d = String(q.orderDirection);
    const sortBy = q.orderBy !== undefined ? String(q.orderBy.field) : "";
    const sortDirection = d === "" || d === "asc" ? "Ascending" : "Descending";

    return await c.site_Search(
      q.page * q.pageSize,
      q.pageSize,
      sortBy,
      sortDirection,
      q.search
    );
  });

  const deleteSiteTask = useAsyncTask(async (c: Client, id: string) => {
    return await c.site_Delete(id);
  });

  const loadSites = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadSiteTask
        .run(query)
        .then((response) => {
          if (response) {
            console.log(response, "response")
            const r = response.result;
            setSites(r.data);
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords!,
            });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const handleEdit = (
    event: React.MouseEvent<unknown>,
    data: SiteSummaryModel
  ) => {
    setOpenDialog(true);
    setSiteId(data.id);
  };

  const handleClone = () => {
    setOpenDialog(true);
  };

  const handleDelete = (
    event: React.MouseEvent<unknown>,
    data: SiteSummaryModel
  ) => {
    confirm({
      title: "Please Confirm",
      description: "Are you sure that you want to delete " + data.name + "?",
      confirmationText: "YES",
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
        disableElevation: true,
      },
      cancellationButtonProps: {
        variant: "contained",
        disableElevation: true,
      },
    })
      .then(() => {
        event.preventDefault();

        deleteSiteTask
          .run(data.id)
          .then(() => {
            const updatedSites = sites.filter((site) => site.id !== data.id);
            setSites([...updatedSites]);
            tableRef.current.onQueryChange();
          })
          .catch(() => {
            console.table(deleteSiteTask.validationErrors);
          });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      });
  };

  const viewSurveys = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("sites/" + data.id + "/surveys");
  };

  return (
    <>
      <Box mb={1} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={handleClone}
          startIcon={<Add />}
        >
          New Site
        </Button>
      </Box>

      <TableCustom
        tableRef={tableRef}
        title="Sites"
        columns={[
          { title: "Name", field: "name" },
          { title: "Description", field: "description" },
        ]}
        data={loadSites}
        moreAction={[
          {
            icon: () => <Edit />,
            tooltip: "Edit",
            onClick: handleEdit,
          },
          {
            icon: () => <Business />,
            tooltip: "Surveys",
            onClick: viewSurveys,
          },
          {
            icon: () => <Delete />,
            tooltip: "Delete",
            onClick: handleDelete,
          },
        ]}
      />

      <CreateSiteDialog
        tableRef={tableRef}
        open={openDialog}
        onClose={setOpenDialog}
        siteId={siteId}
      />
    </>
  );
};

export default SitesList;
