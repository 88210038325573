import * as React from 'react';
import DefinitionDataTable from '../../components/Definition/DefinitionDataTable';
import { Box, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  box: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const DefinitionListPage : React.FC = () => {
  const classes = useStyles();
  
  // Instead of loading all data on page load, let datatable handle server-side processing (pagination/searching)
  // React.useEffect(
  //   () => { loadDefinitionList()}, 
  //   [] // providing empty array so that useEffect will only run once, as value of [] does not change.
  // ); 
  // const client = ApiClientFactory.GetDefinitionApiClient();
  // const [definitionList, setDefinitionList] = useState<DefinitionSummaryModel[]>([]);

  // const loadDefinitionList = () => {
  //   client.getAll().then((response) => {
  //     setDefinitionList(response);
  //   });
  // };

  return (
    <>
      {/* TODO : add breadcrumbs */}
      {/* TODO : move the toolbar to its own component */}
      <div className={classes.root}>
        <Box className={classes.box}>
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/definitions/create"
            startIcon={<AddIcon />}
          >
            New Definition
          </Button>

        </Box>
      </div>
        
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DefinitionDataTable />
        </div>
      </div>
    </>
  );
}

export default DefinitionListPage;