import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Query, QueryResult } from "material-table";
import { Client } from "../../../helpers/ApiResources";

import Edit from "@material-ui/icons/Edit";
import AssignmentIcon from "@material-ui/icons/Assignment";
import useAsyncTask from "../../../hooks/useAsyncTask";
import TableCustom from "../../controls/TableCustom";

type Props = {
  surveyId: string;
};

const RecommissionSheetListDataTable: React.FC<Props> = (props) => {
  const history = useHistory();
  const tableRef = useRef<any>();

  const loadRecommissionTask = useAsyncTask(
    async (c: Client, q: Query<object>) => {
      const d = String(q.orderDirection);
      const sortBy = q.orderBy !== undefined ? String(q.orderBy.field) : "";
      const direction = d === "" || d === "asc" ? "Ascending" : "Descending";

      return await c.recommissionSheet_Search(
        q.page * q.pageSize,
        q.pageSize,
        sortBy,
        direction,
        props.surveyId,
        q.search
      );
    }
  );

  const loadRecommission = (
    query: Query<object>
  ): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadRecommissionTask
        .run(query)
        .then((response) => {
          if (response) {
            const r = response.result;
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords!,
            });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    history.push({ pathname: "/recommission/" + data.id });
  };

  const handleFulfill = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("/surveys/" + data.id + "/fulfillrecommission");
  };

  return (
    <TableCustom
      tableRef={tableRef}
      title="Recommission"
      columns={[
        { title: "Name", field: "name" },
        { title: "Assignee", field: "users[0].fullName" },
        { title: "Status", field: "recommissionSheetStatus" },
      ]}
      data={loadRecommission}
      moreAction={[
        {
          icon: () => <Edit />,
          tooltip: "Edit",
          onClick: handleEdit,
        },
        {
          icon: () => <AssignmentIcon />,
          tooltip: "Fulfill Recommission Routine",
          onClick: handleFulfill,
        },
      ]}
    />
  );
};

export default RecommissionSheetListDataTable;
