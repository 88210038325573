import * as React from 'react'; 
import UserUpdate from '../../components/User/ManageUsers/UserUpdate';
import {useHistory } from "react-router-dom";
 

const UserUpdatePage:React.FC = ()=> {
    const params:any = useHistory();
    // console.log(params.location.state.user);
    return (
        <div>
            <UserUpdate params={params.location.state.user}/>
        </div>
    )
}

export default UserUpdatePage;