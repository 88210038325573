import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { List } from "@material-ui/core";
import DomainIcon from "@material-ui/icons/Domain";
import Sites from "@material-ui/icons/Build";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const NavBar: React.FC = () => {
  const user = useAuth();
  return (
    <List>
      {user.user?.role && user.user?.role === "Administrator" && (
        <ListItem button to="/users" component={RouterLink}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts" />
        </ListItem>
      )}
      <ListItem button to="/definitions" component={RouterLink}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Definitions" />
      </ListItem>
      <ListItem button to="/sites" component={RouterLink}>
        <ListItemIcon>
          <Sites />
        </ListItemIcon>
        <ListItemText primary="Sites" />
      </ListItem>
      {/* <ListItem button to="/surveys" component={RouterLink}>
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText primary="Surveys" />
      </ListItem> */}
      <ListItem button to="/laborTypes" component={RouterLink}>
        <ListItemIcon>
          <WorkRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Labor Types" />
      </ListItem>
      {/* <ListItem button to="/reports" component={RouterLink}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem> */}
    </List>
  );
};

export default NavBar;
