import React from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Box } from "@material-ui/core";
import { Client, SiteSurveyRecommissionSheetPreviewModel } from "../../helpers/ApiResources"; 
import useAsyncTask from "../../hooks/useAsyncTask";

import MaterialTable, { Icons } from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowUpward,
  Remove,
  ViewColumn,
} from "@material-ui/icons";

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

type SiteSurveyCloneDialogProps = {
  //   surveyName: string;
  resourceOwnerId: string;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const PreviewRecommissionSheetDialog: React.FC<SiteSurveyCloneDialogProps> = (props) => {
  const { resourceOwnerId, open, onClose } = props;
 

  // useState Hooks
  const [recommissionSheetData, setRecommissionSheet] = React.useState<SiteSurveyRecommissionSheetPreviewModel>();

  // Handler Close for Dialog
  const handleClose = () => {
    onClose(false);
  };

  React.useEffect(() => {
    loadRecommissionSheetTask.run();
    // eslint-disable-next-line
  }, [resourceOwnerId]);

  const loadRecommissionSheetTask = useAsyncTask(async (client: Client) => {
    await client
      .siteSurvey_GetRecommissionSheetFlatListPreview(resourceOwnerId!)
      .then((response) => { 
        setRecommissionSheet(response.result);
      }) 
  });

  return (
    <div>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}> 
      <DialogActions>
        <Box mr={4}>
          <Button variant="contained" disableElevation onClick={handleClose}>Cancel</Button> 
        </Box>
          
        </DialogActions>
        <DialogContent>
          <MaterialTable
            icons={tableIcons}
            title={recommissionSheetData?.name! + " Recommission Routine"}
            columns={[
              { title: "Location", field: "location" },
              { title: "Recommission Item", field: "recommissionItem.name" },
              { title: "Findings", field: "recommissionItem.findings" },
              { title: "Changed Specification", editable: "never", field: "recommissionItem.changedToSpec" },
              { title: "Attachments", editable: "never", field: "recommissionItem.attachments" },
            ]}
            data={recommissionSheetData?.recommissionSheetItems!}
            options={{
              paging: false,
              exportButton: true,
              headerStyle: {
                fontWeight: "bold",
              },
            }}
          />
        </DialogContent> 
      </Dialog>
    </div>
  );
};

export default PreviewRecommissionSheetDialog;
