import { Node, Question } from "../helpers/ApiResources";

export function findNode(node: Node, nodeId: string): Node | null {
  if (node.uniqueCode === nodeId) {
    return node;
  }
  if (node.children && node.children.length > 0) {
    for (let c of node.children) {
      let n: Node | null = findNode(c, nodeId);
      if (n) {
        return n as Node;
      }
    }
  }
  return null;
}

export function findParentNode(node: Node, nodeId: string): Node | null {
  if (node.uniqueCode === nodeId) {
    console.log("found node: ", node.uniqueCode);
    return null;
  }

  if (node.children && node.children.length > 0) {
    const filterChild = node.children.filter((x) => x.uniqueCode === nodeId);
    if (filterChild.length === 1) {
      return node;
    }

    for (let c of node.children) {
      let n: Node | null = findParentNode(c, nodeId);
      if (n) {
        console.log("found Parent node: ", n.uniqueCode);
        return n as Node;
      }
    }
  }
  return null;
}

export function removeChild(parentNode: Node, uniqueCode: string) {
  for (let i = 0; i < parentNode.children!.length; i++) {
    if (parentNode.children![i].uniqueCode === uniqueCode) {
      parentNode.children!.splice(i, 1);
    }
  }
  return parentNode;
}

export function getFlattenedQuestions(node: Node): Question[] {
  let questions = [...node.definition.questions];
  if (node.children && node.children.length > 0) {
    let childQuestions = node.children.flatMap(getFlattenedQuestions);
    questions.push(...childQuestions);
  }
  return questions;
}

