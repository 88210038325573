import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

type CenterContentProps = {
  pt?: any;
  p?: any;
  pb?: any;
};

const CenterContent: React.FC<CenterContentProps> = (props) => {
  return (
    <Box
      width="100%"
      pt={props.pt}
      p={props.p}
      pb={props.pb}
      justifyContent="center"
      alignContent="center"
      className="center-content"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        className="center-content-grid-container"
      >
        <Grid item className="enter-content-grid-item">
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CenterContent;
