import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Node, Question } from "../../helpers/ApiResources";
import { Box, Grid, Typography } from "@material-ui/core";
import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import { findNode } from "../../helpers/NodeHelper";
import clone from "clone";
import SortableTree, {
  TreeItem,
  OnDragPreviousAndNextLocation,
  NodeData,
} from "react-sortable-tree";

const useStyles = makeStyles({
  root: {
    minHeight: "300px",
    height: "100%",
    backgroundColor: "#fafafa",
    /* width */
    "&::-webkit-scrollbar": {
      width: "10px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

export type SiteSurveyTreeProps = {
  node: Node;
  onNodeSelection: (uniqueCode: string) => void;
  onSurveyReordered: ((node: Node) => void) | null;
  setDisplayNameForUpdate?: (nodeId: string) => void; // Might not be required for every reuse so it can be null
  disableNodeTree?: boolean; // disable tree if a fulfillment form is not saved
  displayQuestionStats?: boolean;
};

export default function SiteSurveyTree({
  node: heirarchy,
  onNodeSelection,
  onSurveyReordered = null,
  setDisplayNameForUpdate,
  disableNodeTree,
  displayQuestionStats,
}: SiteSurveyTreeProps) {
  const classes = useStyles();

  const nodeSelectionHandler = (nodeId: string) => {
    onNodeSelection(nodeId);

    if (setDisplayNameForUpdate) {
      setDisplayNameForUpdate(nodeId);
    }
  };

  const renderQuestionStats = (node: Node) => {
    const questions = node.definition.questions;
    const requiredQuestionCount = questions.filter((q) => q.required).length;
    const isAttachmentAnswered = (question: Question) =>
      question.questionType === "Attachment" &&
      question.attachments &&
      question.attachments.length > 0;
    const answeredRequiredCount = questions.filter(
      (q) => q.required && (q.answer || isAttachmentAnswered(q))
    ).length;
    return (
      <Typography variant="subtitle2">
        {answeredRequiredCount} / {requiredQuestionCount}
      </Typography>
    );
  };

  const renderNodeLabel = (nodeId: string) => {
    const node = findNode(heirarchy, nodeId);

    if (!node) {
      return nodeId;
    }

    return (
      <Box p={0.4}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          direction="row"
        >
          <Grid item>
            <Typography>
              {node.displayName ? node.displayName : node.definition!.name}
            </Typography>
          </Grid>
          <Grid item>{displayQuestionStats && renderQuestionStats(node)}</Grid>
        </Grid>
      </Box>
    );
  };

  const [treeData, setTreeData] = useState<TreeItem[]>([]);

  React.useEffect(() => {
    setTreeData([updateNodeData(heirarchy)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heirarchy]);

  const updateNodeData = (node: Node): TreeItem => {
    return {
      id: node.uniqueCode,
      title: node.displayName,
      expanded: true,
      children: node.children.map((n) => updateNodeData(n)),
    };
  };

  const getNodeData = (root: Node, n: TreeItem): Node | null => {
    if (n) {
      const r = clone(findNode(heirarchy, n.id), false);

      if (r && n.children) {
        const children = [];
        const childArray = n.children as TreeItem[];
        for (let i = 0; i < childArray.length; i++) {
          const element = childArray[i];
          const child = getNodeData(r!, element);

          if (child) {
            children.push(child);
          }
        }

        r.children = children;
      }

      return r;
    }

    return null;
  };

  return (
    <SortableTree
      className={classes.root}
      treeData={treeData}
      canDrag={!displayQuestionStats}
      maxDepth={5}
      isVirtualized={true}
      canDrop={(data: OnDragPreviousAndNextLocation & NodeData): boolean => {
        return data.nextParent !== null;
      }}
      generateNodeProps={(n) => {
        return {
          id: n.node.id,
          onClick: (e: any) => nodeSelectionHandler(n.node.id),
          title: renderNodeLabel(n.node.id),
        };
      }}
      theme={FileExplorerTheme}
      onChange={(treeData) => {
        setTreeData(treeData);

        if (onSurveyReordered) {
          const node = getNodeData(heirarchy, treeData[0]);          
          if (node) {
            console.log(node);
            onSurveyReordered(node);
          }
        }
      }}
    />
  );
}
