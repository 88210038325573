import React from "react";
import { useField, FieldAttributes } from "formik";
import TextField from "@material-ui/core/TextField";

type TextFieldWithValidationProps = {
  label: string;
  fullWidth: boolean;
  multiline?: boolean;
  disabled?: boolean;
  helperText?: string;
  variant?:"standard" | "filled" | "outlined" | undefined;
  rows?: number;
} & FieldAttributes<{}>;

const TextFieldWithValidation: React.FC<TextFieldWithValidationProps> = ({
  placeholder,
  label,
  value,
  type,
  fullWidth,
  multiline,
  required,
  disabled,
  helperText,
  variant,
  rows,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : null;

  return (
    <>
      <TextField
        {...field}
        helperText={errorText ?? helperText}
        value={value}
        error={!!errorText}
        type={type}
        placeholder={placeholder}
        label={label}
        rows={rows}
        fullWidth={fullWidth}
        multiline={multiline}
        required={required}
        disabled={disabled}
        variant={variant}
      ></TextField>
    </>
  );
};

export default TextFieldWithValidation;
