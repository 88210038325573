import * as React from 'react';
import useClient from '../../hooks/useClient';
import SurveyEditor from '../../components/SurveyEditor/SurveyEditor';
import { UpdateSiteSurveyCommand } from '../../helpers/ApiResources';
import { useParams } from 'react-router';

const SurveyEditorEditPage: React.FC = () => {

  const client = useClient();
  const params: any = useParams();

  const updateSiteSurvey = (command: UpdateSiteSurveyCommand) => {
    return client.siteSurvey_Update(command.siteSurveyId, command);
  }

  return (
    <SurveyEditor surveyId={params.id} onSubmit={updateSiteSurvey} />
  );
}

export default SurveyEditorEditPage;