import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Query, QueryResult } from "material-table";
import { useParams } from "react-router";
import { Client } from "../../../helpers/ApiResources";

import Edit from "@material-ui/icons/Edit";
import ListAlt from "@material-ui/icons/ListAlt";
import RemoveRedEyeSharp from "@material-ui/icons/RemoveRedEyeSharp";
import GenerateMaintenanceSheetDialog from "../../Dialogs/GenerateMaintenanceSheetDialog";
import useAsyncTask from "../../../hooks/useAsyncTask";
import TableCustom from "../../controls/TableCustom";

type MaintenanceDataTableProps = {};

const MaintenanceDataTable: React.FC<MaintenanceDataTableProps> = () => {
  const history = useHistory();
  const tableRef = useRef<any>();
  const params: any = useParams();

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [maintainanceSheet, setMaintainanceSheet] = React.useState<any>();

  const loadMaintenanceTask = useAsyncTask(
    async (c: Client, q: Query<object>) => {
      const d = String(q.orderDirection);
      const sortBy = q.orderBy !== undefined ? String(q.orderBy.field) : "";
      const direction = d === "" || d === "asc" ? "Ascending" : "Descending";

      return await c.maintenanceSchedule_Search(
        q.page * q.pageSize,
        q.pageSize,
        sortBy,
        direction,
        params.id,
        q.search
      );
    }
  );

  const loadSchedules = (
    query: Query<object>
  ): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadMaintenanceTask
        .run(query)
        .then((response) => {
          if (response) {
            const r = response.result;
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords!,
            });
          }
        })
        .catch((error) => reject(error));
    });
  };

  // Handle open dialog for Maintainance Sheet
  const generateMaintenanceHandler = (
    event: React.MouseEvent<unknown>,
    data: any
  ) => {
    setMaintainanceSheet(data);
    setOpenDialog(true);
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("/maintenanceschedule/" + data.id);
  };

  const maintainanceViewDataHandler = (
    event: React.MouseEvent<unknown>,
    data: any
  ) => {
    const name = data.name.replace(/ /g, "_");
    history.push(`/maintenanceschedule/${data.id}/${name}`);
  };

  return (
    <>
      <TableCustom
        tableRef={tableRef}
        title="Schedules"
        columns={[{ title: "Name", field: "name" }]}
        data={loadSchedules}
        moreAction={[
          {
            icon: () => <Edit />,
            tooltip: "Edit",
            onClick: handleEdit,
          },
          {
            icon: () => <RemoveRedEyeSharp />,
            tooltip: "Preview Maintenance Routine",
            onClick: maintainanceViewDataHandler,
          },
          {
            icon: () => <ListAlt />,
            tooltip: "Generate Maintenance Routine",
            onClick: generateMaintenanceHandler,
          },
        ]}
      />

      <GenerateMaintenanceSheetDialog
        maintainanceSheet={maintainanceSheet}
        open={openDialog}
        onClose={setOpenDialog}
      />
    </>
  );
};

export default MaintenanceDataTable;
