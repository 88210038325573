import React from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from "@material-ui/core";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
import { Client, GenerateMaintenanceSheetCommand } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";

type GenerateMaintenanceSheetDialogProps = {
  maintainanceSheet: any;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const GenerateMaintenanceSheetDialog: React.FC<GenerateMaintenanceSheetDialogProps> = (props) => {
  // API requests

  let history = useHistory();

  // useState Hooks
  const [maintenanceTitle, setMaintenanceTitle] = React.useState<string>("");
  const [clientErrorMessage, setServerErrorMessage] = React.useState<string>("");
  const [hasError, setHasError] = React.useState<boolean>(false);

  // Handler Close for Dialog
  const handleClose = () => {
    props.onClose(false);
    setHasError(false);
    setServerErrorMessage("");
  };

  // Manage TextField States
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaintenanceTitle(event?.target.value);
    setHasError(false);
    setServerErrorMessage("");
  };

  const generateMaintenanceSheetTask = useAsyncTask(async (client: Client) => {
    const command: GenerateMaintenanceSheetCommand = {
      maintenanceScheduleId: props.maintainanceSheet.id!,
      name: maintenanceTitle,
    };

    await client
      .maintenanceSheet_Create(command)
      .then((res) => {
        history.push("/maintenancesheet/" + res.result.id);
      })
      .catch((error) => {
        // Error from server
        setHasError(true);
        setServerErrorMessage(error.title);
      });
  });

  const handleDialogSubmit = () => {
    // todo: check if surveyName has value, initialize CloneSiteSurveyCommand and
    // set properties(props, updateded surveyName), Call API request

    if (!maintenanceTitle) {
      setHasError(true);
      setServerErrorMessage("Cannot be empty");
      return;
    }

    generateMaintenanceSheetTask
      .run()
      .then()
      .catch(() => console.table(generateMaintenanceSheetTask.validationErrors));
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>{props.maintainanceSheet?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>Generate Maintenance Routine for {props.maintainanceSheet?.name}?</DialogContentText>
          <TextField
            id="name"
            autoFocus
            label="Maintenance Routine*"
            type="text"
            placeholder="Maintenance Routine"
            fullWidth
            onChange={onChangeTextField}
            error={hasError}
            helperText={clientErrorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" disableElevation>Cancel</Button>
          <Button onClick={handleDialogSubmit} variant="contained" color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GenerateMaintenanceSheetDialog;
