import React from "react";
import { Client, UserSummaryModel } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

type TechnicianAutoCompleteProps = {
  selectedUser: UserSummaryModel | undefined;
  onChange: (user: UserSummaryModel | undefined) => void;
};

export default function TechnicianAutoComplete(
  props: TechnicianAutoCompleteProps
) {
  const [users, setUsers] = React.useState<(UserSummaryModel | string)[]>([]);

  const getUsersTask = useAsyncTask((client: Client) =>
    client.user_GetAll().then((response) => {
      let technicians: (UserSummaryModel | string)[] = response.result.filter(
        (u) => u.role === "Technician"
      );
      technicians.push(""); // add empty option - need to do this so the React component stays as a controlled input
      setUsers(technicians);
    })
  );

  React.useEffect(() => {
    if (getUsersTask.status === "IDLE") {
      getUsersTask.run();
    }
  }, [getUsersTask]);

  return (
    <>
      {users && (
        <Autocomplete
          id="definition-selector"
          options={users}
          onChange={(event, value: UserSummaryModel | string) =>
            typeof value === "string"
              ? props.onChange(undefined)
              : props.onChange(value)
          }
          value={props.selectedUser || ""}
          inputValue={props.selectedUser?.fullName ?? ""}
          getOptionLabel={(option: UserSummaryModel | string) =>
            typeof option === "string" ? "" : option.fullName ?? ""
          }
          disableClearable
          style={{ width: 280 }}
          renderInput={(params) => <TextField {...params} label="Technician" />}
        />
      )}
    </>
  );
}
