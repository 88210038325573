import React from "react";
import { Formik, Form, FormikHelpers, FormikValues } from "formik";
import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import * as Yup from "yup";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router";
import useAsyncTask from "../../hooks/useAsyncTask";
import {
  Client,
  CreateLaborTypeCommand,
  LaborTypeSummaryModel,
  UpdateLaborTypeCommand,
} from "../../helpers/ApiResources";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import appMessageService from "../../services/AppMessageService";
import PromptIfDirty from "../common/PromptIfDirty";

type LaborTypeProps = {
  laborType?: LaborTypeSummaryModel;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter a name for this labor type."),
  rate: Yup.string()
    .trim()
    .matches(
      /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/,
      "Rate should be a number greater than zero."
    )
    .required("Please enter a rate for this labor type."),
});

const LaborTypeForm: React.FC<LaborTypeProps> = ({ laborType }) => {
  const history = useHistory();
  const initialValues = {
    id: laborType?.id,
    name: laborType?.name ?? "",
    description: laborType?.description ?? "",
    rate: laborType?.rate ?? 1,
  };

  const updateLaborType = useAsyncTask(
    async (
      client: Client,
      command: UpdateLaborTypeCommand,
      formikHelpers: FormikHelpers<FormikValues>
    ) => {
      await client
        .laborType_Update(command.id, command)
        .then(() => {
          appMessageService.publish(
            "Labor Type was successfully saved.",
            "success"
          );
          history.push("/laborTypes");
        })
        .catch((err) =>
          handleValidationErrors(err["errors"], formikHelpers.setFieldError)
        )
        .finally(() => formikHelpers.setSubmitting(false));
    }
  );

  const createLaborType = useAsyncTask(
    async (
      client: Client,
      command: CreateLaborTypeCommand,
      formikHelpers: FormikHelpers<FormikValues>
    ) => {
      await client
        .laborType_Create(command)
        .then(() => {
          appMessageService.publish(
            "Labor Type was successfully created.",
            "success"
          );
          history.push("/laborTypes");
        })
        .catch((err) =>
          handleValidationErrors(err["errors"], formikHelpers.setFieldError)
        )
        .finally(() => formikHelpers.setSubmitting(false));
    }
  );

  const deleteLaborType = useAsyncTask(async (client: Client, id: string) => {
    await client.laborType_Delete(id).then(() => {
      appMessageService.publish(
        "Labor Type was successfully deleted.",
        "success"
      );
      history.push("/laborTypes");
    });
  });

  function handleValidationErrors(
    errors: { Name?: string[]; Rate?: string[]; Description?: string[] },
    setFieldError: (field: string, message: string) => void
  ) {
    if (errors.Name) {
      setFieldError("name", errors.Name[0]);
    }

    if (errors.Rate) {
      setFieldError("rate", errors.Rate[0]);
    }

    if (errors.Description) {
      setFieldError("description", errors.Description[0]);
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers: FormikHelpers<FormikValues>) => {
        if (laborType) {
          updateLaborType.run(values as UpdateLaborTypeCommand, formikHelpers);
        } else {
          createLaborType.run(values as CreateLaborTypeCommand, formikHelpers);
        }
      }}
    >
      {({ values, isSubmitting, dirty }) => {
        return (
          <Form id="userForm">
            <PromptIfDirty />
            <Grid container justify="center" spacing={2}>
              <Grid item xs={6}>             
                <Fab onClick={() => history.goBack()} size="small">
                  <Tooltip title="Back" aria-label="Back">
                    <ArrowBack />
                  </Tooltip>
                </Fab>
              </Grid>
              <Grid container justify="flex-end" spacing={2} item xs={6}>
                <Grid item>
                  {laborType && (
                    <Button
                      variant="contained"
                      onClick={() => deleteLaborType.run(laborType.id)}
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <Box p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {laborType ? "Update" : "Create"} Labor Type
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextFieldWithValidation
                          as={TextField}
                          name="name"
                          value={values?.name}
                          type="input"
                          placeholder="Name"
                          label="Name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldWithValidation
                          as={TextField}
                          name="rate"
                          value={values?.rate}
                          type="input"
                          placeholder="Rate"
                          label="Rate"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldWithValidation
                          as={TextField}
                          name="description"
                          value={values?.description}
                          label="Description"
                          rows={4}
                          fullWidth
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LaborTypeForm;
