import * as React from "react";
import { Box, Container, Paper } from "@material-ui/core";
import RecommissionFulfillment from "../../../components/SurveyEditor/Recommission/RecommissionFulfillment";
import { useParams } from 'react-router';


const RecommissionFulfillmentPage:React.FC = () => {
  const params: any = useParams();
  return ( 
        <RecommissionFulfillment recommissionId={params.id} /> 
  );
};

export default RecommissionFulfillmentPage;
