import * as React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import SiteSurveyTree from "../SiteSurveyTree";
import { useHistory } from "react-router";
import {
  Client,
  FulfillSiteSurveyCommand,
  Node,
  Question,
  SiteSurveyModel,
} from "../../../helpers/ApiResources";
import { useConfirm } from "material-ui-confirm";
import { FormikHelpers } from "formik";
import { findNode, getFlattenedQuestions } from "../../../helpers/NodeHelper";
import FulfillForm from "./FulfillForm";
import useClient from "../../../hooks/useClient";
import useAsyncTask from "../../../hooks/useAsyncTask";
import AuthContext from "../../../context/Authentication/AuthContext";
import appMessageService from "../../../services/AppMessageService";
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  heirachyScroll: {
    overflow: "auto", 
  },
  alignText: {
    left: 30,
  },
  alignTopMargin: {
    marginTop: 30,
  },
  minheight:{
    minHeight:300
  }
}));

type FulfillmentProps = {
  surveyId: string;
};

const FulfillEditor: React.FC<FulfillmentProps> = (props) => {
  const { logOut } = React.useContext(AuthContext); // Context State

  const client = useClient();
  const params: any = useParams();

  const [survey, setSurvey] = React.useState<SiteSurveyModel>();
  const [selectedNodeCode, setSelectedNodeCode] = React.useState<string>();
  const [selectedNode, setSelectedNode] = React.useState<Node | null>(null);

  const [isSurveyComplete, setIsSurveyComplete] = React.useState(false);
  const [questionStatus, setQuestionStatus] = React.useState("");

  const [isDirtyState, setIsDirtyState] = React.useState<boolean>(false);
  const [disableNodeTree, setDisableNodeTree] = React.useState<boolean>(false);

  React.useEffect(() => {
    const questions = survey ? getFlattenedQuestions(survey.node) : [];
    const requiredQuestions = questions.filter((q) => q.required);

    const isAttachmentAnswered = (question: Question) =>
      question.questionType === "Attachment" 
      && question.attachments 
      && question.attachments.length > 0;

    const requiredQuestionsWithAnswer = questions.filter(
      (q) => q.required && (q.answer || isAttachmentAnswered(q))
    ); // not sure how to detect attachements
    setIsSurveyComplete(requiredQuestions.length === requiredQuestionsWithAnswer.length);
    setQuestionStatus(`${requiredQuestionsWithAnswer.length}/${requiredQuestions.length}`);
  }, [survey]);

  const loadSurveyTask = useAsyncTask(async (client: Client) => {
    if (props.surveyId) {
      await client
        .siteSurvey_Get(props.surveyId)
        .then((response) => {
          setSurvey(response.result);
        });
    }
  });

  React.useEffect(() => {
    loadSurveyTask.run();
    if (isDirtyState) {
      setDisableNodeTree(true); //set true/false depending on if form is modified or dirty
    }
    // eslint-disable-next-line
  }, [isDirtyState]);

  // Handle General Errors
  const handleGeneralErrorMessage = (message: string) => {
    appMessageService.publish(message, "error");
  };

  const updateNode = (data: Node) => {
    let clone = JSON.parse(JSON.stringify(survey?.node));
    var currentNode = findNode(clone, selectedNodeCode!);

    if (currentNode) {
      currentNode.definition.questions = data.definition.questions;

      setSurvey(
        (prevState) =>
          ({
            ...prevState,
            node: clone,
          } as SiteSurveyModel)
      );
    }
    return clone;
  };

  // Get node that was selected
  const selectedNodeHandler = (value: string) => {
    setSelectedNodeCode(value);
    console.log(isDirtyState);
    const foundNode = findNode(survey?.node!, value);
    setSelectedNode(foundNode);
  }; 

  const completeSurveyTask = useAsyncTask((client: Client) =>
     client.siteSurvey_Complete(props.surveyId, { id: props.surveyId }).then((response) => {
      appMessageService.publish("Survey completed", "success");
    })
  );

  React.useEffect(() => {
    if (completeSurveyTask.validationErrors) {
      appMessageService.publish("Validation error 3", "error");
    }
  }, [completeSurveyTask, completeSurveyTask.validationErrors]);

  const handleFormikSubmit = (data: Node, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true); // setSubmitting not updating state

    var node = updateNode(data);

    const command = {
      id: props.surveyId,
      node: node,
    } as FulfillSiteSurveyCommand;

    return client
      .siteSurvey_Fulfill(props.surveyId, command)
      .then((response) => {
        setDisableNodeTree(false); //set true/false depending on if form is modified or dirty
        formikHelpers.resetForm({ values: data });
        appMessageService.publish("Saved", "success");
      })
      .catch((error) => {
        handleGeneralErrorMessage(error.title);
        console.log(error);
        if (!error.title) {
          logOut(error.title);
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <> 
      <Grid container spacing={3} direction="row">
        {/* Heading */}
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography variant="h4">{survey?.name}</Typography>
          </Box>
        </Grid>

        {/* Completion error */}
        <Grid item xs={12}>
          <Typography>{questionStatus}</Typography>
          {completeSurveyTask.status === "ERROR" && <Typography color="error">{completeSurveyTask.message}</Typography>}
        </Grid>

        {/* Sidebar */}
        <Grid item xs={4}>
          <Box style={{}}>
            {survey?.node && (
              <SiteSurveyTree
                node={survey?.node}
                onSurveyReordered={null}
                onNodeSelection={selectedNodeHandler}
                disableNodeTree={disableNodeTree}
                displayQuestionStats={true}
              />
            )}
          </Box>
        </Grid>

        {/* Question/Answer Content */}
        <Grid item xs={8}>
          <Box>
            {selectedNode && (
              <FulfillForm
                selectedNodeCode={selectedNodeCode}
                selectedNode={selectedNode}
                handleFormikSubmit={handleFormikSubmit}
                setIsDirtyState={setIsDirtyState}
                surveyId={props.surveyId}
                completeSurvey={completeSurveyTask.run}
                isSurveyComplete={isSurveyComplete}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FulfillEditor;
