import React from 'react';
import { useField, FieldAttributes } from 'formik';
import ChipInput from 'material-ui-chip-input';

type ChipInputWithValidationProps = {value: string[], 
                                    onAdd: (chip: any) => void,
                                    onDelete: (chip: any, index: number) => void
                                    }
                                    & FieldAttributes<{}>;

const ChipInputWithValidation : React.FC<ChipInputWithValidationProps> = ({value, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error ? meta.error : '';

  return (
    <>
      <ChipInput
            {...field}
            value={value}
            onAdd={props.onAdd}
            onDelete={props.onDelete}
            label="Answer Options *"
            placeholder="Press Enter to add new option"
            fullWidth
            helperText={errorText} 
            error={!!errorText}  

        />
    </>
  );
  
}

export default ChipInputWithValidation;