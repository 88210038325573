import { TextField } from "@material-ui/core";
import * as React from "react";
import { NodeSummary } from "../../../helpers/ApiResources";
import Autocomplete from "@material-ui/lab/Autocomplete";

type SurveyNodeDropdownProps = {
  nodeSummaryList: NodeSummary[];
  value: NodeSummary;
  onChange: (n: NodeSummary | null) => void;
  error?: string;
};
const SurveyNodeDropdown = (props: SurveyNodeDropdownProps) => {
  
    const isOptionSelected = (option: NodeSummary, value: NodeSummary) => {
        return option.uniqueCode === value.uniqueCode;
    }
  return (
    <Autocomplete
      id="combo-box-demo"
      options={props.nodeSummaryList}
      getOptionLabel={(option: NodeSummary) => option.location ?? ""}
      getOptionSelected={isOptionSelected}
      style={{ width: 400 }}
      value={props.value}
      onChange={(e, v) => props.onChange(v)}
      disableClearable
      renderInput={(params) => (
        <TextField {...params} label="Location" helperText={props.error} error={!!props.error} />
      )}
    />
  );
};

export default SurveyNodeDropdown;
