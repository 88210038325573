import { Client, TokenResponse } from '../../helpers/ApiResources'
import {
    LOGIN_USER_SUCCESSFULL,
    ERROR_MESSAGE,
    ERROR_CLEAR_MESSAGE,
    MAKING_REQUEST,
    REQUEST_COMPLETED,
    LOG_OUT,
    REFRESH_TOKEN,
    LOAD_USER
} from '../types/UserTypes'

export type AuthState = {
    user: TokenResponse | null;
    client: Client;
    errorMessage: string;
    loading: boolean;
}

export type LoginStateChangePayload = {
    user: TokenResponse;
    client: Client;
}

export type AuthAction =
 | { type: "LOGIN_USER_SUCCESSFULL", payload: LoginStateChangePayload }
 | { type: "ERROR_MESSAGE", payload: string }
 | { type: "ERROR_CLEAR_MESSAGE" }
 | { type: "MAKING_REQUEST" }
 | { type: "REQUEST_COMPLETED" }
 | { type: "LOG_OUT", payload: Client }
 | { type: "REFRESH_TOKEN"}
 | { type: "LOAD_USER", payload: LoginStateChangePayload };

// eslint-disable-next-line
const AuthReducer = (state: AuthState, action: AuthAction) : AuthState => {
    switch (action.type) {
        case LOGIN_USER_SUCCESSFULL:
            return {
                ...state,
                user: action.payload.user,
                client: action.payload.client
            }
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            }
        case ERROR_CLEAR_MESSAGE:
            return {
                ...state,
                errorMessage: ''
            }
        case MAKING_REQUEST:
            return {
                ...state,
                loading: true
            }
        case REQUEST_COMPLETED:
            return {
                ...state,
                loading: false
            }
        case LOG_OUT:
            return {
                ...state,
                user: null,
                client: action.payload
            }
        case REFRESH_TOKEN:
            return {
                ...state,
            }
        case LOAD_USER:
            return {
                ...state,
                user: action.payload.user,
                client: action.payload.client
            }
    }
}

export default AuthReducer;
