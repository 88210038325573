import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Formik,
  Form,
  FieldArray,
  Field,
  FormikHelpers,
} from "formik";
import * as React from "react";
import {
  Client,
  NodeSummary,
  UpdateRecommissionSheetCommand,
  UserSummaryModel,
} from "../../../helpers/ApiResources";
import TextFieldWithValidation from "../../controls/TextFieldWithValidation";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import useAsyncTask from "../../../hooks/useAsyncTask";
import { useHistory } from "react-router";
import { useConfirm } from "material-ui-confirm";
import appMessageService from "../../../services/AppMessageService";
import * as Yup from "yup";
import SurveyNodeDropdown from "./SurveyNodeDropdown";
import TechnicianAutoComplete from "../../User/TechnicianAutoComplete";
import PromptIfDirty from "../../common/PromptIfDirty";

type RecommissionFormProps = {
  data: UpdateRecommissionSheetCommand;
  handleFormikSubmit: (
    values: UpdateRecommissionSheetCommand,
    formikHelpers: FormikHelpers<any>
  ) => void;
  surveyId: string;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  buttons: {
    padding: "10px",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

const RecommissionForm: React.FC<RecommissionFormProps> = (props) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    recommissionSheetItems: Yup.array().of(
      Yup.object({
        location: Yup.string().required("Location is required"),
        nodeUniqueCode: Yup.string().required("Node is required"),
        recommissionItem: Yup.object({
          name: Yup.string().required("Name is required"),
        }),
      })
    ),
  });

  const { data, handleFormikSubmit, surveyId } = props;

  const [surveyNodeSummaryList, setSurveyNodeSummaryList] = React.useState<
    NodeSummary[]
  >();

  const classes = useStyles();
  let history = useHistory();
  const confirm = useConfirm();

  const getNodeSummaryListTask = useAsyncTask((client: Client) =>
    client.siteSurvey_GetNodeSummaryList(surveyId).then((response) => {
      setSurveyNodeSummaryList(response.result);
      return response.result;
    })
  );
  React.useEffect(() => {
    if (getNodeSummaryListTask.status === "IDLE") {
      getNodeSummaryListTask.run();
    }
  }, []);

  //TODO: delete recommission form itself
  const handleDeleteRecommission = useAsyncTask(
    async (client: Client, id: string) => {
      confirm({
        title: "Please Confirm",
        description:
          "Are you sure that you want to permanently delete this recommission?",
        confirmationText: "YES",
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",
          disableElevation: true,
        },
        cancellationButtonProps: {
          variant: "contained",
          disableElevation: true,
        },
      })
        .then(() => {
          client.recommissionSheet_Delete(id).then((response) => {
            appMessageService.publish("Deleted", "info");
            history.goBack();
          });
        })
        .catch(() => {
          // user cancel, which reject the promise and close dialog
        });
    }
  );

  return (
    <Formik
      enableReinitialize // required in order to reinitialize after adding more items
      initialValues={data}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleFormikSubmit}      
    >
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <Form id="recommissionSheetItemsForm">
            <PromptIfDirty />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="h4">Recommission Routine</Typography>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDeleteRecommission.run(values?.id!);
                  }}
                  className={classes.button}
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Box>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12}>
                <TextFieldWithValidation
                  as={TextField}
                  name="name"
                  value={values?.name || ""}
                  type="input"
                  placeholder="Recommission Name"
                  label="Recommission Name *"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TechnicianAutoComplete 
                  selectedUser={
                    values?.users && values.users.length === 1
                      ? values.users[0]
                      : undefined
                  }
                  onChange={(tech: UserSummaryModel | undefined) => {
                    setFieldValue("users", tech !== undefined ? [tech]: []);
                  }}
                />
              </Grid>

              <FieldArray name="recommissionSheetItems">
                {(arrayHelpers) => (
                  <>
                    <Grid
                      container
                      justify="flex-end"
                      item
                      xs={12}
                      sm={12}
                      className={classes.buttons}
                    >
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          arrayHelpers.push({
                            location: "",
                            recommissionItem: {
                              // unique code is used to uniquely identify a question within a recommission
                              uniqueCode: uuidv4(),
                              name: "",
                              action: {
                                findings: "",
                                changedToSpec: "",
                                notes: "",
                                attachments: [],
                              },
                            },
                          })
                        }
                        startIcon={<AddIcon />}
                      >
                        Add Recommission Item
                      </Button>
                    </Grid>
                    {values?.recommissionSheetItems!.map((r, i) => (
                      <Grid item xs={12} sm={12} key={i}>
                        <Card>
                          <CardContent>
                            <Grid
                              container
                              item
                              spacing={2}
                              justify="space-between"
                              alignItems="center"
                              direction="row"
                            >
                              {/* <Grid item  sm={11}> */}
                              <Grid item xs={5}>
                                {surveyNodeSummaryList && (
                                  <Field
                                    name={`recommissionSheetItems[${i}].location`}
                                  >
                                    {({
                                      field, // { name, value, onChange, onBlur }
                                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                    }: any) => (
                                      <>
                                        <SurveyNodeDropdown
                                          nodeSummaryList={
                                            surveyNodeSummaryList
                                          }
                                          value={{
                                            uniqueCode: values.recommissionSheetItems![
                                              i
                                            ].nodeUniqueCode,
                                            location: values.recommissionSheetItems![
                                              i
                                            ].location!,
                                          }}
                                          onChange={(nodeSummary) => {
                                            setFieldValue(
                                              `recommissionSheetItems[${i}].location`,
                                              nodeSummary?.location
                                            );
                                            setFieldValue(
                                              `recommissionSheetItems[${i}].nodeUniqueCode`,
                                              nodeSummary?.uniqueCode
                                            );
                                            // values.recommissionSheetItems![i].nodeUniqueCode = nodeSummary.uniqueCode!;
                                            // values.recommissionSheetItems![i].location = nodeSummary.location!;
                                          }}
                                          error={meta.error}
                                        />
                                      </>
                                    )}
                                  </Field>
                                )}
                              </Grid>

                              <Grid item xs={4}>
                                <TextFieldWithValidation
                                  as={TextField}
                                  name={`recommissionSheetItems[${i}].recommissionItem.name`}
                                  value={
                                    values.recommissionSheetItems![i]
                                      .recommissionItem.name
                                  }
                                  type="input"
                                  placeholder="Name"
                                  label="Name"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={ 
                                        values.recommissionSheetItems![i]
                                          .recommissionItem.isAttachmentRequired
                                      }
                                      onChange={(e) => setFieldValue(
                                        `recommissionSheetItems[${i}].recommissionItem.isAttachmentRequired`,
                                        !values.recommissionSheetItems![i]
                                          .recommissionItem.isAttachmentRequired
                                      )}
                                      name="required"
                                      color="primary"
                                    />
                                  }
                                  label="Requires Photo"
                                />
                              </Grid>
                              {/* </Grid> */}

                              <Grid item>
                                <IconButton
                                  onClick={() => arrayHelpers.remove(i)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
            <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecommissionForm;
