import * as React from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { Client, UpdateUserCommand } from "../../../helpers/ApiResources";

import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Fab,
  Tooltip,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import TextFieldWithValidation from "../../controls/TextFieldWithValidation";
import useAsyncTask from "../../../hooks/useAsyncTask";
import appMessageService from "../../../services/AppMessageService";
import PromptIfDirty from "../../common/PromptIfDirty";

type UserUpdataProps = {
  params: any;
};

const UserUpdate: React.FC<UserUpdataProps> = ({ params }) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });

  const userParam = params;
  const history = useHistory();

  const UpdateUserTask = useAsyncTask(
    async (client: Client, command: UpdateUserCommand) => {
      client
        .user_Update(params.id, command)
        .then((response) => {
          appMessageService.publish("Saved", "success");
          history.push("/users");
          // user updated
        })
        .catch((error) => appMessageService.publish(error.title, "warning"))
        .finally(() => {});
    }
  );

  const handleFormikSubmit = (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    const command = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
    } as UpdateUserCommand;

    UpdateUserTask.run(command).catch(() =>
      console.table(UpdateUserTask.validationErrors)
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Formik
          enableReinitialize // required in order to reinitialize after adding more questions
          initialValues={userParam}
          validationSchema={validationSchema}
          // validator={() => ({})}
          onSubmit={handleFormikSubmit}
        >
          {({ values, dirty, submitForm }) => {
            return (
              <Form id="userForm" style={{ width: "700px" }}>
                <PromptIfDirty />
                <Box pb={2}>
                  <Grid container item justify="space-between" xs={12}>             
                    <Fab onClick={() => history.goBack()} size="small">
                      <Tooltip title="Back" aria-label="Back">
                        <ArrowBack />
                      </Tooltip>
                    </Fab>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty}
                      onSubmit={submitForm}
                    >
                      Save
                    </Button>
                  </Grid>
                </Box>

                <Paper style={{ padding: "10px" }}>
                  <Grid container spacing={4}>
                    <Grid container item justify="center" xs={12}>
                      <Typography variant="h5">Update user info</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldWithValidation
                        as={TextField}
                        name="firstName"
                        value={values.firstName}
                        type="input"
                        placeholder="First Name"
                        label="First Name *"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldWithValidation
                        as={TextField}
                        name="lastName"
                        value={values.lastName}
                        type="input"
                        placeholder="Last Name"
                        label="Last Name *"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default UserUpdate;
