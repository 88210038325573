import React from "react";
import { useField, FieldAttributes } from "formik";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

type MultiLineTextTextFieldWithValidationProps = {
  label?: string;
  fullWidth: boolean;
  instruction?: string;
  disabled?:boolean
} & FieldAttributes<{}>;

const MultiLineTextTextFieldWithValidation: React.FC<MultiLineTextTextFieldWithValidationProps> = ({
  placeholder,
  label,
  value,
  type,
  fullWidth,
  required,
  instruction,
  disabled,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <>
      {/* <Typography variant="h6">{label}</Typography> */}

      {instruction ? <Typography variant="subtitle1">Instruction: {instruction}</Typography> : null}

      <TextField
        {...field}
        helperText={errorText}
        multiline
        rowsMax={4}
        value={value}
        error={!!errorText}
        type={type}
        placeholder={placeholder}
        label={label}
        fullWidth={fullWidth}
        required={required}
        disabled={disabled}
      ></TextField>
    </>
  );
};

export default MultiLineTextTextFieldWithValidation;
