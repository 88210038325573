import React from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from "@material-ui/core";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
import useClient from "../../hooks/useClient";
import useAsyncTask from "../../hooks/useAsyncTask";
import { Autocomplete } from "@material-ui/lab";
import { useParams } from "react-router";
import { Client, CloneSiteSurveyCommand, SiteSummaryModel } from "../../helpers/ApiResources";

type SiteSurveyCloneDialogProps = {
  surveyName: string;
  surveryId: string;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const SiteSurveyCloneDialog: React.FC<SiteSurveyCloneDialogProps> = (props) => {
  // API requests
  const params: any = useParams();
  let history = useHistory();

  // useState Hooks
  const [surveyName, setSurveyName] = React.useState<string>("");
  const [clientErrorMessage, setServerErrorMessage] = React.useState<string>("");
  const [clientErrorMessageOption, setServerErrorMessageOption] = React.useState<string>("");
  const [hasError, setHasError] = React.useState<boolean>(false);

  const [sites, setSites] = React.useState<(SiteSummaryModel | string)[]>([]);
  const [selectedSites, setSelectedSites] = React.useState<SiteSummaryModel | undefined>(undefined);

  const getUsersTask = useAsyncTask((client: Client) =>
    client.site_GetAll().then((response) => {
      setSites(response.result);
    })
  );

  React.useEffect(() => {
    if (getUsersTask.status === "IDLE") {
      getUsersTask.run();
    }
  }, [getUsersTask]);

  // Handler Close for Dialog
  const handleClose = () => {
    props.onClose(false);
  };

  // Manage TextField States
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurveyName(event?.target.value);
    setHasError(false);
    setServerErrorMessage("");
  };

  const submitDialogTask = useAsyncTask(async (client: Client) => {
    const command: CloneSiteSurveyCommand = {
      siteId: selectedSites?.id!,
      siteSurveyId: props.surveryId,
      name: surveyName,
    };

    console.log(command, "command");

    // API call then() => Success
    client
      .siteSurvey_Clone(command)
      .then((res) => {
        history.go(0);
      })
      .catch((err) => {
        // Error from server
        console.log(err, "err")
        setHasError(true);
        setServerErrorMessage(err.errors.SiteId[0]);
      });
  });

  const handleDialogSubmit = () => {
    // todo: check if surveyName has value, initialize CloneSiteSurveyCommand and
    // set properties(props, updateded surveyName), Call API request

    if (!surveyName) {
      setHasError(true);
      setServerErrorMessage("Survey name is required");
      return;
    }
    if (!selectedSites) {
      setHasError(true);
      setServerErrorMessageOption("Select a survey");
      return;
    }
    submitDialogTask.run();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle> Clone {props.surveyName} </DialogTitle>
        <DialogContent>
          <DialogContentText>Cloning this survey will copy all information in {props.surveyName}</DialogContentText>
          <TextField
            id="name"
            autoFocus
            label="Survey Name"
            type="text"
            placeholder="New Survey Name"
            fullWidth
            onChange={onChangeTextField}
            error={hasError}
            helperText={clientErrorMessage}
          />
          <Autocomplete
            id="site-selector"
            options={sites}
            onChange={(event, value: SiteSummaryModel | string) =>
              typeof value === "string" ? setSelectedSites(undefined) : setSelectedSites(value)
            }
            value={selectedSites || ""}
            inputValue={selectedSites?.name! ?? ""}
            getOptionLabel={(option: SiteSummaryModel | string) =>
              typeof option === "string" ? "" : option.name ?? ""
            }
            disableClearable
            style={{ width: 280 }}
            renderInput={(params) => <TextField {...params} label="Sites" />} 
          />
          <span style={{color:"red"}}>{clientErrorMessageOption}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" disableElevation>
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} variant="contained" color="primary">
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SiteSurveyCloneDialog;
