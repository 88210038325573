import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Client } from "../../../../helpers/ApiResources";
import { Query, QueryResult } from "material-table";

import Edit from "@material-ui/icons/Edit";
import useAsyncTask from "../../../../hooks/useAsyncTask";
import TableCustom from "../../../controls/TableCustom";

type MaintenanceDataTableProps = {
  surveyId: string;
};

const MaintenanceSheetDataTable: React.FC<MaintenanceDataTableProps> = (
  props
) => {
  const history = useHistory();
  const tableRef = useRef<any>();

  const loadMaintenanceSheetTask = useAsyncTask(
    async (c: Client, q: Query<object>) => {
      const d = String(q.orderDirection);
      const sortBy = q.orderBy !== undefined ? String(q.orderBy.field) : "";
      const direction = d === "" || d === "asc" ? "Ascending" : "Descending";

      return await c.maintenanceSheet_Search(
        q.page * q.pageSize,
        q.pageSize,
        sortBy,
        direction,
        props.surveyId,
        q.search
      );
    }
  );

  const loadRoutines = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadMaintenanceSheetTask
        .run(query)
        .then((response) => {
          if (response) {
            const r = response.result;
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords!,
            });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("/maintenancesheet/" + data.id);
  };

  return (
    <TableCustom
      tableRef={tableRef}
      title="Maintenance Routines"
      columns={[
        { title: "Items", field: "name" },
        { title: "Assignee", field: "users[0].fullName" },
        { title: "Status", field: "maintenanceSheetStatus" },
      ]}
      data={loadRoutines}
      moreAction={[
        {
          icon: () => <Edit />,
          tooltip: "Edit",
          onClick: handleEdit,
        },
      ]}
    />
  );
};

export default MaintenanceSheetDataTable;
