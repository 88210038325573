import * as React from "react";
import { DefinitionSummaryModel } from "../../helpers/ApiResources";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"; 
import FormControl from "@material-ui/core/FormControl"; 
import useClient from "../../hooks/useClient";
import { useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
    },
  })
);

type DefinitionSelectorProps = {
  onSelectCallback: (selectedId: string) => void;
  //onSelectedDisplayName: (name: string) => void;
  isDisabled: boolean;
};

export default function DefinitionSelector({
  onSelectCallback,
  //onSelectedDisplayName,
  isDisabled,
}: DefinitionSelectorProps) {
  const classes = useStyles();
  const [selectedDefinitionId, setSelectedDefinitionId] = useState<string>("");
  const [definitionSummaries, setdefinitionSummaries] = useState<DefinitionSummaryModel[]>([]);

  const definitionClient = useClient();

  const loadDefinitionSummaries = () => {
    definitionClient
      .definition_GetAll()
      .then((response) => {
        setdefinitionSummaries(response.result);
      });
  };

  React.useEffect(() => {
    loadDefinitionSummaries();
    // eslint-disable-next-line
  }, []);

  //const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { // This is the normal type to use
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setSelectedDefinitionId(value?.id);
    onSelectCallback(value?.id);
    //onSelectedDisplayName(value?.name);
    //onSelectedDisplayName(value?.name);
  };
 

  return (
      <Autocomplete
        id="definition-selector"
        options={definitionSummaries}
        onChange={(event, value: any) => handleChange(event, value)}
        getOptionLabel={(option) => option.name}
        disableClearable
        disabled={isDisabled}
        style={{ width: 280 }}
        renderInput={(params) => <TextField {...params} label="Definition" />}
      />
  );
}
