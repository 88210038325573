import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { Client, ForgotPasswordCommand } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";
import appMessageService from "../../services/AppMessageService";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";

const ForgotPassword: React.FC = () => {
  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required").email("Must be an email"),
  });

  const command = {
    email: "",
  } as ForgotPasswordCommand;

  const handleFormSubmitTask = useAsyncTask(
    async (client: Client, values: ForgotPasswordCommand, formikHelpers: FormikHelpers<any>) => {
      formikHelpers.setSubmitting(true);
      client
        .user_ForgotPassword(values)
        .then((response: any) => {
          appMessageService.publish("Reset password request is received and you should receive an email", "info"); 
        })
        .catch((error) => {
          console.log("errororor", error);
           appMessageService.publish("Reset password request is received and you should receive an email", "info");
        })
        .finally(() => {
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false); 
        });
    }
  );

  const handleFormSubmit = (values: ForgotPasswordCommand, formikHelpers: FormikHelpers<any>) => {
    handleFormSubmitTask.run(values, formikHelpers);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Formik
        enableReinitialize
        initialValues={command}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, dirty, isSubmitting }) => {
          return (
            <Form style={{ width: "33em" }}>
              <Paper style={{ padding: "2em" }}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <TextFieldWithValidation
                      as={TextField}
                      name="email"
                      value={values.email}
                      type="input"
                      placeholder="Email"
                      label="Email *"
                      fullWidth
                    />
                  </Grid>

                  <Grid container justify="center" item>
                    <Button type="submit" variant="contained" color="primary" disabled={!dirty && !isSubmitting}>
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ForgotPassword;
