import { Box, Button, Fab, IconButton, makeStyles, Snackbar, Tooltip } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Attachment from "@material-ui/icons/Attachment";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { Icons, MTableToolbar } from "material-table";
import * as React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Client, MaintenanceSheetItem, MaintenanceSheetModel, UpdateMaintenanceSheetCommand, UserSummaryModel } from "../../../../helpers/ApiResources";
import UpdateMaintainanceSheetItemDialog from "../../../Dialogs/UpdateMaintainanceSheetItemDialog";
import useAsyncTask from "../../../../hooks/useAsyncTask";
import { Alert } from "@material-ui/lab";
import ArrowBack from "@material-ui/icons/ArrowBack"; 
import { useConfirm } from "material-ui-confirm";
import appMessageService from "../../../../services/AppMessageService";
import TechnicianAutoComplete from "../../../User/TechnicianAutoComplete";
import clone from "clone";

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "4px",
  },
}));

type MaintenanceSheetEditorProps = {
  maintenanceId?: string;
  onSubmit: (data: UpdateMaintenanceSheetCommand) => void;  
};

const MaintenanceSheetEditorDataTable: React.FC<MaintenanceSheetEditorProps> = (props) => {
  let history = useHistory();
  const confirm = useConfirm();
  const classes = useStyles();

  const [maintenanceSheetData, setMaintenanceSheetData] = React.useState<UpdateMaintenanceSheetCommand>();
  const [selectedMaintenanceSheetItem, setSelectedMaintenanceSheetItem] = React.useState<MaintenanceSheetItem>();
  const [open, setOpen] = React.useState<boolean>(false);

  const getAssignee = () => 
    maintenanceSheetData?.users?.length === 1 
    ? maintenanceSheetData?.users[0] 
    : undefined;
  
  const setAssignee = (tech: UserSummaryModel | undefined) => {
    if(maintenanceSheetData) {
      let sheet = clone(maintenanceSheetData);
      if (tech) {
        sheet.users = [tech];
      } else {
        sheet.users = [];
      }
      setMaintenanceSheetData(sheet);
    }
  }

  React.useEffect(() => {
    loadMaintenanceSheeTask.run().catch(() => console.table(loadMaintenanceSheeTask.validationErrors));

    // eslint-disable-next-line
  }, []);

  const loadMaintenanceSheeTask = useAsyncTask(async (client: Client) => {
   await client
      .maintenanceSheet_Get(props.maintenanceId!)
      .then((response) => {
        setMaintenanceSheetData(response.result);
      });
  });

  const deleteSheetAsync = useAsyncTask(async (client: Client, id: string) => {
    // delete data

    confirm({
      title: "Please Confirm",
      description: "Are you sure that you want to delete this maintenance routine?",
      confirmationText: "YES",
      confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        // user confirm
        client
          .maintenanceSheet_Delete(id)
          .then(() => {
            appMessageService.publish("Deleted", "info");
            history.goBack();
          });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      });
  });

  const handleDelete = () => {
    deleteSheetAsync.run(props.maintenanceId!).catch(() => {
      console.table(deleteSheetAsync.status);
    });
  };

  const handleSubmitDataTable = () => {
    props.onSubmit(maintenanceSheetData!);
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    setOpen(true);
    setSelectedMaintenanceSheetItem(data);
  };

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.box}>
          <Fab onClick={() => history.goBack()} size="small">
            <Tooltip title="Back" aria-label="Back">
              <ArrowBack />
            </Tooltip>
          </Fab>
          <div>
            <Button className={classes.button} variant="contained" onClick={handleDelete}>
              Delete
            </Button>
            <Button className={classes.button} color="primary" variant="contained" onClick={handleSubmitDataTable}>
              Save
            </Button>
          </div>
        </Box>
      </div>

      <MaterialTable
        icons={tableIcons}
        components={{
          Toolbar: (props) => (
            <> 
             <MTableToolbar {...props} />
              <Box p={3}>
                <TechnicianAutoComplete selectedUser={getAssignee()} onChange={setAssignee} />
              </Box> 
            </>
          ),
        }}
        title="Maintenance Routine Items"
        columns={[
          { title: "Location", editable: "never", field: "location" },
          { title: "Maintenance Item Name", editable: "never", field: "maintenanceItem.name" },
          { title: "Task", editable: "never", field: "maintenanceItem.task" },
          { title: "Number Of Minutes Required", editable: "never", field: "maintenanceItem.numberOfMinutesRequired" },
          { title: "Labor Type", editable: "never", field: "maintenanceItem.laborType.name" },
          { title: "Maintenance Interval", editable: "never", field: "maintenanceItem.maintenanceInterval" },
          { title: "Cost", editable: "never", field: "maintenanceItem.cost" },
          {
            title: "Is Completed",
            type: "boolean",
            initialEditValue: false,
            field: "maintenanceItem.action.isCompleted",
          },
          {
            title: "Is Extra Work Required",
            field: "maintenanceItem.action.isExtraWorkRequired",
            type: "boolean",
            initialEditValue: false,
          },
          {
            title: "Extra Work Item",
            field: "maintenanceItem.action.extraWorkItem",
            type: "string",
          },
          {
            title: "Note",
            field: "maintenanceItem.action.notes",
            type: "string",
          },
          {
            title: "Attachments",
            field: "maintenanceItem.action.attachments",
            render: (rowData) => (
              <>
                {rowData.maintenanceItem.action?.attachments?.map((data) => {
                  if (data) {
                    return (
                      <IconButton color="primary" key={data.uniqueCode} aria-label="upload picture" component="span">
                        <Attachment />
                      </IconButton>
                    );
                  }
                })}
              </>
            ),
          },
        ]}
        data={maintenanceSheetData?.items!}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: "Edit",
            onClick: (event, data) => handleEdit(event, data),
          },
        ]}
        options={{
          paging: false,
          actionsColumnIndex: -1,
          exportButton: true,
          headerStyle: {
            fontWeight: "bold",
          },
        }}
      />

      <UpdateMaintainanceSheetItemDialog
        open={open}
        setOpen={setOpen}
        maintenanceSheetItem={selectedMaintenanceSheetItem}
        maintenanceId={props.maintenanceId}
        maintenanceSheetData={maintenanceSheetData}
        setMaintenanceSheetData={setMaintenanceSheetData}
      />

      {/* <pre>{JSON.stringify(maintenanceSheetData?.items!, null, 2)}</pre> */}
    </>
  );
};

export default MaintenanceSheetEditorDataTable;
