import * as React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Box, Button, Card, CardActions, CardContent, makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Client, SetPasswordCommand } from "../../helpers/ApiResources";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import useAsyncTask from "../../hooks/useAsyncTask";
import { Alert } from "@material-ui/lab";
import appMessageService from "../../services/AppMessageService";

const useStyles = makeStyles({
  root: {},
  cardSize: {
    maxWidth: 400,
  },
  errorMessage: {
    color: "red",
  },
  marginText: {
    marginTop: 30,
  },
  cardDivContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    alignItems: "center",
  },
});

type SetAccountEditorProps ={
    handleFormSubmit: (values: any, formikHelpers: FormikHelpers<any>)=> void;
    title:string
}

const SetAccountEditor: React.FC<SetAccountEditorProps> = ({handleFormSubmit,title}) => {
  const classes = useStyles();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get("t"); 
  // App state Context

  const initalValues = { token: token, email: "", password: "", confirmPassword: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .min(6, "Must be at least 6 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must have a number, Uppercase, Lowercase and special case character"
      ),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"),
      })
      .required("This field is required")
      .min(6, "Must be at least 6 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must have a number, Uppercase, Lowercase and special case character"
      ),
  }); 

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Formik
          enableReinitialize
          initialValues={initalValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, isSubmitting, dirty, isValid}) => {
            return (
              <Form>
                <Card className={classes.cardSize}>
                  <div className={classes.cardDivContainer}>
                    <CardContent>
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        {title}
                      </Typography>

                      <TextField id="outlined-token" name="username" value={initalValues.token} type="hidden" hidden />

                      <TextFieldWithValidation
                        as={TextField}
                        name="email"
                        value={values.email}
                        type="email"
                        placeholder="Email"
                        label="Email *"
                        fullWidth
                      />
                      <TextFieldWithValidation
                        as={TextField}
                        name="password"
                        value={values.password}
                        type="password"
                        placeholder="New Password"
                        label="New Password *"
                        fullWidth
                      />
                      <TextFieldWithValidation
                        as={TextField}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        type="password"
                        placeholder="Confirm password"
                        label="Confirm password *"
                        fullWidth
                      />
                    </CardContent>
                    <CardActions>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!dirty || isSubmitting || !isValid}
                      >
                         {title}
                      </Button>
                    </CardActions>
                  </div>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </div>
  );
};

export default SetAccountEditor;
