import * as React from "react";
import { useHistory } from "react-router";
import { makeStyles, Tabs, Tab, Box, Grid, IconButton, Tooltip, Fab } from "@material-ui/core";
import { ReactNode } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles({
  eventTabBar: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

type SurveyMaintenanceTabBarProps = {
  children?: ReactNode;
  surveyId: string;
  siteId:string
};

const SurveyMaintenanceTabBar = (props: SurveyMaintenanceTabBarProps) => {
  const classes = useStyles();
  const history:any = useHistory();
  const currentLocation = history.location.pathname;

  console.log(history.location.state , "surveryIdsurveryId");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(newValue);
  };

  return (
    <Box width="100%"> 
      <Grid container justify="center" alignItems="center">
        {/* Set a general back button with hashed/memorized history */}
        <Box pb={2}>
        <Fab onClick={() => history.push("/sites/" + props.siteId + "/surveys")} size="small">
          <Tooltip title="Surveys" aria-label="Surveys">
            <ArrowBack />
          </Tooltip>
        </Fab>
      </Box>
        <Grid container justify="center" item xs={10}>
          <div className={classes.eventTabBar}>
            <Tabs value={currentLocation} variant="fullWidth" onChange={handleChange} aria-label="mytabs">
              {/* <Tab label="Surveys" value="/surveys" /> */}
              <Tab label="Schedules" value={"/sites/" + props.siteId + "/maintenanceschedule/" + props.surveyId} />
              <Tab label="Maintenance Routines" value={"/sites/" + props.siteId + "/maintenancesheet/"+ props.surveyId} />
              <Tab label="recommission" value={"/sites/" + props.siteId + "/recommission/"+ props.surveyId} />
            </Tabs>
          </div>
        </Grid>
        <Grid container justify="flex-end" item xs>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyMaintenanceTabBar;
