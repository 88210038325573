import * as React from "react";
import SitesList from "../../components/Sites/SitesList";

const SitesListPage: React.FC = () => {
  return (
    <div>
      <SitesList />
    </div>
  );
};

export default SitesListPage;
