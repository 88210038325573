import * as React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Schedule from '@material-ui/icons/Schedule';
import { Link as RouterLink } from 'react-router-dom';
import SurveyDataTable from '../../components/SurveyEditor/SurveyDataTable';
import SurveyMaintenanceTabBar from './SurveyMaintenanceTabBar';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  box: {
    display: "flex",
    justifyContent: 'space-between',
  }
}));

const SurveyListOptionPage: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  const surveyId = params.id;
  const siteId = params.siteId

  return (
    <>
      <SurveyMaintenanceTabBar surveyId={surveyId} siteId={siteId} />

      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
         
        </div>
      </div>
    </>
  );
}

export default SurveyListOptionPage;