import React from 'react'

const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            <hr />
            {/* <Link to='/definitions' component={RouterLink}>Definitions</Link> */}
        </div>
    )
}

export default Dashboard
