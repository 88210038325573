import * as React from 'react';
import CreateUser from "../../components/User/CreateUser";
 

const CreateUserPage:React.FC = ()=> {
    return (
        <CreateUser />
    )
}

export default CreateUserPage;