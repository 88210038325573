import React from 'react';
import appMessageService, { MessagePayload, MessagsType } from '../services/AppMessageService';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const AppSnackbar: React.FC = () => {

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<MessagsType>("info");
  
  React.useEffect(() => {
    const appMessageSubscription = appMessageService.subscribe(onMessagePush);
    return () => {
      appMessageService.unsubscribe(appMessageSubscription);
    }
  }, []);

  const onMessagePush = (p: MessagePayload) => {
    setOpen(true);
    setMessage(p.message);
    setSeverity(p.type);
  }

  const handleClose = (e: any) => {
    if(!e || e.type !== "click") {
      // without this check the notification will close immediately
      setOpen(false);
    }
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} disableWindowBlurListener={false} onClose={handleClose}>
      <Alert onClose={() => setOpen(false)} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AppSnackbar;
