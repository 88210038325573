import * as React from "react";
import { Formik, Form, Field, FieldArray, FormikHelpers } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  IconButton,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useClient from "../../hooks/useClient";
import {
  Client,
  DefinitionModel,
  LaborTypeSummaryModel,
  MaintenanceItem,
  Question,
  RecommissionItem,
  SwaggerResponse,
} from "../../helpers/ApiResources";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingProgress from "../common/LoadingProgress";
import Alert from "../common/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as Yup from "yup";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import ChipInputWithValidation from "../controls/ChipInputWithValidation";
import { v4 as uuidv4 } from "uuid";
import camelcaseKeys from "camelcase-keys";
import { useHistory } from "react-router";
import { useConfirm } from "material-ui-confirm";
import useAsyncTask from "../../hooks/useAsyncTask";
import appMessageService from "../../services/AppMessageService";
import { ArrowBack } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import PromptIfDirty from "../common/PromptIfDirty";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

// Used for both Create and Update command
export interface IDefinitionCommand {
  id: string;
  name: string;
  questions: Question[];
  maintenanceItems: MaintenanceItem[];
  recommissionItems: RecommissionItem[];
}

type DefinitionEditorProps = {
  definitionId?: string;
  handleSumbit: (
    id: string,
    data: IDefinitionCommand
  ) => Promise<SwaggerResponse<any>>;
};

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// Tab Panel on each Item
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} sm={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}

// Identification for each tab
export function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const DefinitionEditor: React.FC<DefinitionEditorProps> = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const client = useClient();

  const [laborTypes, setLaborTypes] = React.useState<LaborTypeSummaryModel[]>(
    []
  );

  // definition component should hold the true state, not the question component
  const [definition, setDefinition] = useState<DefinitionModel>({
    id: "",
    name: "",
    questions: [],
    maintenanceItems: [],
    recommissionItems: [],
  } as DefinitionModel);

  // UX states
  const [tableValue, setTableValue] = React.useState(0);

  const [dragState, setDragState] = useState<Question[] | undefined>(
    [] || undefined
  );

  const confirm = useConfirm();

  const loadDefinitionTask = useAsyncTask(async (client: Client) => {
    if (props.definitionId) {
      // edit
      await client.definition_Get(props.definitionId).then((response) => {
        setDefinition(response.result);
      });
    } else {
      // create
      setDefinition({
        id: "",
        name: "",
        questions: [],
        maintenanceItems: [],
        recommissionItems: [],
      } as DefinitionModel);
    }
  });

  const getLaborTypes = useAsyncTask((client: Client) =>
    client.laborType_GetAll().then((response) => setLaborTypes(response.result))
  );

  // get definition on page load
  React.useEffect(() => {
    loadDefinitionTask.run();
    getLaborTypes.run();
    // eslint-disable-next-line
  }, []);

  const requireOptions = (questionType: string): boolean => {
    return questionType === "SingleChoice" || questionType === "MultipleChoice";
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Definition name is required"),
    questions: Yup.array().of(
      Yup.object({
        label: Yup.string().required("Question label is required"),
        options: Yup.array()
          .of(Yup.string())
          .when("questionType", {
            is: (val) => requireOptions(val),
            then: Yup.array().required(
              "At least one answer option is required"
            ),
          }),
      })
    ),
    maintenanceItems: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Maintenance name is required"),
        numberOfMinutesRequired: Yup.number().required(
          "Number of minutes is required"
        ),
        // maintenanceInterval: Yup.number().required("Interval is required"),
      })
    ),
    recommissionItems: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Recommission name is required"),
      })
    ),
  });

  const handleFormikSubmit = (
    data: IDefinitionCommand,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    let isRedirected: boolean = false;
    // For create, id is ignored. For Edit, id and data are used to build an update command

    props
      .handleSumbit(data.id, data)
      .then((response) => {
        if (response.result && response.result.id) {
          isRedirected = true;
          appMessageService.publish("Saved", "success");
          history.push({ pathname: "/definitions/" + response.result.id });
        } else {
          appMessageService.publish("Saved", "success"); // When a definition already exist
        }
      })
      .catch((error) => {
        if (error.title) {
          appMessageService.publish(error.title, "success");
        }
        // Field-specific errors from server side validation.
        // Note this only works on top level, but not on nested level like Question.Label
        if (error.errors) {
          // Formik errors use camelcase for key values
          formikHelpers.setErrors(camelcaseKeys(error.errors));
        }
      })
      .finally(() => {
        // Avoid memory leak from unmounted component after redirect
        if (!isRedirected) {
          setTimeout(() => {
            formikHelpers.setSubmitting(false);
          }, 2000);
        }
      });
  };

  const handleDeleteDefinition = (id: string) => {
    let isRedirected: boolean = false;

    // confirm dialog:https://github.com/jonatanklosko/material-ui-confirm#useconfirm-confirm
    confirm({
      title: "Please Confirm",
      description:
        "Are you sure that you want to permanently delete this definition?",
      confirmationText: "YES",
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
        disableElevation: true,
      },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        // user confirm
        client
          .definition_Delete(id)
          .then(() => {
            appMessageService.publish("Deleted", "info");
            isRedirected = true;
            history.push("/definitions");
          })
          .catch((error) => {
            if (error.title) {
              appMessageService.publish(error.title, "info");
            }
          })
          .finally(() => {
            if (!isRedirected) return;
          });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      })
      .finally(() => {
        // do nothing
      });
  };

  // Set Tab value when changed
  const handleChangeTabBar = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTableValue(newValue);
  };

  return (
    <>
      <LoadingProgress isLoading={definition === undefined}>
        <Formik
          enableReinitialize // required in order to reinitialize after adding more questions
          initialValues={definition}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleFormikSubmit}
        >
        {({errors, values, isSubmitting, setTouched, submitForm, setFieldValue, dirty }) => {
            return (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Fab
                    onClick={() => history.push("/definitions")}
                    size="small"
                  >
                    <Tooltip title="Back" aria-label="Back">
                      <ArrowBack />
                    </Tooltip>
                  </Fab>
                  <div className={classes.buttons}>
                    {values.id && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleDeleteDefinition(values.id);
                        }}
                        className={classes.button}
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      className={classes.button}
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </Button>
                    {errors.maintenanceItems?.length! > 0 ||
                    errors.questions?.length! > 0 ||
                    errors.recommissionItems?.length! > 0 ? (
                      <Snackbar open={true} autoHideDuration={6000}>
                        <Alert severity="error">
                          Oops, Missing some fields
                        </Alert>
                      </Snackbar>
                    ) : null}
                  </div>
                </Box>
                <Paper className={classes.paper}>
                  <Grid item xs={12} sm={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="name"
                      value={values.name}
                      type="input"
                      placeholder="Definition Name"
                      label="Definition Name *"
                      fullWidth
                    />
                  </Grid>

                  <Grid container justify="center">
                    <Tabs
                      value={tableValue}
                      onChange={handleChangeTabBar}
                      aria-label="tabs menu"
                    >
                      <Tab label="Survey Questions" {...a11yProps(0)} />
                      <Tab label="Maintenance" {...a11yProps(1)} />
                      <Tab label="Recommission" {...a11yProps(2)} />
                    </Tabs>
                  </Grid>

                  <Form id="definitionEditForm">
                    <PromptIfDirty />
                    <Grid container>
                      <TabPanel value={tableValue} index={0}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" gutterBottom>
                            Questions
                          </Typography>
                        </Grid>

                        <FieldArray name="questions">
                          {(arrayHelpers) => (
                            <>
                              <Grid
                                container
                                justify="flex-end"
                                alignItems="baseline"
                                item
                                xs={12}
                                sm={12}
                                className={classes.buttons}
                              >
                                <Button
                                  className={classes.button}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  // onClick={handleAddQuestion}
                                  onClick={() => {
                                    let qs = definition.questions;
                                    qs.push({
                                      // unique code is used to uniquely identify a question within a definition
                                      uniqueCode: uuidv4(),
                                      position: 0,
                                      label: "",
                                      instruction: "",
                                      questionType: "Text", // default to Text
                                      required: false,
                                      options: [],
                                    });

                                    setDefinition({
                                      ...definition,
                                      questions: qs,
                                    });
                                  }}
                                  startIcon={<AddIcon />}
                                >
                                  Add Question
                                </Button>
                              </Grid>
                              <DragDropContext
                                onDragEnd={(
                                  result: DropResult,
                                  provided: ResponderProvided
                                ) => {
                                  if (!result.destination) return;
                                  const items = Array.from(values?.questions!);
                                  const [reorderedItem] = items.splice(
                                    result.source.index,
                                    1
                                  );

                                  if (reorderedItem) {
                                    items.splice(
                                      result?.destination!.index,
                                      0,
                                      reorderedItem
                                    );

                                    setDefinition(
                                      (prevState) =>
                                        ({
                                          ...prevState,
                                          questions: items,
                                        } as DefinitionModel)
                                    );
                                  }
                                }}
                              >
                                <Droppable droppableId="questions">
                                  {(provided) => (
                                    <List
                                      dense
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {values.questions.map((q, i) => {
                                        return (
                                          <Draggable
                                            key={q.uniqueCode}
                                            draggableId={q.uniqueCode}
                                            index={i}
                                          >
                                            {(provided) => (
                                              <ListItem
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                              >
                                                <Box
                                                  pb={2}
                                                  key={i}
                                                  width="100%"
                                                >
                                                  <Grid item xs={12} sm={12}>
                                                    <Card>
                                                      <CardContent>
                                                        <Grid
                                                          container
                                                          alignItems="center"
                                                          item
                                                          sm={12}
                                                          spacing={2}
                                                        >
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <TextFieldWithValidation
                                                              as={TextField}
                                                              name={`questions.${i}.label`}
                                                              value={
                                                                values
                                                                  .questions[i]
                                                                  .label
                                                              }
                                                              type="input"
                                                              placeholder="Label"
                                                              label="Label *"
                                                              fullWidth
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <Field
                                                              as={TextField}
                                                              name={`questions.${i}.instruction`}
                                                              value={
                                                                values
                                                                  .questions[i]
                                                                  .instruction
                                                              }
                                                              type="input"
                                                              placeholder="Instruction"
                                                              label="Instruction"
                                                              fullWidth
                                                            />
                                                          </Grid>

                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                          >
                                                            <InputLabel
                                                              shrink
                                                              id={`quesitonType.${i}`}
                                                            >
                                                              Type
                                                            </InputLabel>
                                                            <Field
                                                              as={Select}
                                                              name={`questions.${i}.questionType`}
                                                              value={
                                                                values
                                                                  .questions[i]
                                                                  .questionType
                                                              }
                                                              type="select"
                                                              label="Question Type"
                                                              fullWidth
                                                            >
                                                              <MenuItem value="Text">
                                                                Text
                                                              </MenuItem>
                                                              <MenuItem value="Number">
                                                                Number
                                                              </MenuItem>
                                                              <MenuItem value="Date">
                                                                Date
                                                              </MenuItem>
                                                              <MenuItem value="SingleChoice">
                                                                Single Choice
                                                              </MenuItem>
                                                              <MenuItem value="MultipleChoice">
                                                                Multiple Choice
                                                              </MenuItem>
                                                              <MenuItem value="MultiLineText">
                                                                MultiLine Text
                                                              </MenuItem>
                                                              <MenuItem value="Attachment">
                                                                Attachment
                                                              </MenuItem>
                                                              <MenuItem value="Checkbox">
                                                                Checkbox
                                                              </MenuItem>
                                                            </Field>
                                                          </Grid>

                                                          <Grid
                                                            item
                                                            xs={12}
                                                            sm={2}
                                                          >
                                                            <Tooltip title="Is user input required?">
                                                              <FormControlLabel
                                                                control={
                                                                  <Field
                                                                    as={
                                                                      Checkbox
                                                                    }
                                                                    name={`questions.${i}.required`}
                                                                    type="checkbox"
                                                                    color="primary"
                                                                  />
                                                                }
                                                                label="Required?"
                                                              />
                                                            </Tooltip>
                                                          </Grid>

                                                          <Grid item xs={12} sm>
                                                            <IconButton
                                                              onClick={() =>
                                                                arrayHelpers.remove(
                                                                  i
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </Grid>

                                                          {requireOptions(
                                                            values.questions[i]
                                                              .questionType
                                                          ) && (
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              sm={12}
                                                            >
                                                              <ChipInputWithValidation
                                                                name={`questions.${i}.options`}
                                                                value={
                                                                  values
                                                                    .questions[
                                                                    i
                                                                  ].options
                                                                }
                                                                onAdd={(
                                                                  chip
                                                                ) => {
                                                                  // handleAddChip(i, chip);
                                                                  values.questions[
                                                                    i
                                                                  ].options.push(
                                                                    chip
                                                                  );
                                                                  // manually trigger validatio since onChange callback will not fire for controlled input.
                                                                  setTouched(
                                                                    {},
                                                                    true
                                                                  );
                                                                }}
                                                                onDelete={(
                                                                  chip,
                                                                  chipIndex
                                                                ) => {
                                                                  // handleDeleteChip(i, index, chip);
                                                                  values.questions[
                                                                    i
                                                                  ].options.splice(
                                                                    chipIndex,
                                                                    1
                                                                  );
                                                                  // manually trigger validatio since onChange callback will not fire for controlled input.
                                                                  setTouched(
                                                                    {},
                                                                    true
                                                                  );
                                                                }}
                                                                // onChange event does not fire for controlled input
                                                                // https://github.com/TeamWertarbyte/material-ui-chip-input
                                                                // onChange={(e: any) => console.log(e)}
                                                              />
                                                            </Grid>
                                                          )}
                                                        </Grid>
                                                      </CardContent>
                                                    </Card>
                                                  </Grid>
                                                </Box>
                                              </ListItem>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </List>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </>
                          )}
                        </FieldArray>
                      </TabPanel>

                      <TabPanel value={tableValue} index={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" gutterBottom>
                            Maintenance Items
                          </Typography>
                        </Grid>

                        <FieldArray name="maintenanceItems">
                          {(arrayHelpers) => (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.buttons}
                              >
                                <Button
                                  className={classes.button}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  // onClick={handleAddQuestion}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      // unique code is used to uniquely identify a maintenance item within a definition
                                      uniqueCode: uuidv4(),
                                      name: "",
                                      numberOfMinutesRequired: 0,
                                      maintenanceInterval: "Quarterly",
                                    } as MaintenanceItem)
                                  }
                                  startIcon={<AddIcon />}
                                >
                                  Add Maintenance Item
                                </Button>
                              </Grid>
                              {values.maintenanceItems.map((q, i) => {
                                return (
                                  <Box pb={2} key={i}>
                                    <Grid item xs={12} sm={12} key={i}>
                                      <Card>
                                        <CardContent>
                                          <Grid
                                            container
                                            alignItems="center"
                                            item
                                            sm={12}
                                            spacing={2}
                                          >
                                            <Grid item xs={12} sm={2}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`maintenanceItems.${i}.name`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .name || ""
                                                }
                                                type="input"
                                                placeholder="Maintenance Item Name"
                                                label="Name *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`maintenanceItems.${i}.task`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .task || ""
                                                }
                                                type="input"
                                                placeholder="Task"
                                                label="Task Name *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      values.maintenanceItems[i]
                                                        .isAttachmentRequired
                                                    }
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `maintenanceItems.${i}.isAttachmentRequired`,
                                                        !values
                                                          .maintenanceItems[i]
                                                          .isAttachmentRequired
                                                      )
                                                    }
                                                    name="required"
                                                    color="primary"
                                                  />
                                                }
                                                label="Requires Photo"
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`maintenanceItems.${i}.numberOfMinutesRequired`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .numberOfMinutesRequired ||
                                                  ""
                                                }
                                                type="input"
                                                placeholder="Minutes"
                                                label="Minutes *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                              <Autocomplete
                                                options={laborTypes}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                disableClearable
                                                id={`maintenanceItems.${i}.laborType`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .laborType
                                                }
                                                onChange={(e, value) =>
                                                  setFieldValue(
                                                    `maintenanceItems.${i}.laborType`,
                                                    value
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Labor Type"
                                                  />
                                                )}
                                              />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                              <InputLabel
                                                shrink
                                                id={`maintenanceInterval.${i}`}
                                              >
                                                Interval
                                              </InputLabel>
                                              <Field
                                                as={Select}
                                                name={`maintenanceItems.${i}.maintenanceInterval`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .maintenanceInterval
                                                }
                                                type="select"
                                                label="Interval"
                                                fullWidth
                                              >
                                                <MenuItem value="Quarterly">
                                                  Quarterly
                                                </MenuItem>
                                                <MenuItem value="Spring">
                                                  Spring
                                                </MenuItem>
                                                <MenuItem value="Summer">
                                                  Summer
                                                </MenuItem>
                                                <MenuItem value="Fall">
                                                  Fall
                                                </MenuItem>
                                                <MenuItem value="Winter">
                                                  Winter
                                                </MenuItem>
                                              </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`maintenanceItems.${i}.cost`}
                                                value={
                                                  values.maintenanceItems[i]
                                                    .cost || ""
                                                } //change to cost
                                                type="number"
                                                placeholder="Cost"
                                                label="Cost *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm>
                                              <IconButton
                                                onClick={() =>
                                                  arrayHelpers.remove(i)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </FieldArray>
                      </TabPanel>

                      <TabPanel value={tableValue} index={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" gutterBottom>
                            Recommission Items
                          </Typography>
                        </Grid>

                        <FieldArray name="recommissionItems">
                          {(arrayHelpers) => (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.buttons}
                              >
                                <Button
                                  className={classes.button}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      // unique code is used to uniquely identify a maintenance item within a definition
                                      uniqueCode: uuidv4(),
                                      name: "",
                                      task: "",
                                      cost: 0,
                                      required: false,
                                    })
                                  }
                                  startIcon={<AddIcon />}
                                >
                                  Add Recommission Item
                                </Button>
                              </Grid>
                              {values.recommissionItems.map((q, i) => {
                                return (
                                  <Box pb={2} key={i}>
                                    <Grid item xs={12} sm={12}>
                                      <Card>
                                        <CardContent>
                                          <Grid
                                            container
                                            alignItems="center"
                                            item
                                            sm={12}
                                            spacing={2}
                                          >
                                            <Grid item sm={4}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`recommissionItems.${i}.name`}
                                                value={
                                                  values.recommissionItems[i]
                                                    .name || ""
                                                }
                                                type="input"
                                                placeholder="Recommission Items Name"
                                                label="Recommission Name *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item sm={4}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`recommissionItems.${i}.task`}
                                                value={
                                                  values.recommissionItems[i]
                                                    .task || ""
                                                }
                                                type="input"
                                                placeholder="Task"
                                                label="Task Name *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item sm={1}>
                                              <TextFieldWithValidation
                                                as={TextField}
                                                name={`recommissionItems.${i}.cost`}
                                                value={
                                                  values.recommissionItems[i]
                                                    .cost || ""
                                                }
                                                type="number"
                                                placeholder="Cost"
                                                label="Cost *"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      values.recommissionItems[
                                                        i
                                                      ].isAttachmentRequired
                                                    }
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `recommissionItems.${i}.isAttachmentRequired`,
                                                        !values
                                                          .recommissionItems[i]
                                                          .isAttachmentRequired
                                                      )
                                                    }
                                                    name="required"
                                                    color="primary"
                                                  />
                                                }
                                                label="Requires Photo"
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                              <IconButton
                                                onClick={() =>
                                                  arrayHelpers.remove(i)
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Box>
                                );
                              })}
                            </>
                          )}
                        </FieldArray>
                      </TabPanel>
                    </Grid>

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  </Form>
                </Paper>
              </>
            );
          }}
        </Formik>
      </LoadingProgress>
    </>
  );
};

export default DefinitionEditor;
