import * as React from "react";
import {
  Client,
  SwaggerResponse,
  UpdateSiteSurveyCommand,
} from "../../helpers/ApiResources";
import { useState } from "react";
import LoadingProgress from "../common/LoadingProgress";
import { useHistory } from "react-router";
import SurveyForm from "./SurveyForm";
import useAsyncTask from "../../hooks/useAsyncTask";
import { useParams } from "react-router";

type SurveyEditorProps = {
  surveyId?: string;
  onSubmit: (command: UpdateSiteSurveyCommand) => Promise<SwaggerResponse<any>>;
};

const SurveyEditor: React.FC<SurveyEditorProps> = (props) => {
  let history = useHistory();
  const params: any = useParams();
  const siteId = params.siteId;

  const [survey, setSurvey] = useState<UpdateSiteSurveyCommand>();
  const loadSurvey = useAsyncTask(async (client: Client, surveyId: string) => {
    await client.siteSurvey_Get(surveyId).then((response) => {
      console.log("responds", response);
      setSurvey(response.result);
    });
  });

  React.useEffect(() => {
    if (props.surveyId) {
      loadSurvey.run(props.surveyId);
    } else {
      setSurvey({
        siteId: siteId,
        siteSurveyId: "",
        name: "",
        node: undefined,
      });
    }
  }, []);

  const submitForm = (data: UpdateSiteSurveyCommand) => {
    return props
      .onSubmit({
        name: data.name,
        node: data.node,
        siteId: data.siteId,
        siteSurveyId: data.siteSurveyId,
      })
      .then((response) => {
        if (response.result && response.result.id) {
          history.push("/sites/" + siteId + "/surveys/" + response.result.id);
          return response.result;
        }
      });
  };

  return (
    <>
      <LoadingProgress isLoading={survey === undefined}>
        {survey && (
          <SurveyForm initialSurvey={survey} handleSumbit={submitForm} />
        )}
      </LoadingProgress>
    </>
  );
};

export default SurveyEditor;
