import * as React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import {
  Client,
  LaborTypeSummaryModel,
  MaintenanceScheduleNode,
  UpdateMaintenanceScheduleCommand,
} from "../../../helpers/ApiResources";
import TextFieldWithValidation from "../../controls/TextFieldWithValidation";
import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { ArrowBack, ExpandMore } from "@material-ui/icons";  
import { useConfirm } from "material-ui-confirm";
import { useHistory } from "react-router";
import camelcaseKeys from "camelcase-keys";
import useAsyncTask from "../../../hooks/useAsyncTask";
import appMessageService from "../../../services/AppMessageService"; 
import { Autocomplete } from "@material-ui/lab";
import PromptIfDirty from "../../common/PromptIfDirty";

const grey = "#F1F1FB"; 

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3),
  },
  mouse: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ecf0f1",
    },
  },
  heirachyScroll: {
    overflow: "auto",
    height: "300px",
    width: "300px",
    flexWrap: "wrap", 
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  displayPanel: {
    display: "flex",
    flexDirection: "row",
  },
  displayPanelCard: {
    flex: 1,
  },
}));

type MaintenanceScheduleEditorProps = {
  maintenanceId: string;
};

const MaintenanceScheduleEditor = (props: MaintenanceScheduleEditorProps) => { 
  const classes = useStyles();
  const confirm = useConfirm();
  let history = useHistory(); 

  const [maintenanceSchedule, setMaintenanceSchedule] = React.useState<UpdateMaintenanceScheduleCommand>();
  const [laborTypes, setLaborTypes] = React.useState<LaborTypeSummaryModel[]>([]);
  // UX states
  const [selectedMaintenanceItem, setSelectedMaintenanceItem] = React.useState<MaintenanceScheduleNode>();
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const validationSchema = Yup.object({
    name: Yup.string().required("Schedule name is required"),
    maintenanceItems: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Schedule item name is required"),
        numberOfMinutesRequired: Yup.number().required("Number of minutes is required"),
      })
    ),
  });

  const getScheduleTask = useAsyncTask(async (client: Client) =>
  await client.maintenanceSchedule_Get(props.maintenanceId).then((response) => setMaintenanceSchedule(response.result))
  );

  const getLaborTypes = useAsyncTask(async (client: Client) => 
  await client.laborType_GetAll().then((response) => setLaborTypes(response.result))
  );

  React.useEffect(() => {
    getScheduleTask.run();
    getLaborTypes.run();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onCancel = () => {
    history.push("/maintenanceschedule");
  };

    // Delete entire Maintenance Schedule
  const deleteScheduleTask = useAsyncTask((client: Client, id: string) =>


       // confirm dialog:https://github.com/jonatanklosko/material-ui-confirm#useconfirm-confirm
    confirm({
      title: "Please Confirm",
      description: "Are you sure that you want to permanently delete this Maintenance Schedule?",
      confirmationText: "YES",
      confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        // user confirm
        client.maintenanceSchedule_Delete(id).then(() => {
          appMessageService.publish("Deleted", "success");
          history.goBack();
        })
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      })
      .finally(() => {
        // do nothing
      }) 
  ); 

  const updateScheduleTask = useAsyncTask((client: Client, data: UpdateMaintenanceScheduleCommand) =>
    client.maintenanceSchedule_Update(data.id, data).then(() => appMessageService.publish("Saved", "success"))
  );

  // update or create a Maintenance Schedule
  const handleFormikSubmit = (data: UpdateMaintenanceScheduleCommand, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true);

    updateScheduleTask
      .run(data)
      .catch((error) => {
        if (error.errors) {
          // Formik errors use camelcase for key values
          formikHelpers.setErrors(camelcaseKeys(error.errors));
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const renderMaintenanceSchedule = (d: UpdateMaintenanceScheduleCommand) => {
    return (
      <Formik
        enableReinitialize
        initialValues={d}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleFormikSubmit}
      >
        {({ values, isSubmitting, errors, dirty, setFieldValue }) => {
          return (
            <Form id="MaintenanceEditForm" key={values.id}>
              <PromptIfDirty />
              <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Fab onClick={() => history.goBack()} size="small">
                  <Tooltip title="Back" aria-label="Back">
                    <ArrowBack />
                  </Tooltip>
                </Fab>

                <div className={classes.buttons}>
                  {values.id && (
                    <Button
                      variant="contained"
                      onClick={() => deleteScheduleTask.run(values.id)}
                      className={classes.button}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isSubmitting || !dirty}
                  >
                    Save
                  </Button>
                </div>
              </Box>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextFieldWithValidation
                      as={TextField}
                      name="name"
                      value={values.name}
                      type="input"
                      placeholder="Schedule Name"
                      label="Schedule Name *"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" gutterBottom>
                    Schedule
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" justify="space-between">
                  {/* <Grid item className={classes.heirachyScroll}> */}
                  <Box>
                    <Grid item xs={12}>
                      <Box maxWidth={350} borderRadius={2} p={2} style={{backgroundColor:"#fafafa",}}>
                        {values.maintenanceScheduleItems!.map((item) => {
                          return (
                            <Grid item className={classes.mouse} key={item.uniqueCode}>
                              <Typography
                                onClick={() => setSelectedMaintenanceItem(item)}
                                variant="subtitle1"
                                gutterBottom
                                // inline conditional stying: to determine what item/definition is selected
                                style={
                                  item.location === selectedMaintenanceItem?.location
                                    ? { backgroundColor: grey, borderRadius: 5 }
                                    : { backgroundColor: "" }
                                }
                              >
                                {item.location}
                              </Typography>
                              <Divider light />
                            </Grid>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Box>

                  <Grid item xs={12} sm>
                    {values.maintenanceScheduleItems?.map((item, i) => {
                      return (
                        <React.Fragment key={item.uniqueCode}>
                          {item.uniqueCode === selectedMaintenanceItem?.uniqueCode && (
                            <Card className={classes.displayPanelCard}>
                              <CardActions disableSpacing>
                                <CardContent>
                                  <Typography>{item.displayName}</Typography>
                                </CardContent>
                                <IconButton
                                  className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                  })}
                                  onClick={handleExpandClick}
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandMore />
                                </IconButton>
                              </CardActions>
                              <Collapse in={expanded} timeout="auto">
                                <FieldArray name={`maintenanceScheduleItems[${i}].definition.maintenanceItems`}>
                                  {(arrayHelpers) => (
                                    <>
                                      <Grid item className={classes.buttons}>
                                        <Button
                                          className={classes.button}
                                          variant="outlined"
                                          color="primary"
                                          size="small"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              // unique code is used to uniquely identify a Maintenance within a Maintenance Schedule
                                              uniqueCode: uuidv4(),
                                              name: "",
                                              numberOfMinutesRequired: 0,
                                              maintenanceInterval: 0,
                                              Action: null,
                                            })
                                          }
                                          startIcon={<AddIcon />}
                                        >
                                          Add Maintenance Item
                                        </Button>
                                      </Grid>
                                      {values.maintenanceScheduleItems![i].definition.maintenanceItems.map(
                                        (data, j) => {
                                          return (
                                            <Box pb={2} key={data.uniqueCode}>
                                              <Grid item xs={12} sm={12} key={j}>
                                                <Card>
                                                  <CardContent key={j}>
                                                    <Grid container alignItems="center" item sm={12} spacing={2}>
                                                      <Grid item xs={12} sm={5}>
                                                        <TextFieldWithValidation
                                                          as={TextField}
                                                          name={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].name`}
                                                          value={
                                                            values
                                                              .maintenanceScheduleItems![i]
                                                              .definition
                                                              .maintenanceItems[j]
                                                              .name
                                                          }
                                                          type="text"
                                                          placeholder="Maintenance Item Name"
                                                          label="Name "
                                                          fullWidth
                                                          required
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={4}>
                                                        <TextFieldWithValidation
                                                          as={TextField}
                                                          name={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].task`}
                                                          value={
                                                            values
                                                              .maintenanceScheduleItems![i]
                                                              .definition
                                                              .maintenanceItems[j]
                                                              .task
                                                          }
                                                          type="text"
                                                          placeholder="Task Name"
                                                          label="Task "
                                                          fullWidth
                                                          required
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={2}>
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              checked={ 
                                                                values
                                                                .maintenanceScheduleItems![i]
                                                                .definition
                                                                .maintenanceItems[j]
                                                                .isAttachmentRequired
                                                              }
                                                              onChange={(e) => setFieldValue(
                                                                `maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].isAttachmentRequired`,
                                                                !values
                                                                .maintenanceScheduleItems![i]
                                                                .definition
                                                                .maintenanceItems[j]
                                                                .isAttachmentRequired
                                                              )}
                                                              name="required"
                                                              color="primary"
                                                            />
                                                          }
                                                          label="Requires Photo"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={1}>
                                                        <TextFieldWithValidation
                                                          as={TextField}
                                                          name={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].cost`}
                                                          value={
                                                            values.maintenanceScheduleItems![i].definition
                                                              .maintenanceItems[j].cost
                                                          }
                                                          type="number"
                                                          placeholder="Cost"
                                                          label="Cost"
                                                          fullWidth
                                                          required
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={2}>
                                                        <TextFieldWithValidation
                                                          as={TextField}
                                                          name={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].numberOfMinutesRequired`}
                                                          value={
                                                            values.maintenanceScheduleItems![i].definition
                                                              .maintenanceItems[j].numberOfMinutesRequired
                                                          }
                                                          type="number"
                                                          placeholder="Number of minutes required"
                                                          label="Minutes"
                                                          fullWidth
                                                          required
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} sm={5}>
                                                        <Autocomplete
                                                          options={laborTypes!}
                                                          getOptionLabel={(option) => option.name}
                                                          disableClearable
                                                          id={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].laborType`}
                                                          value={values.maintenanceScheduleItems![i].definition.maintenanceItems[j].laborType}
                                                          onChange={(e, value) => setFieldValue(`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].laborType`, value)}
                                                          renderInput={(params) => <TextField {...params} label="Labor Type" />}
                                                        />
                                                      </Grid>

                                                      <Grid item xs={12} sm={4}>
                                                        <InputLabel
                                                          shrink
                                                          id={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].maintenanceInterval`}
                                                        >
                                                          Interval
                                                        </InputLabel>
                                                        <Field
                                                          as={Select}
                                                          name={`maintenanceScheduleItems[${i}].definition.maintenanceItems[${j}].maintenanceInterval`}
                                                          value={
                                                            values.maintenanceScheduleItems![i].definition
                                                              .maintenanceItems[j].maintenanceInterval
                                                          }
                                                          type="select"
                                                          label="Interval"
                                                          fullWidth
                                                        >
                                                          <MenuItem value="Quarterly">Quarterly</MenuItem>
                                                          <MenuItem value="Spring">Spring</MenuItem>
                                                          <MenuItem value="Summer">Summer</MenuItem>
                                                          <MenuItem value="Fall">Fall</MenuItem>
                                                          <MenuItem value="Winter">Winter</MenuItem>
                                                        </Field>
                                                      </Grid>
                                                      <Grid item xs={12} sm={1}>
                                                      <IconButton onClick={() => arrayHelpers.remove(j)}>
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </Grid>
                                                    </Grid> 
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Box>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </Collapse>
                            </Card>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </Paper>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div>
      <LoadingProgress isLoading={maintenanceSchedule === undefined}>
        {renderMaintenanceSchedule(maintenanceSchedule!)}
      </LoadingProgress>
    </div>
  );
};

export default MaintenanceScheduleEditor;
