import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
import {
  Client,
  CreateSiteCommand,
  UpdateSiteCommand,
} from "../../helpers/ApiResources";
import { Form, Formik, FormikHelpers } from "formik";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import MultiLineTextTextFieldWithValidation from "../controls/MultiLineTextTextFieldWithValidation";
import * as Yup from "yup";
import useAsyncTask from "../../hooks/useAsyncTask";
import appMessageService from "../../services/AppMessageService";

type SiteCreateDialogProps = {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  siteId?: string;
  tableRef: any
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
});

const CreateSiteDialog: React.FC<SiteCreateDialogProps> = ({
  open,
  onClose,
  siteId,
  tableRef,
}) => {
  const [site, setSite] = React.useState<CreateSiteCommand>();

  const loadSiteDetails = useAsyncTask(async (client: Client) => {
    if (siteId) {
      client.site_Get(siteId).then((response) => {
        setSite(response.result);
      });
    } else {
      let emptySite: CreateSiteCommand = {
        name: "",
        description: "",
      };
      setSite(emptySite);
    }
  });

  React.useEffect(() => {
    if (siteId) {
      loadSiteDetails.run();
    }
  }, [siteId]);

  const siteInitialValue = {
    name: site?.name,
    description: site?.description,
  } as CreateSiteCommand;

  const handleClose = () => {
    onClose(!open);
  };

  const handleFormikSubmit = useAsyncTask(
    async (
      client: Client,
      values: CreateSiteCommand,
      formikHelpers: FormikHelpers<any>
    ) => {
      if (siteId) {
        const command = {
          id: siteId,
          name: values.name,
          description: values.description,
        } as UpdateSiteCommand;

        client
          .site_Update(siteId, command)
          .then((response) => {
            tableRef.current.onQueryChange();
            appMessageService.publish("Saved", "success");
            handleClose();
          })
          .catch((err) => {
            appMessageService.publish(err.title, "error");
          });
      } else {
        client
          .site_Create(values)
          .then((response) => {
            tableRef.current.onQueryChange();
            appMessageService.publish("Saved", "success");
            handleClose();
          })
          .catch((err) => {
            console.log(err, "err");
            // appMessageService.publish(err.errors.Name[0], "error");
          });
      }
    }
  );

  const renderUserForm = (site: CreateSiteCommand) => {
    return (
      <Formik
        enableReinitialize // required in order to reinitialize after adding more questions
        initialValues={site}
        validationSchema={validationSchema}
        onSubmit={handleFormikSubmit.run}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form id="siteForm">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextFieldWithValidation
                    as={TextField}
                    name="name"
                    value={values.name ?? ""}
                    type="input"
                    placeholder="Name"
                    label="Name *"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiLineTextTextFieldWithValidation
                    as={TextField}
                    name="description"
                    value={values.description ?? ""}
                    type="input"
                    placeholder="Description"
                    label="Description *"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> New Site </DialogTitle>
      <Box p={3}>{renderUserForm(siteInitialValue!)}</Box>
    </Dialog>
  );
};

export default CreateSiteDialog;
