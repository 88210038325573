import React, {  } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
import useClient from '../../hooks/useClient';
import { Client, GenerateMaintenanceScheduleCommand } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";

type GenerateMaintenanceDialogProps = {
  surveyName: string;
  siteSurveryId: string;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const GenerateRecommissionDialog: React.FC<GenerateMaintenanceDialogProps> = (props) => {
  // API requests
  const client = useClient();

  let history = useHistory();

  // useState Hooks
  const [recommissionTitle, setRecommissionTitle] = React.useState<string>("");
  const [clientErrorMessage, setServerErrorMessage] = React.useState<string>("");
  const [hasError, setHasError] = React.useState<boolean>(false);

  // Handler Close for Dialog
  const handleClose = () => {
    props.onClose(false);
    setHasError(false);
    setServerErrorMessage("");
  };

  // Manage TextField States
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecommissionTitle(event?.target.value);
    setHasError(false);
    setServerErrorMessage("");
  };

  const submitDialogTask =  useAsyncTask(
    async (client: Client) => {  
      
      const command: GenerateMaintenanceScheduleCommand = {
        siteSurveyId: props.siteSurveryId!,
        name: recommissionTitle,
      };
  
      // API call then() => Success
      client
        .recommissionSheet_Create(command)
        .then((res) => { 
          history.push({pathname:"/recommission/" + res.result.id, state:props.siteSurveryId});
        })
        .catch((error) => {
          // Error from server
          setHasError(true);
          setServerErrorMessage(error.title);
        });
    });

  const handleDialogSubmit = () => {
    // todo: check if surveyName has value, initialize CloneSiteSurveyCommand and
    // set properties(props, updateded surveyName), Call API request

    if (!recommissionTitle) {
      setHasError(true);
      setServerErrorMessage("Recommission routine name is required");
      return;
    }

    submitDialogTask.run();
  };

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>{props.surveyName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Generate Recommission Routine for {props.surveyName}? 
                    </DialogContentText>
                    <TextField id="name" 
                    autoFocus 
                    label="Recommission Routine Name *" 
                    type="text" 
                    placeholder="Recommission Routine Name" 
                    fullWidth 
                    onChange={onChangeTextField} 
                    error={hasError} 
                    helperText={clientErrorMessage} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" disableElevation>
                        Cancel
                        </Button>
                    <Button onClick={handleDialogSubmit} variant="contained" color="primary">
                        Generate
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GenerateRecommissionDialog;
