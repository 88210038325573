import * as React from "react";
import { Box, Container, Fab, Paper, Tooltip } from "@material-ui/core";
import FulfillEditor from "../../../components/SurveyEditor/Fulfillment/FulfillEditor";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";

const FulfillmentPage: React.FC = () => {
  const params: any = useParams();
  const history = useHistory();

  return (
    <>
      <Box pb={3}>
        <Fab onClick={() => history.goBack()} size="small">
          <Tooltip title="Back" aria-label="Back">
            <ArrowBack />
          </Tooltip>
        </Fab>
      </Box>
      <Paper>
        <Container maxWidth="lg">
          <FulfillEditor surveyId={params.id} />
        </Container>
      </Paper>
    </>
  );
};

export default FulfillmentPage;
