import * as React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Client,
  DefinitionModel,
  SwaggerResponse,
  UpdateSiteSurveyCommand,
  Node,
  Question,
  QuestionType,
} from "../../helpers/ApiResources";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import camelcaseKeys from "camelcase-keys";
import SiteSurveyTree from "./SiteSurveyTree";
import DefinitionSelector from "./DefinitionSelector";
import { useConfirm } from "material-ui-confirm";
import { findNode } from "../../helpers/NodeHelper";
import useAsyncTask from "../../hooks/useAsyncTask";
import { useHistory, useParams } from "react-router";
import appMessageService from "../../services/AppMessageService";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SurveyReducer, { SurveyState } from "./SurveyReducer";
import useAuth from "../../hooks/useAuth";
import { Select } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  nodeSection: {
    display: "flex",
    flexDirection: "column",
  },
  newNodeSection: {
    display: "flex",
    alignItems: "center",
  },
  heirachyScroll: {
    overflow: "scroll",
  },
}));

type SurveyEditorProps = {
  initialSurvey: UpdateSiteSurveyCommand;
  handleSumbit: (data: UpdateSiteSurveyCommand) => Promise<SwaggerResponse<any>>;
};

const SurveyForm: React.FC<SurveyEditorProps> = (props) => {
  const params: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  const initialSurvey = props.initialSurvey as SurveyState;
  initialSurvey.isDirty = false;
  const surveyId = params.id;
  const [survey, dispatch] = React.useReducer(SurveyReducer, initialSurvey);
  const [dropdownSelectedDefinition, setDropdownSelectedDefinition] = useState<DefinitionModel>();
  const [selectedNode, setSelecteNode] = useState<Node>();
  const [serverNodeErrorMessage, setServerNodeErrorMessage] = useState("");
  const [dragState, setDragState] = useState<Question[] | undefined>([] || undefined);

  React.useEffect(() => {
    // technically selected node should be in the dependency list, but we really only
    // want to update the selected node if the survey changes. This could be optimized later
    if (selectedNode) {
      var node = findNode(survey.node!, selectedNode.uniqueCode);
      setSelecteNode(node!);

      setDragState(selectedNode?.definition.questions);
    }
  }, [survey, selectedNode]);

  // confirm UX asynchronous function
  const confirm = useConfirm();

  const validationSchema = Yup.object({
    name: Yup.string().required("Survey name is required"),
  });

  const changeSelectedDefinition = (definitionId: string) => {
    loadSelectedDefinitionDetailsAsync.run(definitionId).then();
  };

  const loadSelectedDefinitionDetailsAsync = useAsyncTask(async (client: Client, definitionId: string) => {
    client.definition_Get(definitionId).then((response) => {
      setDropdownSelectedDefinition(response.result);
    });
  });

  const onNodeTreeSelection = (nodeId: string) => {
    var node = findNode(survey.node!, nodeId);
    if (node) {
      setSelecteNode(node);
    } else {
      console.error("Cound not find node " + nodeId);
    }
  };

  const deleteSurveyAsync = useAsyncTask(async (client: Client) => {
    confirm({
      title: "Delete Survey",
      description: "This action cannot be undone",
      confirmationText: "Delete Survey",
      confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        // user confirm
        client.siteSurvey_Delete(params.siteId, surveyId).then((response) => {
          appMessageService.publish("Survey deleted", "success");
          history.push("/surveys");
        });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      });
  });

  const deleteQuestion = (question: Question) =>
    confirm({
      title: "Delete Question - " + question.label,
      description: "This action cannot be undone",
      confirmationText: "Delete Question",
      confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        dispatch({
          type: "REMOVE_QUESTION",
          payload: {
            nodeUniqueCode: selectedNode!.uniqueCode,
            questionUniqueCode: question.uniqueCode,
          },
        });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      });

  const reorderSurvey = (node: Node) => {
    dispatch({
      type: "UPDATE_SURVEY",
      payload: { node: node }
    });
  }

  // // Handle General Errors Client-Side
  // const handleClientErrorMessage = (message: string) => {
  //   appMessageService.publish(message, "warning");
  // };

  const isAddNodeButtonEnabled =
    (!survey.node && dropdownSelectedDefinition) || (survey.node && dropdownSelectedDefinition && selectedNode);

  // Only enable if there is at least one node, and if we have a selectedNode
  const isDeleteNodeButtonEnabled = survey.node && selectedNode;

  const deleteNodeButtonClickHandler = (selectedNodeCode?: string) => {
    // confirm dialog:https://github.com/jonatanklosko/material-ui-confirm#useconfirm-confirm
    confirm({
      title: "Please Confirm",
      description: "Are you sure that you want to remove this definition",
      confirmationText: "YES",
      confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        // user confirm
        dispatch({
          type: "REMOVE_NODE",
          payload: { nodeUniqueCode: selectedNodeCode! },
        });
      })
      .catch(() => {
        // user cancel, which reject the promise and close dialog
      })
      .finally(() => {
        // do nothing
        setSelecteNode(undefined);
      });
  };

  const navigateBack = () => {
    // confirm dialog:https://github.com/jonatanklosko/material-ui-confirm#useconfirm-confirm
    if (survey.isDirty) {
      confirm({
        title: "Please Confirm",
        description: "Are you sure that you want to cancel with your changes unsaved?",
        confirmationText: "YES",
        confirmationButtonProps: { variant: "contained", color: "primary", disableElevation: true },
        cancellationButtonProps: { variant: "contained", disableElevation: true },
      })
        .then(() => {
          // user confirm
          history.goBack();
        })
        .catch(() => {
          // user cancel, which reject the promise and close dialog
        });
    } else {
      history.goBack();
    }
  };

  // submit form
  const handleFormikSubmit = (data: UpdateSiteSurveyCommand, formikHelpers: FormikHelpers<any>) => {
    // Return isNodeModifiedToSave state to false when SAVE button is clicked
    formikHelpers.setSubmitting(true); // setSubmitting not updating state

    props
      .handleSumbit(data)
      .then(() => {
        setServerNodeErrorMessage(""); // if node error was displayed set empty
        dispatch({ type: "RESET_ISDIRTY" });
        appMessageService.publish("Saved", "success");
      })
      .catch((error) => {
        if (error.status === 400) {
          console.log(error);
          if (error.errors && error.errors.Node && error.errors.Node.length > 0) {
            appMessageService.publish(error.errors.Node[0], "warning");
            setServerNodeErrorMessage(error.errors.Node[0]);
          } else {
            appMessageService.publish("Fatal error submitting.", "error");
          }
          if (error.errors) {
            // Note this only works on top level, but not on nested level like Question.Label
            // Formik errors use camelcase for key values
            formikHelpers.setErrors(camelcaseKeys(error.errors));
          }
        } else if (error.status === 401) {
          // TODO: This should be standardized. When catching error we need to check manually
          appMessageService.publish("Your login session has expired", "warning");
          auth.logOut();
        } else {
          appMessageService.publish("Fatal error submitting", "error");
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;
    const items = Array.from(dragState!);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result?.destination!.index, 0, reorderedItem);

    setDragState(items);

    dispatch({
      type: "UPDATE_QUESTION_POSITION",
      payload: {
        nodeUniqueCode: selectedNode?.uniqueCode!,
        question: [...items],
      },
    });
  };

  const renderNodeSelectionEditor = () => (
    <Paper>
      <Box p={2}>
        {selectedNode && (
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <TextField
                id="selectedNodeDisplayName"
                type="text"
                label="Modify selected name"
                size="medium"
                style={{ width: 300 }}
                value={selectedNode.displayName}
                onChange={(e) => {
                  dispatch({
                    type: "RENAME_NODE",
                    payload: { nodeUniqueCode: selectedNode.uniqueCode!, displayName: e.target.value },
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" flexDirection="row">
                <IconButton
                  aria-label="Delete selection"
                  disabled={!isDeleteNodeButtonEnabled}
                  onClick={() => deleteNodeButtonClickHandler(selectedNode.uniqueCode)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container alignItems="center" spacing={1} direction="row" justify="space-between">
          <Grid item>
            <DefinitionSelector
              onSelectCallback={changeSelectedDefinition}
              //onSelectedDisplayName={onChangeSurveyDisplayNameHandler}
              isDisabled={false}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={!isAddNodeButtonEnabled}
              onClick={() => {
                if (survey.node && selectedNode && dropdownSelectedDefinition) {
                  dispatch({
                    type: "ADD_NODE",
                    payload: { parentNodeUniqueCode: selectedNode.uniqueCode, definition: dropdownSelectedDefinition! },
                  });
                } else {
                  dispatch({
                    type: "UPDATE_ROOT",
                    payload: { definition: dropdownSelectedDefinition! },
                  });
                }
              }}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>

          <Grid item xs={12}>
            {selectedNode && (
              <>
                <Typography variant="h5">Questions</Typography>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch({ type: "ADD_QUESTION", payload: { nodeUniqueCode: selectedNode.uniqueCode } });
                  }}
                >
                  Add Question
                </Button>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="questions">
                    {(provided) => (
                      <List dense {...provided.droppableProps} ref={provided.innerRef}>
                        {dragState?.map((q: Question, i: number) => {
                          return (
                            <Draggable key={q.uniqueCode} draggableId={q.uniqueCode} index={i}>
                              {(provided) => (
                                <ListItem
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <Paper style={{ padding: 15 }}>
                                    <Grid
                                      container
                                      spacing={2}
                                      direction="row"
                                      alignItems="center"
                                      alignContent="space-between"
                                    >
                                      <Grid item xs={8}>
                                        <TextField
                                          name="label"
                                          label="Label"
                                          defaultValue={q.label}
                                          fullWidth
                                          onChange={(e) =>
                                            dispatch({
                                              type: "UPDATE_QUESTION",
                                              payload: {
                                                nodeUniqueCode: selectedNode.uniqueCode,
                                                question: { ...q, label: e.target.value },
                                              },
                                            })
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={3}>
                                        <FormControl>
                                          <InputLabel id="type">Type</InputLabel>
                                          <Select
                                            value={q.questionType}
                                            style={{ width: 160 }}
                                            onChange={(e) =>
                                              dispatch({
                                                type: "UPDATE_QUESTION",
                                                payload: {
                                                  nodeUniqueCode: selectedNode.uniqueCode,
                                                  question: { ...q, questionType: e.target.value as QuestionType },
                                                },
                                              })
                                            }
                                          >
                                            <MenuItem value="Text">Text</MenuItem>
                                            <MenuItem value="Number">Number</MenuItem>
                                            <MenuItem value="Date">Date</MenuItem>
                                            <MenuItem value="SingleChoice">Single Choice</MenuItem>
                                            <MenuItem value="MultipleChoice">Multiple Choice</MenuItem>
                                            <MenuItem value="MultiLineText">MultiLine Text</MenuItem>
                                            <MenuItem value="Attachment">Attachment</MenuItem>
                                            <MenuItem value="Checkbox">Checkbox</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton aria-label="Delete question" onClick={() => deleteQuestion(q)}>
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <TextField
                                          name="instruction"
                                          label="Instruction"
                                          fullWidth
                                          defaultValue={q.instruction || ""}
                                          onChange={(e) =>
                                            dispatch({
                                              type: "UPDATE_QUESTION",
                                              payload: {
                                                nodeUniqueCode: selectedNode.uniqueCode,
                                                question: { ...q, instruction: e.target.value },
                                              },
                                            })
                                          }
                                        />
                                      </Grid>

                                      <Grid item>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={q.required}
                                              onChange={(e) =>
                                                dispatch({
                                                  type: "UPDATE_QUESTION",
                                                  payload: {
                                                    nodeUniqueCode: selectedNode.uniqueCode,
                                                    question: { ...q, required: !q.required },
                                                  },
                                                })
                                              }
                                              name="required"
                                              color="primary"
                                            />
                                          }
                                          label="Required"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </ListItem>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );

  return (
    <Formik
      enableReinitialize // required in order to reinitialize after adding more questions
      initialValues={survey}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(data, formikHelpers) => handleFormikSubmit(data, formikHelpers)}
    >
      {({ submitForm, values, isSubmitting, errors, dirty }) => {
        return (
          <Form id="surveyEditForm">
            <Grid container justify="space-between">
              <Grid item>
                <Box pb={2}>
                  <Fab onClick={navigateBack} size="small">
                    <Tooltip title="Surveys" aria-label="Surveys">
                      <ArrowBack />
                    </Tooltip>
                  </Fab>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="flex-end" flexDirection="row" className="Test">
                  <Box mr={3}>
                    {survey.siteSurveyId && (
                      <Button variant="contained" onClick={deleteSurveyAsync.run}>
                        Delete
                      </Button>
                    )}
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={!survey.isDirty || isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item container xs={12} alignItems="center">
                  <input type="hidden" name="siteId" value={params.siteId} />
                  <Grid item xs={3}>
                    <TextField
                      id="survey-name"
                      type="text"
                      placeholder="Name"
                      label="Survey Name"
                      value={survey.name ?? ""}
                      helperText={errors.name}
                      error={errors.name !== undefined}
                      onChange={(e) => {
                        dispatch({
                          type: "UPDATE_SURVEY_NAME",
                          payload: { name: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  {/* Display Heirachy */}
                  <Typography variant="caption" color="error" gutterBottom>
                    {serverNodeErrorMessage}
                  </Typography>
                  {survey.node && <SiteSurveyTree node={survey.node} onNodeSelection={onNodeTreeSelection} onSurveyReordered={reorderSurvey} />}
                </Grid>

                <Grid item xs={8}>
                  {renderNodeSelectionEditor()}
                </Grid>
              </Grid>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SurveyForm;
