import * as React from 'react';
import useClient from '../../hooks/useClient';
import DefinitionEditor from '../../components/Definition/DefinitionEditor';
import { CreateDefinitionCommand } from '../../helpers/ApiResources';

const DefinitionCreatePage: React.FC = () => {

  const client = useClient();

  const handleSubmit = (id: string, data: any) => {
    let createCommand: CreateDefinitionCommand = { ...data};
    return client.definition_Create(createCommand);
  }

  return (
    <DefinitionEditor handleSumbit={handleSubmit} />
  );
}

export default DefinitionCreatePage;