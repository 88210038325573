import React from "react";
import "./App.css";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import DefinitionEditPage from "./pages/Definition/DefinitionEditPage";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/Authentication/LoginPage";
import DefinitionListPage from "./pages/Definition/DefinitionListPage";
import DashboardLayout from "./layouts/DashboardLayout";
import DefinitionCreatePage from "./pages/Definition/DefinitionCreatePage";
import { ConfirmProvider } from "material-ui-confirm";
import SurveyEditorListPage from "./pages/SurveyEditor/SurveyEditorListPage";
import SurveyEditorCreatePage from "./pages/SurveyEditor/SurveyEditorCreatePage";
import SurveyEditorEditPage from "./pages/SurveyEditor/SurveyEditorEditPage";
import { ProtectedRoute } from "./components/Authentication/ProtectedRoute";
import AuthContextProvider from "./context/Authentication/AuthContextProvider";
import MaintenanceScheduleEditPage from "./pages/SurveyEditor/MaintenanceSchedule/MaintenanceScheduleEditPage";
import MaintenanceScheduleListPage from "./pages/SurveyEditor/MaintenanceSchedule/MaintenanceScheduleListPage";
import MaintenanceScheduleViewDataTable from "./components/SurveyEditor/MaintenanceSchedule/MaintenanceScheduleViewDataTable";
import RecommissionSheetListPage from "./pages/SurveyEditor/Recommission/RecommissionSheetListPage";
import RecommissionSheetEditPage from "./pages/SurveyEditor/Recommission/RecommissionSheetEditPage";
import RecommissionFulfillmentPage from "./pages/SurveyEditor/Recommission/RecommissionFulfillmentPage";
import RecommissionViewDataTable from "./components/SurveyEditor/Recommission/RecommissionViewDataTable";
import MaintenanceSheetPage from "./pages/SurveyEditor/MaintenanceSchedule/MaintainanceSheet/MaintenanceSheetPage";
import MaintenanceSheetListPage from  "./pages/SurveyEditor/MaintenanceSchedule/MaintainanceSheet/MaintenanceSheetListPage";
import FulfillmentPage from "./pages/SurveyEditor/Fulfillment/FulfillmentPage";
import UserListPage from "./pages/User/UserListPage";
import UserUpdatePage from "./pages/User/UserUpdatePage";
import UserProfilePage from "./pages/User/UserProfilePage";
import CreateUserPage from "./pages/User/CreateUserPage";
import ActivateAccountPage from "./pages/User/ActivateAccountPage";
import ResetPasswordPage from "./pages/User/ResetPasswordPage";
import ForgotPasswordPage from "./pages/User/ForgotPasswordPage";
import AppSnackbar from "./components/AppSnackbar";
import SitesListPage from "./pages/Sites/SitesListPage"; 
import SurveyListOptionPage from "./pages/SurveyEditor/SurveyListOptionPage"

import LaborTypeListPage from "./pages/LaborType/LaborTypeListPage";
import LaborTypeEditPage from "./pages/LaborType/LaborTypeEditPage";

const App: React.FC<RouteProps> = (props) => {
  return (
    <>
      <AuthContextProvider>
        <ConfirmProvider>
          <DashboardLayout>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              <ProtectedRoute path="/dashboard" exact={true} component={Dashboard} />
              <ProtectedRoute path="/sites" exact={true} component={SitesListPage} />
              <ProtectedRoute path="/sites/:siteId/surveys" exact={true} component={SurveyEditorListPage} />
              <ProtectedRoute path="/definitions/create" exact={true} component={DefinitionCreatePage} />
              <ProtectedRoute path="/definitions/:id" exact={true} {...props} component={DefinitionEditPage} />
              <ProtectedRoute path="/definitions" exact={true} component={DefinitionListPage} />
              <ProtectedRoute path="/sites/:siteId/surveys/create" exact={true} component={SurveyEditorCreatePage} />
              <ProtectedRoute path="/sites/:siteId/surveys/:id" exact={true} component={SurveyEditorEditPage} /> 
              <ProtectedRoute path="/sites/:siteId/surveysoptions/:id" exact={true} component={SurveyListOptionPage} />
              {/* <ProtectedRoute path="/surveys" exact={true} component={SurveyEditorListPage} /> */}
              <ProtectedRoute path="/surveys/:id/fulfill" exact={true} component={FulfillmentPage} />
              <ProtectedRoute path="/maintenanceschedule/:id" exact={true} component={MaintenanceScheduleEditPage} /> 
              <ProtectedRoute path="/maintenanceschedule/:id/:name" exact={true} component={MaintenanceScheduleViewDataTable} />
              <ProtectedRoute path="/sites/:siteId/maintenancesheet/:id" exact={true} component={MaintenanceSheetListPage} />
              <ProtectedRoute path="/maintenancesheet/:id" exact={true} component={MaintenanceSheetPage} />
              <ProtectedRoute path="/sites/:siteId/maintenanceschedule/:id" exact={true} component={MaintenanceScheduleListPage} />
              <ProtectedRoute path="/sites/:siteId/recommission/:id" exact={true} component={RecommissionSheetListPage} />
              <ProtectedRoute path="/recommission/:id" exact={true} component={RecommissionSheetEditPage} />
              <ProtectedRoute path="/previewrecommission/:id" exact={true} component={RecommissionViewDataTable} />
              <ProtectedRoute path="/surveys/:id/fulfillrecommission" exact={true} component={RecommissionFulfillmentPage} />
              <ProtectedRoute path="/users" exact={true} component={UserListPage} />
              <ProtectedRoute path="/users/create" exact={true} component={CreateUserPage} />
              <ProtectedRoute path="/users/:id" exact={true} component={UserUpdatePage} />
              
              <ProtectedRoute path="/laborTypes" exact={true} component={LaborTypeListPage} />
              <ProtectedRoute path="/laborTypes/create" exact={true} component={LaborTypeEditPage} />
              <ProtectedRoute path="/laborTypes/:id" exact={true} component={LaborTypeEditPage} />
              <ProtectedRoute path="/profile/password" exact={true} component={UserProfilePage} />
              <Route path="/verify" exact={true} component={ActivateAccountPage} />
              <Route path="/forgotpassword" exact={true} component={ForgotPasswordPage} />
              <Route path="/resetPassword" exact={true} component={ResetPasswordPage} />
              <Route path="/login" exact component={LoginPage} />
              <Route path="*">
                <Redirect from="/" to="dashboard" />
              </Route>
            </Switch>
          </DashboardLayout>
        </ConfirmProvider>
        <AppSnackbar />
      </AuthContextProvider>
    </>
  );
};

export default App;
