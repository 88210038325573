import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  Checkbox,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

type OptionsProps = {
  options: string[];
  label: string;
  required: boolean;
  name: string;
  instruction: string;
  value: string | undefined;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const MultipleChoice: React.FC<OptionsProps> = (props) => {
  let c = (props.value && props.value!.split(",")) || [];

  // set value (props.value from saved choices, convert to array)
  const [value, setValue] = React.useState<string[]>(c);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    setValue(event.target.value as string[]);

    props.setValue(props.name, event.target.value.toString(), true);
  };

  return (
    <FormControl required={props.required} style={{ minWidth: 300 }}>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <Select
        id={props.name}
        labelId={props.name}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) => (selected as string[]).join(", ")}
        name={props.name}
      >
        {props.options.map((d) => (
          <MenuItem key={d} value={d}>
            <Checkbox checked={value.indexOf(d) > -1} />
            <ListItemText primary={d} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.instruction}</FormHelperText>
    </FormControl>
  );
};

export default MultipleChoice;
