import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useHistory } from "react-router";
import {
  Client,
  RecommissionSheetItem,
  RecommissionSheetModel,
  UpdateRecommissionSheetCommand,
} from "../../../helpers/ApiResources";
import useAsyncTask from "../../../hooks/useAsyncTask";
import appMessageService from "../../../services/AppMessageService";
import AttachmentUploader from "../../common/AttachmentUploader";
import MultiLineTextTextFieldWithValidation from "../../controls/MultiLineTextTextFieldWithValidation";
import TextFieldWithValidation from "../../controls/TextFieldWithValidation"; 
import { Link } from "react-router-dom";
import * as Yup from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { ArrowBack } from "@material-ui/icons";

 
const grey = "#F1F1FB";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  buttons: {
    padding: "10px",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

type RecommissionFulfillmentProps = {
  recommissionId: string;
};

const RecommissionFulfillment: React.FC<RecommissionFulfillmentProps> = (
  props
) => {
  //TODO: validationSchema
  const validationSchema = Yup.object({
    recommissionSheetItems: Yup.array().of(
      Yup.object({
        action: Yup.object({
          recommissionItem: Yup.object({
            action: Yup.object({
              findings: Yup.string().required("Findings is required"),
              changedToSpec: Yup.string().required(
                "Changed to specification is required"
              ),
              notes: Yup.string().required("notes is required"),
            }),
          }),
        }),
      })
    ),
  });

  //TODO: Destructure props
  const { recommissionId } = props;

  //style
  const classes = useStyles();

  //history
  let history = useHistory();

  // User State Context

  const [recommission, setRecommission] = React.useState<RecommissionSheetModel>();
  const [surveyId, setSurveyId] = React.useState<string>("");
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [selectedFormProperty, setSelectedFormProperty] = React.useState<{
    uniqueCode: string;
    index: number;
  }>();
  const [selectedSheetItem, setSelectedSheetItem] = React.useState<RecommissionSheetItem>();

  //TODO: useEffect<Load recommission data>
  React.useEffect(() => {
    getRecommissionByIdTask.run().catch((r) => console.log(r));
  }, [recommissionId, setSurveyId]);

  //TODO: get recommission from api by ID from params.id
  const getRecommissionByIdTask = useAsyncTask(async (client: Client) => {
    //TODO: API Call
    return await client.recommissionSheet_Get(recommissionId).then((response) => {
      setIsCompleted(
        response.result.recommissionSheetStatus === "Completed" ? true : false
      ); //TODO: set true if form is completed
      setSurveyId(response.result.ResourceOwnerId);
      setRecommission(response.result);
    });
  });

  //TODO: selected recommission
  const onSelectedRecommission = (
    data: RecommissionSheetItem,
    index: number
  ) => {
    //console.log(data.recommissionItem.uniqueCode);
    setSelectedFormProperty({
      uniqueCode: data.recommissionItem.uniqueCode,
      index: index,
    });
    setSelectedSheetItem(data);
    // setSelectedLocation(data);
  };
  
  //TODO: check if all input are filled/completed
  const completeRecommissionTask = useAsyncTask(async (client: Client) => {
    // TODO: In what case should recommission be completed
    return client
      .recommissionSheet_Complete(recommissionId, { id: recommissionId })
      .then((response) => {
        appMessageService.publish("Saved", "success");
      }).catch(error => {
        appMessageService.publish(error.detail, "error");
      });
  });

  // handleFormikSubmitTask
  const handleFormikSubmitTask = useAsyncTask(
    async (
      client: Client,
      values: UpdateRecommissionSheetCommand,
      formikHelpers: FormikHelpers<any>
    ) => {
      formikHelpers.setSubmitting(true);
      return client
        .recommissionSheet_Update(recommissionId, values)
        .then((response) => {
          appMessageService.publish("Saved", "success");
        })
        .catch((error) => {
          appMessageService.publish(error.title, "warning");
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
          formikHelpers.resetForm({ values: values }); // force reset dirty state from Formik
        });
    }
  );

  //TODO: save form
  const handleFormikSubmit = (
    values: RecommissionSheetModel,
    formikHelpers: FormikHelpers<any>
  ) => {
    handleFormikSubmitTask.run(values, formikHelpers);
  };

  const renderSidebarItem = (item: RecommissionSheetItem, index: number) => {
    return (
      <Grid item key={index}>
        <Typography
          variant="subtitle1"
          gutterBottom
          key={item.nodeUniqueCode}
          onClick={() => onSelectedRecommission(item, index)}
          style={
            index === selectedFormProperty?.index
              ? { backgroundColor: grey, borderRadius: 2 }
              : { backgroundColor: "" }
          }
        >
          {item.location}
        </Typography>
        <Divider light />
      </Grid>
    );
  };

  //TODO: recommission form
  const RecommissionForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={recommission!}
        validateOnBlur={false}
        validateOnChange={false}
        // validationSchema={validationSchema}
        onSubmit={handleFormikSubmit}
      >
        {({ values, dirty, isSubmitting, setFieldValue, errors }) => (
          <Form id="recommissionForm">
            {/* Button Heading */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Fab onClick={() => history.goBack()} size="small">
                <Tooltip title="Back" aria-label="Back">
                  <ArrowBack />
                </Tooltip>
              </Fab>
              <div className={classes.buttons}>
                <Button
                  onClick={completeRecommissionTask.run}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={isCompleted}
                >
                  Complete
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Box>
            <Paper>
              <Container maxWidth="lg">
                <Box pb={5}>
                  <Typography variant="h4" gutterBottom>
                    Recommission Fulfillment
                  </Typography>
                </Box>

                <Grid container spacing={3} direction="row">
                  {/* Form Section */}
                  <Grid item xs={12} sm>
                    <FieldArray name="recommissionSheetItems">
                      {() => (
                        <>
                          {values?.recommissionSheetItems!.map((r, i) => {
                              return (
                                <Box key={i}>
                                  <Paper>
                                    <Box p={3}>
                                      <Grid item xs={12} sm={12}>
                                        <Box display="flex" flexDirection="row" pt={1} pb={1} alignItems="center">
                                          <Typography>{r?.location}</Typography>
                                          <Typography>&nbsp;-&nbsp;</Typography>
                                          <Typography variant="subtitle1"> {r?.recommissionItem.name}</Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                        <Box mb={3}>
                                          <TextFieldWithValidation
                                            as={TextField}
                                            name={`recommissionSheetItems[${i}].recommissionItem.action.findings`}
                                            value={
                                              r.recommissionItem.action
                                                ?.findings || ""
                                            }
                                            type="text"
                                            placeholder="Findings"
                                            label="Findings"
                                            fullWidth
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                        <Box mb={3}>
                                          <TextFieldWithValidation
                                            as={TextField}
                                            name={`recommissionSheetItems[${i}].recommissionItem.action.changedToSpec`}
                                            value={
                                              r?.recommissionItem.action
                                                ?.changedToSpec || ""
                                            }
                                            type="text"
                                            placeholder="Changed to specification"
                                            label="Changed to specification"
                                            fullWidth
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                        <Box mb={3}>
                                          <MultiLineTextTextFieldWithValidation
                                            as={TextField}
                                            name={`recommissionSheetItems[${i}].recommissionItem.action.notes`}
                                            value={
                                              r?.recommissionItem.action
                                                ?.notes || ""
                                            }
                                            type="text"
                                            placeholder="Additional note"
                                            label="Additional note"
                                            fullWidth
                                          />
                                        </Box>
                                      </Grid>
                                      {/* Attachment Section */}
                                      <AttachmentUploader
                                        surveyId={surveyId}
                                        name={`recommissionSheetItems[${i}].recommissionItem.action.attachments`}
                                        setFieldValue={setFieldValue}
                                        value={
                                          r.recommissionItem.action?.attachments
                                        }
                                        key={`recommissionSheetItems[${i}].recommissionItem.${r.recommissionItem.uniqueCode}`}
                                      />
                                    </Box>
                                  </Paper>
                                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                                  <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                </Box>
                              );
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <LoadingProgress
        isLoading={recommission?.recommissionSheetItems! === undefined}
      >
        {recommission?.recommissionSheetItems!.length! > 0 ? (
          RecommissionForm()
        ) : (
          <Container
            style={{ display: "flex", justifyContent: "center" }}
            maxWidth="lg"
          >
            <Box margin={0} width={700}>
              <Paper>
                <Box p={8}>
                  <Typography variant="h6">
                    There is no recommission item created{" "}
                    <Link to={`/recommission/${recommissionId}`}>
                      {" "}
                      Add recommission Items{" "}
                    </Link>
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Container>
        )}
      </LoadingProgress>
    </div>
  );
};

export default RecommissionFulfillment;
