import * as React from "react";
import UserProfile from "../../components/User/UserProfile";
import { ChangePasswordCommand, Client, UpdateUserCommand} from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";
import useAuth from "../../hooks/useAuth";
import appMessageService from "../../services/AppMessageService";

const UserProfilePage: React.FC = () => {
  const user = useAuth();
  const [userinfo, setUserInfo] = React.useState({
    firstName: "",
    lastName: "",
  });
  const password = { currentPassword: "", newPassword: "", confirmPassword: "" }; 

  React.useEffect(() => {
    getUserByIdTask.run().catch(()=> console.log(getUserByIdTask.validationErrors, "Errorororroro"));
    // eslint-disable-next-line
  }, [setUserInfo]);

  const getUserByIdTask = useAsyncTask(async (client: Client) => {
   await client
      .user_Get(user.user?.id!)
      .then((response) => {
        setUserInfo({
          firstName: response.result?.firstName!,
          lastName: response.result?.lastName!,
        });
      })
  });

  const updateUserTask = useAsyncTask(async (client: Client, command: UpdateUserCommand) => {
   await client
      .user_Update(user.user?.id!, command)
      .then((response) => {
        appMessageService.publish("Saved", "success");
        // user updated
      })
      .catch((error) => {
        // handleGeneralErrorMessage(error.title);
        appMessageService.publish(error.title, "warning");
      })
      .finally(() => {});
  });

  const handleFormikUserProfile = (values: any) => {
    const command = {
      id: user.user?.id,
      firstName: values.firstName,
      lastName: values.lastName,
    } as UpdateUserCommand;

    console.log("command", command);

    setUserInfo({
      //State do not update
      firstName: command.firstName,
      lastName: command.lastName,
    });

    updateUserTask.run(command).catch(() => console.table(updateUserTask.validationErrors));
  };

  const changePasswordTask = useAsyncTask(async (client: Client, command: ChangePasswordCommand) => {
   await client
      .user_ChangePassword(command)
      .then((response) => {
        if (response.result.succeeded) {
          appMessageService.publish("Password Changed", "success");
        } else {
          appMessageService.publish(response.result.errors![0].description!, "warning");
        }
        // user updated
      });
  });

  const handleFormSubmit = (values: any) => {
    const command = {
      id: user.user?.id,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    } as ChangePasswordCommand;

    changePasswordTask.run(command).catch(() => console.table(changePasswordTask.validationErrors));
  };

  return (
    <UserProfile
      userinfo={userinfo}
      password={password}
      handleFormikUserProfile={handleFormikUserProfile}
      handleFormSubmit={handleFormSubmit}
    />
  );
};

export default UserProfilePage;
