import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const auth = useAuth();
  if (!auth.user?.token) {
    const renderComponent = () => (
      <Redirect to={{ pathname: "/login", state: {} }} />
    );
    return <Route {...props} component={renderComponent} />;
  } else {
    return <Route {...props} />;
  }
};
