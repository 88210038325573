import { 
  Container,
  Fab,
  Grid,
  IconButton, 
  Paper, 
  Tooltip, 
} from "@material-ui/core";
import { FormikHelpers } from "formik";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { Client, UpdateRecommissionSheetCommand } from "../../../helpers/ApiResources";
import useAsyncTask from "../../../hooks/useAsyncTask"; 
import appMessageService from "../../../services/AppMessageService";
import RecommissionForm from "./RecommissionForm";
import PreviewRecommissionSheetDialog from "../../Dialogs/PreviewRecommissionSheetDialog";
import { ArrowBack, RemoveRedEye } from "@material-ui/icons";

const RecommissionSheetEditor: React.FC = () => {  
  let history = useHistory();
  let params: any = useParams();
  const recommissionId:string= params.id;

  // set recommission data
  const [recommission, setRecommission] = React.useState<UpdateRecommissionSheetCommand | undefined>(undefined);

  //open close dialog
  const [open, setOpen] = React.useState<boolean>(false);

  //ResourceOwnerId
  const [surveyId, setSurveyId] = React.useState<string>();

  React.useEffect(() => {
    loadRecommissionTask.run();
    // eslint-disable-next-line
  }, [recommissionId]);

  const loadRecommissionTask = useAsyncTask(async (client: Client) => { 
   return client
      .recommissionSheet_Get(recommissionId)
      .then((response) => { 
        setSurveyId(response.result.ResourceOwnerId);  
        setRecommission(response.result);
      })
  }); 

  const handleFormikSubmitTask = useAsyncTask(
    async (client: Client, values: UpdateRecommissionSheetCommand, formikHelpers: FormikHelpers<any>) => {
      formikHelpers.setSubmitting(true);
      return client
        .recommissionSheet_Update(recommissionId, values)
        .then((response) => { 
          appMessageService.publish("Saved", "success");
        }) 
        .finally(() => formikHelpers.setSubmitting(false));
    }
  );

  //TODO: handle submit form
  const handleFormikSubmit = (values: UpdateRecommissionSheetCommand, formikHelpers: FormikHelpers<any>) => {
    handleFormikSubmitTask.run(values, formikHelpers);
  };

  // Handle open dialog for Recommission
  const previewRecommissionHandler = () => {
    // history.push("/previewrecommission/" + resourceOwnerId);
    setOpen(!open);
  };

  return (
    <>
      <Grid container justify="space-between">
          <Fab onClick={() => history.goBack()} size="small">
            <Tooltip title="Back" aria-label="Back">
              <ArrowBack />
            </Tooltip>
          </Fab>  
        <Grid item>
          <Tooltip title="Preview Recommission Routine" aria-label="Preview Recommission Routine">
            <IconButton onClick={() => previewRecommissionHandler()}>
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Paper>
        <Container maxWidth="lg"> 
        <>
        {
          surveyId &&
          <RecommissionForm data={recommission!} handleFormikSubmit={handleFormikSubmit} surveyId={surveyId} />
        }
        </>
        </Container>
      </Paper>

        {
          surveyId &&
              <PreviewRecommissionSheetDialog open={open} onClose={setOpen} resourceOwnerId={surveyId} />
        }
    </>
  );
};

export default RecommissionSheetEditor;
