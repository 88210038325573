import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Query, QueryResult } from "material-table";
import { isInstanceOfLaborTypeSummaryModel } from "../../helpers/guards/laborType";

import TableCustom from "../controls/TableCustom";
import Edit from "@material-ui/icons/Edit";
import useAsyncTask from "../../hooks/useAsyncTask";
import { Client } from "../../helpers/ApiResources";
import appMessageService from "../../services/AppMessageService";

type LaborTypeDataTableProps = {};

const LaborTypeDataTable: React.FC<LaborTypeDataTableProps> = () => {
  const history = useHistory();
  const tableRef = useRef<any>();

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    if (isInstanceOfLaborTypeSummaryModel(data)) {
      history.push("/laborTypes/" + data.id);
    }
  };

  const loadRemotellData = useAsyncTask(
    async (
      client: Client,
      query: Query<object>
    ): Promise<QueryResult<object>> => {
      return new Promise<QueryResult<object>>(async (resolve, reject) => {
        const sortColumn: string =
          query.orderBy !== undefined ? String(query.orderBy.field) : "";
        const sortDirection =
          String(query.orderDirection) === "" ||
          String(query.orderDirection) === "asc"
            ? "Ascending"
            : "Descending";
        client
          .laborType_Search(
            query.page * query.pageSize,
            query.pageSize,
            sortColumn,
            sortDirection,
            query.search
          )
          .then((response) => {
            console.log("response.result.data", response.result.data);
            resolve({
              data: response.result.data,
              page: response.result.page, // current page
              totalCount: response.result.totalRecords!,
            });
          })
          .catch((error) => {
            appMessageService.publish("Error loading data", "error");
            console.log(error);
            reject("Error loading data");
          });
      });
    }
  );

  //TODO; func get data, resolve with data or not
  const getData = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise<QueryResult<object>>((resolve, reject) => {
      loadRemotellData
        .run(query)
        .then((response) => {
          resolve({
            data: response?.data!,
            page: response?.page!, // current page
            totalCount: response?.totalCount!,
          });
        })
        .catch((error) => {
          // TODO (SS): handle error
          console.log(error);
          reject("Error occurred while retrieving date.");
        });
    });
  };

  return (
    <TableCustom
      tableRef={tableRef}
      title="Labor Types"
      columns={[
        { title: "Name", field: "name" },
        { title: "Rate", field: "rate" },
      ]}
      data={getData}
      moreAction={[
        {
          icon: () => <Edit />,
          tooltip: "Edit",
          onClick: handleEdit,
        },
      ]}
    />
  );
};

export default LaborTypeDataTable;
