import { Box, Button, Fab, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn"; 
import MaterialTable, { Icons } from "material-table";
import * as React from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { Client, MaintenanceScheduleFlatListModel } from "../../../helpers/ApiResources";
import useAsyncTask from "../../../hooks/useAsyncTask";
import ExitToAppIcon from "@material-ui/icons/ExitToApp"; 
import { ArrowBack } from "@material-ui/icons";

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}; 

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

 

const MaintenanceScheduleViewDataTable: React.FC = () => { 
 
  const params: any = useParams();
  const classes = useStyles(); 
  let history = useHistory();

  const [maintenanceSheetData, setMaintenanceSheetData] = React.useState<MaintenanceScheduleFlatListModel>(); 

  React.useEffect(() => {
    loadMaintenanceScheduleTask.run().catch(() => console.table(loadMaintenanceScheduleTask.validationErrors)); 
    // eslint-disable-next-line
  }, []);

  const loadMaintenanceScheduleTask = useAsyncTask(async (client: Client) => {
    client
      .maintenanceSchedule_GetFlatList(params.id)
      .then((response) => {   
        setMaintenanceSheetData(response.result)
    })
  });
  

  return (
     <>
        <Box pb={2}>
            <Fab onClick={() => history.goBack()} size="small">
              <Tooltip title="Back" aria-label="Back">
                <ArrowBack />
              </Tooltip>
            </Fab>  
       </Box>
      <MaterialTable
        icons={tableIcons}
        title="Maintainance Schedule"
        columns={[
          { title: "location", editable: "never", field: "location" },
          { title: "Maintenance Item Name", editable: "never", field: "maintenanceItem.name" },
          { title: "Task", editable: "never", field: "maintenanceItem.task" },
          { title: "Number Of Minutes Required", editable: "never", field: "maintenanceItem.numberOfMinutesRequired" },
          { title: "Labor Type", editable: "never", field: "maintenanceItem.laborType.name" },
          { title: "Cost", editable: "never", field: "maintenanceItem.cost" },
          { title: "Maintenance Interval", editable: "never", field: "maintenanceItem.maintenanceInterval" }, 
        ]}
        data={maintenanceSheetData?.maintenanceSheetItems!} 
        options={{
          paging: false,
          exportButton: true,
          headerStyle: {
            fontWeight: "bold",
          },
        }}
      />
      {/* <pre>{JSON.stringify(maintenanceSheetData, null, 2)}</pre> */}
    </>
  );
};

export default MaintenanceScheduleViewDataTable;
