import * as React from "react";
import { Box, Button, Fab, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Schedule from "@material-ui/icons/Schedule";
import { Link as RouterLink } from "react-router-dom";
import SurveyDataTable from "../../components/SurveyEditor/SurveyDataTable";
import SurveyMaintenanceTabBar from "./SurveyMaintenanceTabBar";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const SurveyEditorListPage: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  let history = useHistory();

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Fab onClick={() => history.push("/sites")} size="small">
          <Tooltip title="Back" aria-label="Back">
            <ArrowBack />
          </Tooltip>
        </Fab>

        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={"/sites/" + params.siteId + "/surveys/create"}
          startIcon={<AddIcon />}
        >
          New Survey
        </Button>
      </Box>

      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <SurveyDataTable />
        </div>
      </div>
    </>
  );
};

export default SurveyEditorListPage;
