import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
} from "@material-ui/core";

type OptionsProps = {
  options: string[];
  label: string;
  required: boolean;
  name: string;
  instruction: string;
  value: string | undefined;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const CheckBoxChoice: React.FC<OptionsProps> = (props) => {
  // set value (props.value from saved choices, convert to array)
  const [value, setValue] = React.useState<string>(props.value!);

  const handleChange = () => {
    let setCheckBoxState = "";

    if (value === "Yes") {
      setCheckBoxState = "No";
    } else {
      setCheckBoxState = "Yes";
    }

    setValue(setCheckBoxState);
    props.setValue(props.name, setCheckBoxState, true);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={props.name}
            value={props.value}
            onChange={handleChange}
            checked={value === "Yes"}
            required={props.required}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label={props.label}
      />
      <FormHelperText>{props.instruction}</FormHelperText>
    </>
  );
};

export default CheckBoxChoice;
