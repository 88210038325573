import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Client } from "../../helpers/ApiResources";
import { isInstanceOfDefinitionSummaryModel } from "../../helpers/guards/definition";
import { Query, QueryResult } from "material-table";
import useAsyncTask from "../../hooks/useAsyncTask";
import TableCustom from "../controls/TableCustom";
import Edit from "@material-ui/icons/Edit";

type DefinitionDataTableProps = {};

const DefinitionDataTable: React.FC<DefinitionDataTableProps> = () => {
  const history = useHistory();
  const tableRef = useRef();

  const loadDefinitionsTask = useAsyncTask(
    async (client: Client, q: Query<object>) => {
      const sortColumn = q.orderBy !== undefined ? String(q.orderBy.field) : "";
      const sortDirection =
        String(q.orderDirection) === "" || String(q.orderDirection) === "asc"
          ? "Ascending"
          : "Descending";

      return await client.definition_Search(
        q.page * q.pageSize,
        q.pageSize,
        sortColumn,
        sortDirection,
        q.search
      );
    }
  );

  const loadDefinitions = (q: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadDefinitionsTask
        .run(q)
        .then((response) => {
          if (response) {
            const r = response.result;
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords,
            });
          }
        })
        .catch((error) =>
          reject("An error occurred while retrieving the definition data.")
        );
    });
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    if (isInstanceOfDefinitionSummaryModel(data)) {
      history.push("/definitions/" + data.id);
    }
  };

  return (
    <TableCustom
      tableRef={tableRef}
      title="Definitions"
      columns={[{ title: "Name", field: "name" }]}
      data={loadDefinitions}
      moreAction={[
        {
          icon: () => <Edit />,
          tooltip: "Edit",
          onClick: handleEdit,
        },
      ]}
    />
  );
};

export default DefinitionDataTable;
