import * as React from 'react';
import useClient from '../../hooks/useClient';
import { UpdateDefinitionCommand } from '../../helpers/ApiResources';
import { useParams } from 'react-router-dom';
import DefinitionEditor, { IDefinitionCommand } from '../../components/Definition/DefinitionEditor';

const DefinitionEditPage: React.FC = () => {

  const client = useClient();
  let params: any = useParams();

  const handleSubmit = (id: string, data: any) => {
    let updateCommand: UpdateDefinitionCommand = { ...data, id: id }
    return client.definition_Update(id, updateCommand);
  }

  return (
    <DefinitionEditor definitionId={params.id} handleSumbit={handleSubmit} />
  );
}

export default DefinitionEditPage;