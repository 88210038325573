import * as React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import { MaintenanceSheetItem, MaintenanceSheetModel, UpdateMaintenanceSheetCommand } from "../../helpers/ApiResources";
import MultiLineTextTextFieldWithValidation from "../controls/MultiLineTextTextFieldWithValidation";
import AttachmentUploader from "../common/AttachmentUploader";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    minWidth: 220,
  },
  button: {
    margin: "4px",
  },
}));

type CreateUserProps = {
  maintenanceId: string | undefined;
  maintenanceSheetItem: MaintenanceSheetItem | undefined;
  maintenanceSheetData: UpdateMaintenanceSheetCommand | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMaintenanceSheetData: React.Dispatch<React.SetStateAction<UpdateMaintenanceSheetCommand | undefined>>;
};

const UpdateMaintainanceSheetItemDialog: React.FC<CreateUserProps> = (props) => {
  const classes = useStyles();

  // Handler Close for Dialog
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleFormikSubmit = (values: any) => {
    const dataUpdate = [...props.maintenanceSheetData?.items!];
    const index = values.tableData.id;
    dataUpdate[index] = values;
    props.setMaintenanceSheetData(
      (prevState) =>
        ({
          ...prevState,
          items: dataUpdate,
        } as MaintenanceSheetModel)
    );

    handleClose();
  };

  const renderUserForm = (item: MaintenanceSheetItem) => {
    return (
      <Formik
        enableReinitialize // required in order to reinitialize after adding more questions
        initialValues={item}
        onSubmit={handleFormikSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form id="userForm">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextFieldWithValidation
                    as={TextField}
                    name="location"
                    value={values.location}
                    type="input"
                    placeholder="Location"
                    label="Location"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiLineTextTextFieldWithValidation
                    as={TextField}
                    name="lastName"
                    value={values.maintenanceItem.name}
                    type="input"
                    placeholder="Maintenance item info"
                    label="Maintenance item info"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWithValidation
                    as={TextField}
                    name="maintenanceInterval"
                    value={values.maintenanceItem.maintenanceInterval}
                    type="input"
                    placeholder="Maintenance interval"
                    label="Maintenance interval *"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography> Is maintenance complete? </Typography>
                  <Checkbox
                    name="maintenanceItem.action.isCompleted"
                    value={values.maintenanceItem.action?.isCompleted}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      setFieldValue("maintenanceItem.action.isCompleted", checked, true)
                    }
                    checked={values.maintenanceItem.action?.isCompleted}                    
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography> Extra work required? </Typography>
                  <Checkbox
                    name="maintenanceItem.action.isExtraWorkRequired"
                    value={values.maintenanceItem.action?.isExtraWorkRequired}
                    checked={values.maintenanceItem.action?.isExtraWorkRequired}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      setFieldValue("maintenanceItem.action.isExtraWorkRequired", checked, true)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiLineTextTextFieldWithValidation
                    as={TextField}
                    name="maintenanceItem.action.extraWorkItem"
                    value={values.maintenanceItem.action?.extraWorkItem}
                    type="input"
                    placeholder="Extra Work Item"
                    label="Extra Work Item *"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultiLineTextTextFieldWithValidation
                    as={TextField}
                    name="maintenanceItem.action.notes"
                    value={values.maintenanceItem.action?.notes}
                    type="input"
                    placeholder="Notes"
                    label="Notes *"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} style={{ padding: "20px" }}>
                  <AttachmentUploader
                    surveyId={props.maintenanceId!}
                    name={`maintenanceItem.action.attachments`}
                    setFieldValue={setFieldValue}
                    value={values.maintenanceItem.action?.attachments!}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Grid container  direction="row" justify='flex-end'>
                      <Button className={classes.button} onClick={handleClose} variant="contained" disableElevation>Cancel</Button>
                      <Button className={classes.button} type="submit" variant="contained" color="primary">
                        Save
                      </Button>
                    </Grid>
                  </DialogActions>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Grid>
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      className={classes.dialogRoot}
    >
      <DialogTitle id="form-dialog-title">Update Maintenance Item</DialogTitle>
      <DialogContent style={{ width: "700px", padding: "30px" }}>
        {renderUserForm(props.maintenanceSheetItem!)}
      </DialogContent>
    </Dialog>
    </Grid>
  );
};

export default UpdateMaintainanceSheetItemDialog;
