import * as React from 'react';
import UserList from "../../components/User/ManageUsers/UserList";
 

const UserListPage:React.FC = ()=> {
    return (
        <UserList />
    )
}

export default UserListPage;