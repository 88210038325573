
export type MessagsType = "success" | "info" | "warning" | "error";

export type MessagePayload = {
  type: MessagsType,
  message: string
};

interface CallbackItem {
  callback: (m: MessagePayload) => void,
  subscriptionId: number
}

class AppMessageService {
    _callbacks: CallbackItem[] = [];
    _nextSubscriptionId = 0;
    
    publish(message: string, messageType: MessagsType) {
        this.notifySubscribers({type: messageType, message: message});
    }

    subscribe(callback: (m: MessagePayload) => void) {
        this._nextSubscriptionId++;
        this._callbacks.push({ callback, subscriptionId: this._nextSubscriptionId });
        return this._nextSubscriptionId;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscriptionId === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }
        //this._callbacks = this._callbacks.splice(subscriptionIndex[0].index, 1); todo
    }

    notifySubscribers(payload: MessagePayload) {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(payload);
        }
    }

}

const appMessageService = new AppMessageService();

export default appMessageService;