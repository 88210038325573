import React, { useState } from "react";
import { useParams } from "react-router";
import { LaborTypeSummaryModel } from "../../helpers/ApiResources";

import useClient from "../../hooks/useClient";
import LoadingProgress from "../../components/common/LoadingProgress";
import LaborTypeForm from "../../components/LaborType/LaborTypeForm";

const LaborTypeEditPage: React.FC = () => {
  const client = useClient();
  const params: any = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [laborType, setLaborType] = useState<LaborTypeSummaryModel>();

  React.useEffect(() => {
    if (params.id) {
      client.laborType_Get(params.id).then((response) => {
        setLaborType(response.result);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [client, params.id]);

  return (
    <LoadingProgress isLoading={isLoading}>
      <LaborTypeForm laborType={laborType} />
    </LoadingProgress>
  );
};

export default LaborTypeEditPage;
