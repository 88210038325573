import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Copyright : React.FC = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} 
        <Link color="inherit" href="http://www.pbncontrols.com/">
          &nbsp; Kalder
        </Link>
      </Typography>
    );
  }

export default Copyright