import React from "react";
import MaintenanceScheduleEditor from "../../../components/SurveyEditor/MaintenanceSchedule/MaintenanceScheduleEditor";
import { useParams } from "react-router";

const MaintenanceScheduleEditPage = () => {
  const params: any = useParams();
  const maintenanceScheduleId: string = params.id;
  return <MaintenanceScheduleEditor maintenanceId={maintenanceScheduleId} />;
};

export default MaintenanceScheduleEditPage;
