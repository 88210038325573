import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { Icons } from "material-table";
import * as React from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { Client, SiteSurveyRecommissionSheetPreviewModel } from "../../../helpers/ApiResources";
import useAsyncTask from "../../../hooks/useAsyncTask";

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const RecommissionViewDataTable: React.FC = () => {
  const params: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  
  const [recommissionSheetData, setRecommissionSheet] = React.useState<SiteSurveyRecommissionSheetPreviewModel>();

  React.useEffect(() => {
    loadRecommissionSheetTask.run();
    // eslint-disable-next-line
  }, []);

  const loadRecommissionSheetTask = useAsyncTask(async (client: Client) => {
    client
      .siteSurvey_GetRecommissionSheetFlatListPreview(params.id)
      .then((response) => {
        console.log(response);
        setRecommissionSheet(response.result);
      });
  });

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.box}>
          <IconButton onClick={()=> history.goBack()}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </div>
      <MaterialTable
        icons={tableIcons}
        title={recommissionSheetData?.name! + " Recommission Routine"}
        columns={[
          { title: "Location", field: "location" },
          { title: "Recommission Item", field: "recommissionItem.name" },
          { title: "Findings", field: "recommissionItem.findings" },
          { title: "Changed Specification", editable: "never", field: "recommissionItem.changedToSpec" },
          { title: "Attachments", editable: "never", field: "recommissionItem.attachments" },
        ]}
        data={recommissionSheetData?.recommissionSheetItems!}
        options={{
          paging: false,
          exportButton: true,
          headerStyle: {
            fontWeight: "bold",
          },
        }}
      />
      <pre>{JSON.stringify(recommissionSheetData, null, 2)}</pre>
    </>
  );
};

export default RecommissionViewDataTable;
