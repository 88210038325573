import * as React from "react";
import { AuthenticateCommand, Client, TokenResponse } from "../../helpers/ApiResources";

export type AuthContextProps = {
  user: TokenResponse | null;
  client: Client;
  errorMessage: string | null;
  loading: boolean;
  loginUser: (user: AuthenticateCommand) => void;
  logOut: (error?: string) => void;
  refreshToken:() => void;
};
const AuthContext = React.createContext<AuthContextProps>(undefined!);

export default AuthContext;
