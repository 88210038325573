import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

type LoadingProgressProps = {
  isLoading: boolean
  marginTop: number
  size: number
  color?: "primary" | "secondary" | "inherit" | undefined
}

const LoadingProgressCustom: React.FC<LoadingProgressProps> = (props) =>
  props.isLoading ?
    <Box display="flex" justifyContent="center" alignItems="center"  marginTop={`${props.marginTop}em`} >
      <CircularProgress size={props.size}  color={props.color}/>
    </Box>
    : <>{props.children}</>

export default LoadingProgressCustom;