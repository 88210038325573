import { makeStyles } from '@material-ui/core';
import React from 'react' 
import LoginForm from '../../components/Authentication/LoginForm'


const useStyles = makeStyles({
    root: {
        display:'flex',
        justifyContent:'center', 
        alignItems:'center',
        marginTop:"10vh"
    },
  
});


const LoginPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LoginForm />
        </div>
    )
}

export default LoginPage
