import React, { useRef } from "react";
import { useHistory } from "react-router";
import { Client } from "../../helpers/ApiResources";
import { Query, QueryResult } from "material-table";
import { useParams } from "react-router";

import Edit from "@material-ui/icons/Edit";
import Copy from "@material-ui/icons/FileCopy";
import Schedule from "@material-ui/icons/Schedule";
import ListAltIcon from "@material-ui/icons/ListAlt";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooks from "@material-ui/icons/Assignment";
import SiteSurveyCloneDialog from "../Dialogs/SiteSurveyCloneDialog";
import GenerateMaintenanceDialog from "../Dialogs/GenerateMaintenanceDialog";
import GenerateRecommissionDialog from "../Dialogs/GenerateRecommissionDialog";
import useAsyncTask from "../../hooks/useAsyncTask";
import TableCustom from "../controls/TableCustom";

const SurveyDataTable = () => {
  const history = useHistory();
  const tableRef = useRef<any>();
  const params: any = useParams();

  // State to manage Dialog Two-Way Binding(managing)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [
    openGenerateMaintainanceDialog,
    setOpenGenerateMaintainanceDialog,
  ] = React.useState<boolean>(false);
  const [
    openGenerateRecommissionDialog,
    setOpenGenerateRecommissionDialog,
  ] = React.useState<boolean>(false);

  const [surveyName, setSurveyName] = React.useState<string>("");
  const [surveryId, setSurveryId] = React.useState<string>("");

  const loadSurveyTask = useAsyncTask(async (c: Client, q: Query<object>) => {
    const d = String(q.orderDirection);
    const sortBy = q.orderBy !== undefined ? String(q.orderBy.field) : "";
    const sort = d === "" || d === "asc" ? "Ascending" : "Descending";

    return await c.siteSurvey_Search(
      params.siteId,
      q.page * q.pageSize,
      q.pageSize,
      sortBy,
      sort,
      q.search
    );
  });

  const loadSurveys = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadSurveyTask
        .run(query)
        .then((response) => {
          if (response) {
            const r = response.result;
            resolve({
              data: r.data,
              page: r.page,
              totalCount: r.totalRecords!,
            });
          }
        })
        .catch((error) => reject(error));
    });
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    console.log("data", data, params.siteId);
    history.push("/sites/" + params.siteId + "/surveys/" + data.id);
  };

  // handleClone Opens Dialog, set SurveyName and id across Dialog Component
  const handleClone = (event: React.MouseEvent<unknown>, data: any) => {
    setSurveyName(data.name);
    setSurveryId(data.id);
    setOpenDialog(true);
  };

  // Handle open dialog for Maintainance
  const generateMaintainanceHandler = (
    event: React.MouseEvent<unknown>,
    data: any
  ) => {
    setSurveyName(data.name);
    setOpenGenerateMaintainanceDialog(true);
    setSurveryId(data.id);
  };

  const generateRecommissionHandler = (
    event: React.MouseEvent<unknown>,
    data: any
  ) => {
    setSurveyName(data.name);
    setOpenGenerateRecommissionDialog(true);
    setSurveryId(data.id);
  };

  // Route to fulfillment page
  const handleFulfillment = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("/surveys/" + data.id + "/fulfill");
  };

  const handleSurveyOptions = (event: React.MouseEvent<unknown>, data: any) => {
    history.push("/sites/" + params.siteId + "/maintenanceschedule/" + data.id);
  };

  return (
    <>
      <TableCustom
        tableRef={tableRef}
        title="Site Surveys"
        columns={[
          { title: "Name", field: "name" },
          { title: "Status", field: "siteSurveyStatus" },
        ]}
        data={loadSurveys}
        moreAction={[
          {
            icon: () => <Edit />,
            tooltip: "Edit Survey",
            onClick: handleEdit,
          },
          {
            icon: () => <Copy />,
            tooltip: "Clone Survey",
            onClick: handleClone,
          },
          {
            icon: () => <LibraryBooks />,
            tooltip: "Fulfill Survey",
            onClick: handleFulfillment,
          },
          {
            icon: () => <Schedule />,
            tooltip: "Generate Schedule",
            onClick: generateMaintainanceHandler,
          },
          {
            icon: () => <ListAltIcon />,
            tooltip: "Generate Recommission Routine",
            onClick: generateRecommissionHandler,
          },
          {
            icon: () => <InfoIcon />,
            tooltip: "Survey Details",
            onClick: handleSurveyOptions,
          },
        ]}
      />

      <SiteSurveyCloneDialog
        surveyName={surveyName}
        surveryId={surveryId}
        open={openDialog}
        onClose={setOpenDialog}
      />

      <GenerateMaintenanceDialog
        surveyName={surveyName}
        siteSurveryId={surveryId}
        open={openGenerateMaintainanceDialog}
        onClose={setOpenGenerateMaintainanceDialog}
      />

      <GenerateRecommissionDialog
        surveyName={surveyName}
        siteSurveryId={surveryId}
        open={openGenerateRecommissionDialog}
        onClose={setOpenGenerateRecommissionDialog}
      />
    </>
  );
};

export default SurveyDataTable;
