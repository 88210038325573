import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select, 
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 160,
    },
    selectEmpty: {
      marginTop: theme.spacing(2), 
    },
  })
);

type OptionsProps = {
  options: string[];
  label: string;
  required?: boolean;
  name: string;
  instruction?: string;
  defaultValue?: string;
  width?:number
  value: string | undefined;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const SingleChoice: React.FC<OptionsProps> = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value!);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue((event.target as HTMLInputElement).value);
    props.setValue(props.name, event.target.value, true);
  };

  return (
    <FormControl
      required={props.required} 
      component="fieldset"
      style={{ minWidth: 100 }}
    >
      <InputLabel id={`single-choice${props.name}`}>{props.label}</InputLabel>
      <Select
        id={props.name}
        labelId={`single-choice${props.name}`}
        value={value || ""}
        onChange={handleChange}
        defaultValue=""
        name={props.name}  
        className={classes.selectEmpty}
        style={{
            width:props.width
        }}
      > 
        {props.options.map((d, i) => (
          <MenuItem value={d} key={i}>
            {d}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.instruction}</FormHelperText>
    </FormControl>
  );
};

export default SingleChoice;
