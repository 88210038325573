import React, {  } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField"; 
import { Client, GenerateMaintenanceScheduleCommand } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";

type GenerateMaintenanceDialogProps = {
  surveyName: string;
  siteSurveryId: string;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
const GenerateMaintenanceDialog: React.FC<GenerateMaintenanceDialogProps> = (props) => {
  // API requests
  // const client = useClient();

  let history = useHistory();

  // useState Hooks
  const [maintenanceTitle, setMaintenanceTitle] = React.useState<string>("");
  const [clientErrorMessage, setServerErrorMessage] = React.useState<string>("");
  const [hasError, setHasError] = React.useState<boolean>(false);

  // Handler Close for Dialog
  const handleClose = () => {
    props.onClose(false);
    setHasError(false);
    setServerErrorMessage("");
  };

  // Manage TextField States
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaintenanceTitle(event?.target.value);
    setHasError(false);
    setServerErrorMessage("");
  };

  const submitDialogTask =  useAsyncTask(
    async (client: Client) => {  
      
    const command: GenerateMaintenanceScheduleCommand = {
      siteSurveyId: props.siteSurveryId!,
      name: maintenanceTitle,
    };
 
    // API call then() => Success
    client
      .maintenanceSchedule_Create(command)
      .then((res) => {
        history.push("/maintenanceschedule/" + res.result.id);
      })
      .catch((error) => {
        // Error from server
        setHasError(true);
        setServerErrorMessage(error.title);
      });
    });

  const handleDialogSubmit = () => {
    // todo: check if surveyName has value, initialize CloneSiteSurveyCommand and
    // set properties(props, updateded surveyName), Call API request

    if (!maintenanceTitle) {
      setHasError(true);
      setServerErrorMessage("Schedule name is required");
      return;
    }

    //TODO: refactor code 
    submitDialogTask.run();

  };

    return (
        
            <Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>{props.surveyName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Generate Schedule for {props.surveyName}? 
                    </DialogContentText>
                    <TextField id="name" 
                    autoFocus 
                    label="Schedule Name *" 
                    type="text" 
                    placeholder="Schedule Name" 
                    fullWidth 
                    onChange={onChangeTextField} 
                    error={hasError} 
                    helperText={clientErrorMessage} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" disableElevation>
                        Cancel
                        </Button>
                    <Button onClick={handleDialogSubmit} variant="contained" color="primary">
                        Generate
                        </Button>
                </DialogActions>
            </Dialog> 
    )
}

export default GenerateMaintenanceDialog;
