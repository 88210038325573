import * as React from "react";
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import CheckBoxChoice from "../../controls/CheckBoxChoice";
import MultipleChoice from "../../controls/MultipleChoice";
import SingleChoice from "../../controls/SingleChoice";
import TextFieldWithValidation from "../../controls/TextFieldWithValidation";
import AttachmentUploader from "../../common/AttachmentUploader";
import MultiLineTextTextFieldWithValidation from "../../controls/MultiLineTextTextFieldWithValidation";
import { Node } from "../../../helpers/ApiResources";
import { PropsWithChildren } from "react";
import PromptIfDirty from "../../common/PromptIfDirty";

type FulfillProps = {
  selectedNodeCode: string | undefined;
  selectedNode: Node | null;
  handleFormikSubmit: (data: Node, formikHelpers: FormikHelpers<any>) => void;
  completeSurvey: () => Promise<void>;  
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
  isSurveyComplete: boolean
  surveyId: string;
};

const useStyles = makeStyles((theme) => ({
  fulfillFormBody: {},
  answersPaperSpacing: {},
}));

const FulfillForm: React.FC<FulfillProps> = ({
  selectedNodeCode,
  selectedNode,
  handleFormikSubmit,
  setIsDirtyState,
  surveyId,
  isSurveyComplete,
  completeSurvey
}: PropsWithChildren<FulfillProps>) => {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={selectedNode!}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(data, formikHelpers) =>
        handleFormikSubmit(data, formikHelpers)
      }
    >
      {({ submitForm, values, errors, dirty, isSubmitting, setFieldValue }) => {
        return (
          <Form onChange={() => setIsDirtyState(dirty)}>
            <PromptIfDirty />
            {/* Header */}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h4">
                {selectedNode?.displayName ?? selectedNode?.definition.name}
              </Typography>
              <Grid
                item
                container
                xs={6}
                direction="row"
                justify="flex-end"
                spacing={2}
              >
                {/* <Grid item>
                  <Button
                    onClick={() => onCancelHandler(dirty)}
                    variant="contained"
                    color="default"
                  >
                    Cancel
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={completeSurvey}
                    disabled={!isSurveyComplete}
                  >
                    Complete
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onChange={submitForm}
                    disabled={!dirty || isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* Questions Body */}
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Typography variant="subtitle2" color="error">
                {dirty
                  ? "You must save before moving to the next fulfillment!"
                  : null}
              </Typography>
            </Grid>

            <Grid container direction="column" spacing={2}>
              <FieldArray name="questions">
                {() => (
                  <>
                    {values?.definition!.questions.map((d, i) => (
                      <Grid item key={d.uniqueCode}>
                        <Box p={1}>
                          {d.questionType === "Text" ? (
                            <Box width={300}>
                              <TextFieldWithValidation
                                as={TextField}
                                required={d.required}
                                name={`definition.questions.${i}.answer`}
                                value={d.answer || ""}
                                type="input"
                                placeholder="Answer"
                                label={d.label}
                                helperText={d.instruction}
                                fullWidth
                                key={`definition.questions.${d.uniqueCode}.answer`}
                              />
                            </Box>
                          ) : d.questionType === "Number" ? (
                            <Box width={300}>
                              <TextFieldWithValidation
                                as={TextField}
                                required={d.required}
                                name={`definition.questions.${i}.answer`}
                                value={d.answer || ""}
                                type="number"
                                placeholder="Answer"
                                label={d.label}
                                helperText={d.instruction}
                                fullWidth
                                key={`definition.questions.${d.uniqueCode}.answer`}
                              />
                            </Box>
                          ) : d.questionType === "Date" ? (
                            <Box width={300}>
                              <TextFieldWithValidation
                                as={TextField}
                                required={d.required}
                                name={`definition.questions.${i}.answer`}
                                value={d.answer || "2021-01-22"}
                                type="date"
                                label={d.label}
                                helperText={d.instruction}
                                fullWidth
                                key={`definition.questions.${d.uniqueCode}.answer`}
                              />
                            </Box>
                          ) : d.questionType === "Checkbox" ? (
                            <>
                              <CheckBoxChoice
                                label={d.label}
                                instruction={d.instruction!}
                                name={`definition.questions.${i}.answer`}
                                options={d.options}
                                setValue={setFieldValue}
                                value={d.answer}
                                required={d.required!}
                                key={`definition.questions.${d.uniqueCode}`}
                              />
                            </>
                          ) : d.questionType === "MultipleChoice" ? (
                            <>
                              <MultipleChoice
                                label={d.label}
                                instruction={d.instruction!}
                                name={`definition.questions.${i}.answer`}
                                options={d.options}
                                setValue={setFieldValue}
                                value={d.answer}
                                required={d.required!}
                                key={`definition.questions.${d.uniqueCode}`}
                              />
                            </>
                          ) : d.questionType === "SingleChoice" ? (
                            <>
                              <SingleChoice
                                label={d.label}
                                name={`definition.questions.${i}.answer`}
                                options={d.options}
                                setValue={setFieldValue}
                                instruction={d.instruction!}
                                required={d.required!}
                                value={d.answer}
                                key={`definition.questions.${d.uniqueCode}`}
                              />
                            </>
                          ) : d.questionType === "Attachment" ? (
                            <>
                              <Typography variant="h5">{d.label}</Typography>
                              <Typography
                                variant="subtitle1"
                                key={d.uniqueCode}
                              >
                                Instruction: {d.instruction}
                              </Typography>
                              <AttachmentUploader
                                surveyId={surveyId}
                                name={`definition.questions.${i}.attachments`}
                                setFieldValue={setFieldValue}
                                value={d.attachments!}
                                key={`definition.questions.${d.uniqueCode}`}
                              />
                            </>
                          ) : d.questionType === "MultiLineText" ? (
                            <MultiLineTextTextFieldWithValidation
                              required={d.required}
                              name={`definition.questions.${i}.answer`}
                              value={d.answer}
                              type="text"
                              placeholder="Answer"
                              label={d.label}
                              instruction={d.instruction!}
                              fullWidth
                              key={`definition.questions.${d.uniqueCode}`}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FulfillForm;
