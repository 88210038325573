import * as React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import MaintenanceSheetDataTable from "../../../../components/SurveyEditor/MaintenanceSchedule/MaintenanceSheet/MaintenanceSheetDataTable"
import SurveyMaintenanceTabBar from '../../SurveyMaintenanceTabBar';
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const MaintenanceSheetListPage: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  const surveyId = params.id;
  const siteId = params.siteId

  return (
    <>
      <SurveyMaintenanceTabBar surveyId={surveyId} siteId={siteId}/>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <MaintenanceSheetDataTable surveyId={surveyId} />
        </div>
      </div>
    </>
  );
};

export default MaintenanceSheetListPage;
