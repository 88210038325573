import * as React from "react";

import { Box, Button, makeStyles } from "@material-ui/core";
import { Query, QueryResult } from "material-table";

import { useHistory } from "react-router-dom";
import { Client, UserSummaryModel } from "../../../helpers/ApiResources";
import { Add, Delete, Edit } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";

import appMessageService from "../../../services/AppMessageService";
import useAsyncTask from "../../../hooks/useAsyncTask";
import TableCustom from "../../controls/TableCustom";

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
}));

const UserList: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirm();
  const tableRef = React.useRef<any>();

  const [users, setUsers] = React.useState<UserSummaryModel[]>([]);

  const loadUserTask = useAsyncTask(
    async (client: Client, query: Query<UserSummaryModel>) => {
      const sortColumn: string =
        query.orderBy !== undefined ? String(query.orderBy.field) : "";

      const sortDirection =
        String(query.orderDirection) === "" ||
        String(query.orderDirection) === "asc"
          ? "Ascending"
          : "Descending";

      return await client.user_Search(
        query.search,
        query.page * query.pageSize,
        query.pageSize,
        sortColumn,
        sortDirection
      );
    }
  );

  const deleteUserTask = useAsyncTask(async (client: Client, id: string) => {
    await client.user_Delete(id).then(() => {
      appMessageService.publish("Account deleted", "info");
    });
  });

  const loadUsers = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      loadUserTask.run(query).then((response) => {
        if (response) {
          const r = response.result;
          setUsers(r.data);
          resolve({
            data: r.data,
            page: r.page,
            totalCount: r.totalRecords!,
          });
        }
      });
    });
  };

  const handleEdit = (event: React.MouseEvent<unknown>, data: any) => {
    history.push({
      pathname: `/users/${data.id}`,
      state: { user: data },
    });
  };

  const deleteUser = (event: React.MouseEvent<unknown>, data: any) => {
    confirm({
      title: "Please Confirm",
      description: "Are you sure that you want to delete this user?",
      confirmationText: "YES",
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
        disableElevation: true,
      },
      cancellationButtonProps: { variant: "contained", disableElevation: true },
    })
      .then(() => {
        const updateUserState = users.filter((user) => user.id !== data.id);
        setUsers([...updateUserState]);

        deleteUserTask
          .run(data.id)
          .catch(() => console.table(deleteUserTask.validationErrors));
      })
      // If user cancels
      .catch();
  };

  return (
    <>
      <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push("/users/create")}
            startIcon={<Add />}
          >
            Create User
          </Button>
        </Box>
      </div>

      <TableCustom
        tableRef={tableRef}
        title="Users"
        columns={[
          { title: "First Name", field: "firstName" },
          { title: "Last Name", field: "lastName" },
          { title: "Full Name", field: "fullName" },
          { title: "Email", field: "email" },
          { title: "Role", field: "role" },
          { title: "Active", field: "isEnabled" },
        ]}
        data={loadUsers}
        moreAction={[
          {
            icon: () => <Edit />,
            tooltip: "Edit User",
            onClick: handleEdit,
          },
          {
            icon: () => <Delete />,
            tooltip: "Delete User",
            onClick: deleteUser,
          },
        ]}
      />
    </>
  );
};

export default UserList;
