import { UpdateSiteSurveyCommand, Node, DefinitionModel, Question } from "../../helpers/ApiResources";
import { findNode, findParentNode, removeChild } from "../../helpers/NodeHelper";
import { v4 as uuidv4 } from "uuid";
import clone from "clone";
export interface SurveyState extends UpdateSiteSurveyCommand {
    isDirty: boolean;
}
type SurveyAction =
    | { type: "UPDATE_SURVEY", payload: { node: Node }}
    | { type: "UPDATE_SURVEY_NAME", payload: { name: string }}
    | { type: "UPDATE_ROOT", payload: { definition: DefinitionModel }}
    | { type: "ADD_NODE", payload: { parentNodeUniqueCode: string, definition: DefinitionModel } }
    | { type: "REMOVE_NODE", payload: { nodeUniqueCode: string } }
    | { type: "REMOVE_QUESTION", payload: { nodeUniqueCode: string, questionUniqueCode: string } }
    | { type: "RENAME_NODE", payload: { nodeUniqueCode: string, displayName: string } }
    | { type: "ADD_QUESTION", payload: { nodeUniqueCode: string } }
    | { type: "UPDATE_QUESTION", payload: { nodeUniqueCode: string, question: Question } }
    | { type: "UPDATE_QUESTION_POSITION", payload: { nodeUniqueCode: string, question: Question[] } }
    | { type: "RESET_ISDIRTY" };

export default function SurveyReducer(state: SurveyState, action: SurveyAction) : SurveyState {
    let survey: SurveyState = clone(state);
    switch (action.type) {
        case "UPDATE_SURVEY":
            survey.node = action.payload.node;
            survey.isDirty = true;
            return survey;
        case "UPDATE_SURVEY_NAME":
            survey.name = action.payload.name;
            survey.isDirty = true;
            return survey;
        case "UPDATE_ROOT":
            survey.node = {
                uniqueCode: uuidv4(),
                definition: action.payload.definition,
                displayName: action.payload.definition.name,
                children: [],
            }
            survey.isDirty = true;
            return survey;
        case "ADD_NODE":
            let parentNode = findNode(survey.node!, action.payload.parentNodeUniqueCode);
            let matchingCount = parentNode!.children
                .map(c => c.definition.name)
                .filter(d => d === action.payload.definition.name)
                .length;
            let append = matchingCount === 0 ? "" : ` ${matchingCount + 1}`;
            parentNode!.children.push({
                uniqueCode: uuidv4(),
                definition: action.payload.definition,
                displayName: action.payload.definition.name + append,
                children: []});
            survey.isDirty = true;
            return survey;
        case "REMOVE_NODE":
            let p = findParentNode(survey.node!, action.payload.nodeUniqueCode);
            removeChild(p!, action.payload.nodeUniqueCode);
            survey.isDirty = true;
            return survey;
        case "REMOVE_QUESTION":
            let questionNode = findNode(survey.node!, action.payload.nodeUniqueCode);
            questionNode!.definition.questions = [...questionNode!.definition.questions.filter(q => q.uniqueCode !== action.payload.questionUniqueCode)];
            survey.isDirty = true;
            return survey;
        case "RENAME_NODE":
            let nodeToRename = findNode(survey.node!, action.payload.nodeUniqueCode);
            nodeToRename!.displayName = action.payload.displayName;
            survey.isDirty = true;
            return survey;
        case "ADD_QUESTION":
            let node = findNode(survey.node!, action.payload.nodeUniqueCode);
            node!.definition.questions.push({
                uniqueCode: uuidv4(),
                position: node!.definition.questions.length,
                label: "",
                instruction: "",
                questionType: "Text",
                required: false,
                options: []
            });
            survey.isDirty = true;
            return survey;
        case "UPDATE_QUESTION":
            let qnode = findNode(survey.node!, action.payload.nodeUniqueCode);
            qnode!.definition.questions = qnode!.definition.questions
                .map(q => 
                    q.uniqueCode === action.payload.question.uniqueCode 
                    ? action.payload.question 
                    : q);
            survey.isDirty = true;
            return survey;
        case "UPDATE_QUESTION_POSITION":
            let qnodep = findNode(survey.node!, action.payload.nodeUniqueCode);
            qnodep!.definition.questions = [...action.payload.question];
            survey.isDirty = true;
            return survey;
        case "RESET_ISDIRTY":
            survey.isDirty = false;
            return survey;
    }
}


