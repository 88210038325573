import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import Axios from "axios";
import fileDownload from "js-file-download";
import { Attachment, UploadedAttachment } from "../../helpers/ApiResources";
import { blue } from "@material-ui/core/colors";
import { PhotoAlbum, PictureAsPdf } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterContent from "../common/CenterContent";
import useAuth from "../../hooks/useAuth";
import appMessageService from "../../services/AppMessageService";

//const baseUrl = "https://insite-api-staging.azurewebsites.net";
//const baseUrl = "https://localhost:44358";
const baseUrl = "https://kalderinsiteapi.azurewebsites.net";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  progress: {
    width: "100%",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: blue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type AttachmentUploaderProps = {
  surveyId: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  name: string;
  value?: Attachment[];
};

export default function AttachmentUploader({
  surveyId,
  setFieldValue,
  name,
  value,
}: AttachmentUploaderProps) {
  const classes = useStyles();
  const auth = useAuth();
  const token = auth.user!.token!;
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);

  // Request interceptor for AXIOS API calls
  Axios.interceptors.request.use(config => {
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    return config;
  });

  Axios.interceptors.response.use(
    (res) => res,
    (error) => {
      // Do something with response error
      if (error.response.status === 401) {
        appMessageService.publish("Your session expired", "error");
        auth.logOut();
      }
      console.error(error.response);
      return Promise.reject(error);
    }
  );

  function appendAttachments(newAttachments: Attachment[]) {
    const existingAttachments = value ?? [];
    const updated = [...existingAttachments, ...newAttachments];
    setFieldValue(name, updated, true);
  }

  function uploadMultipleFiles() {
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("Files", file);
    });
    formData.append("SiteSurveyId", surveyId);

    Axios.post(baseUrl + "/api/Attachment/Multiple", formData)
      .then((response) => {
        appendAttachments(response.data.uploadedAttachments.map((a: UploadedAttachment) => a.attachment));
        setFilesToUpload([]);
      })
      .catch(error => {
        appMessageService.publish(error.message ?? "Error uploading", "error");
      });
  }

  function uploadSingleFile() {
    const formData = new FormData();
    formData.append("File", filesToUpload[0]);
    formData.append("SiteSurveyId", surveyId);
    Axios.post(baseUrl + "/api/Attachment", formData)
      .then((response) => {
        appendAttachments([response.data.resource.attachment]);
        setFilesToUpload([]);
      })
      .catch((error) => {
        appMessageService.publish(error.message ?? "Error uploading", "error");
      });
  }

  function Upload() {
    if (filesToUpload.length === 1) {
      uploadSingleFile();
    } else if (filesToUpload.length > 1) {
      uploadMultipleFiles();
    }
  };

  const DownloadAttachment = (filePath: string, originalFileName: string) => {
    console.log(filePath);
    Axios.get(baseUrl + "/api/Attachment/Download", {
      params: {
        filePath: filePath,
        originalFileName: originalFileName,
      },
      responseType: "blob",
    })
      .then((response) => {
        fileDownload(response.data, originalFileName); // download files
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const RemoveFile = (fileName: string) => {
    var array = [...filesToUpload];
    const filteredFiles = array.filter((x) => x.name !== fileName);
    setFilesToUpload(filteredFiles);
  };

  const attachmentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      let newFiles: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        newFiles.push(fileList[i]); // probably a better way to do this, but FileList is not a typical array
      }
      setFilesToUpload([...filesToUpload, ...newFiles]);
    }
  };

  const renderFileCard = (file: File) => {
    return (
      <Grid item xs={6}>
        <Card style={{ height: 340 }}>
          <CardHeader
            action={
              <IconButton
                aria-label="Delete"
                onClick={() => RemoveFile(file.name)}
              >
                <DeleteIcon />
              </IconButton>
            }
            title={file.name}
            subheader={file.type}
          />
          {file.type.match("image") && (
            // <CardMedia
            //   style={{height: 240}}
            //   image={file && URL.createObjectURL(file)}
            //   title={file.name}
            // />
            <CardContent>
              <CenterContent>
                <img
                  src={file && URL.createObjectURL(file)}
                  height={200}
                  alt=""
                  style={{ borderRadius: "10px" }}
                />
              </CenterContent>
            </CardContent>
          )}
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <input
            accept="image/*,application/pdf"
            className={classes.input}
            id={name!}
            multiple
            type="file"
            name={name!}
            onChange={(event) => attachmentHandler(event)}
          />
          <label htmlFor={name!}>
            <Button variant="contained" component="span">
              Select...
            </Button>
          </label>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={Upload}
            disabled={!filesToUpload || filesToUpload.length == 0}
          >
            Upload Files
          </Button>
        </Grid>
      </Grid>

      {/* Selected Files thumbnail */}
      <Grid container direction="row" spacing={2}>
        {(filesToUpload as File[]).map((file, i) => (
          <React.Fragment key={i}>{renderFileCard(file)}</React.Fragment>
        ))}
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        {value &&
          value.map((data, i) => (
            <Grid key={i} item>
              <Paper
                style={{ textAlign: "center", padding: "10px" }}
                elevation={3}
              >
                <Grid item>
                  <Typography variant="caption">
                    {data?.originalFileName!.split(".", 1)}
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton
                    aria-label="Delete"
                    onClick={() =>
                      DownloadAttachment(
                        data?.filePath!,
                        data?.originalFileName!
                      )
                    }
                  >
                    {data?.fileType!.match("png|image|jpeg|jfif|jpg") ? (
                      <PhotoAlbum fontSize="large" />
                    ) : (
                        <PictureAsPdf fontSize="large" />
                      )}
                  </IconButton>
                </Grid>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );

};
