import * as React from "react";
import { useHistory } from "react-router";
import RecommissionSheetEditor from "../../../components/SurveyEditor/Recommission/RecommissionSheetEditor";

const RecommissionSheetEditPage: React.FC = () => {
  return (
    <>
      <RecommissionSheetEditor />
    </>
  );
};

export default RecommissionSheetEditPage;
