import * as React from "react";
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Formik, Form, FormikHelpers, Field } from "formik";
import TextFieldWithValidation from "../controls/TextFieldWithValidation";
import * as Yup from "yup";
import { Client, CreateUserCommand, SiteSurveySummaryModel } from "../../helpers/ApiResources";
import useAsyncTask from "../../hooks/useAsyncTask";
import { useHistory } from "react-router-dom";
import appMessageService from "../../services/AppMessageService";
import { ArrowBack } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import PromptIfDirty from "../common/PromptIfDirty";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    minWidth: 220,
  },
}));

const CreateUser: React.FC = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const userInitialValue = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    siteSurveyIds: [],
  };

  const [serverErrorMessage, setServerErrorMessage] = React.useState<string>("");
  const [siteSurvey, setSiteSurvey] = React.useState<SiteSurveySummaryModel[]>([]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    role: Yup.string().required("Role is required"),
  });

  React.useEffect(() => {
    loadSurveyTask.run().catch(() => console.table(loadSurveyTask.validationErrors));
    // eslint-disable-next-line
  }, []);

  const loadSurveyTask = useAsyncTask(async (client: Client) => {
    await client.siteSurvey_GetAll().then((response) => {
      setSiteSurvey([...response.result]);
    });
  });

  const CreatUserTask = useAsyncTask(
    async (client: Client, command: CreateUserCommand, formikHelpers: FormikHelpers<any>) => {
      client
        .user_Create(command)
        .then((response) => {
          // user created
          appMessageService.publish("Saved", "success");
          setServerErrorMessage("");
          formikHelpers.resetForm();
          history.push("/users");
        })
        .catch((error) => {
          if (error.errors.Email && error.errors.Email.length > 0) {
            setServerErrorMessage(error.errors.Email[0]);
            appMessageService.publish(error.errors.Email[0], "warning");
          }
        })
        .finally(() => {});
    }
  );

  const CreatUserHandle =(values: CreateUserCommand, formikHelpers: FormikHelpers<any>) => { 
    CreatUserTask.run(values,formikHelpers)
  }

  const renderUserForm = (usersData: CreateUserCommand) => {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={usersData}
          validationSchema={validationSchema}
          onSubmit={CreatUserHandle}
        >
          {({ values, dirty, submitForm, errors, isValid }) => {
            return (
              <>
                <Box pb={2}>
                  <Grid container item xs={12} justify="space-between">                   
                    <Fab onClick={() => history.goBack()} size="small">
                      <Tooltip title="Back" aria-label="Back">
                        <ArrowBack />
                      </Tooltip>
                    </Fab>

                    <Button type="submit" variant="contained" color="primary" onClick={submitForm} disabled={!dirty || !isValid}>
                      Save
                    </Button>
                  </Grid>
                </Box> 
                <Form id="userForm"> 
                  <PromptIfDirty />
                  <Paper>
                    <div style={{ padding: 20 }}>
                      <Typography variant="subtitle1"> Create User </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <TextFieldWithValidation
                            as={TextField}
                            name="firstName"
                            value={values.firstName}
                            type="input"
                            placeholder="First Name"
                            label="First Name *"
                            fullWidth
                          />
                        </Grid>  
                        <Grid item xs={12} sm={12}>
                          <TextFieldWithValidation
                            as={TextField}
                            name="lastName"
                            value={values.lastName}
                            type="input"
                            placeholder="Last Name"
                            label="Last Name *"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <TextFieldWithValidation
                            as={TextField}
                            name="email"
                            value={values.email}
                            type="input"
                            placeholder="Email"
                            label="Email *"
                            fullWidth
                          />
                          <p style={{ color: "red" }}>{serverErrorMessage}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl} error={!!errors.role}>
                            <InputLabel id="demo-simple-select-error-label">User Role</InputLabel>

                            <Field as={Select} name="role" value={values.role} type="select" label="Role" >
                              <MenuItem value="Administrator">Administrator</MenuItem>
                              <MenuItem value="Technician">Technician</MenuItem>
                            </Field>
                            <FormHelperText>{errors.role}</FormHelperText>
                          </FormControl>
                        </Grid> 
                      </Grid> 
                    </div>
                  </Paper> 
                </Form> 
              </>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <Grid container style={{width:"50%", margin:"auto"}}>
      <Grid>{renderUserForm(userInitialValue!)}</Grid>
    </Grid>
  );
};

export default CreateUser;
