import { makeStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import React from 'react' 
import { useHistory } from 'react-router';
import SetAccountEditor from '../../components/User/SetAccountEditor'
import { Client, SetPasswordCommand } from '../../helpers/ApiResources';
import useAsyncTask from '../../hooks/useAsyncTask';
import appMessageService from '../../services/AppMessageService';


const useStyles = makeStyles({
    root: {
        display:'flex',
        justifyContent:'center', 
        alignItems:'center',
        marginTop:"10vh"
    },
  
});


const ResetPasswordPage = () => {
    const classes = useStyles();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("t");
    const history = useHistory();

    const resetPasswordTask = useAsyncTask(async (client: Client, values: any, formikHelpers: FormikHelpers<any>) => {
        const command = {
          token: token,
          email: values.email,
          password: values.confirmPassword,
        } as SetPasswordCommand;
     
        formikHelpers.setSubmitting(true);
        client
          .user_ResetPassword(command)
          .then((response) => {
            console.log("response.result", response.result.succeeded);
            if (response.result.succeeded) {
              appMessageService.publish("Password set", "success");
              history.push("/login");
            } else {
              appMessageService.publish("Something went wrong!", "warning");
            }
            // user updated
          })
          .catch((error) => {
            console.log(error, "An Error occurred");
            appMessageService.publish("Something went wrong!", "warning");
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      });
    
      const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        resetPasswordTask.run(values, formikHelpers).catch(() => console.table(resetPasswordTask.validationErrors));
      };
    return (
        <div className={classes.root}>
            <SetAccountEditor handleFormSubmit={handleFormSubmit} title="Reset Password"/>
        </div>
    )
}

export default ResetPasswordPage
