import { useCallback, useState } from "react";
import { Client, ProblemDetails, ValidationProblemDetails } from '../helpers/ApiResources'
import appMessageService from "../services/AppMessageService"; 
import useClient from "./useClient";
import useAuth from "./useAuth";

export type TaskStatus = "IDLE" | "PROCESSING" | "ERROR" | "SUCCESS";

function useAsyncTask<T extends any[], R = any>(task: (client: Client, ...args: T) => Promise<R>) {
  const [status, setStatus] = useState<TaskStatus>("IDLE");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[]; } | null>(null);
  const client = useClient(); 
  const auth = useAuth();
  // https://reactjs.org/docs/hooks-reference.html#usecallback
  const run = useCallback(async (...arg: T) => {
    setStatus("PROCESSING"); 
    try { 
      const resp: R = await task(client, ...arg);
      console.log(resp, "resppppppp");
      setStatus("SUCCESS");
      return resp;
    } catch (error) {
      // ApiException
      let message = error?.response?.data?.error?.message || error.message;
      setMessage(message);
      appMessageService.publish(message, "error");
      setStatus("ERROR"); 
      if (error.status === 400) { 
        const problemDetails: ProblemDetails = error;
        if(problemDetails.detail) {
          setMessage(problemDetails.detail);
        }
        let validationProblem: ValidationProblemDetails = error;
        setValidationErrors(validationProblem.errors);
        console.log("Validation Problem Errors: ");
        console.table(validationProblem.errors);
        appMessageService.publish("Validation error", "error");
      } else if (error.status === 401) {
        console.log(error, "401 REFRESH TOKEN"); // TODO: Could try refresh token here
        auth.refreshToken();
        // auth.logOut();        
        // appMessageService.publish("You are not authorized, please login", "error");
      } else if (error.status === 403) {
        let message403 = "Forbidden";
        if (error && error.response) {
          try {
            const obj = JSON.parse(error.response);
            if (obj && obj.Message) {
              message403 = "Forbidden: " + obj.Message;
            }
          } catch { }
        }
        appMessageService.publish(message403, "error");
      } else if (error.status === 404) {
        appMessageService.publish("Not Found", "warning");
      } else if (error.status === 500) {
        appMessageService.publish("A server error occured", "error");
        //throw error;
      } 
      // else {
      //   appMessageService.publish("A weird error occured", "error");
      //   auth.refreshToken(); // this is a hack until back-end sends a status code for Search API
      //   //throw error;
      // }
    }
  }, [task, client]); // https://reactjs.org/docs/hooks-reference.html#usecallback
  // I'm not sure this is useful if we update the callback every time the function updates
  // Perhaps we should put arg... here

  const reset = useCallback(() => {
    setMessage("");
    setStatus("IDLE");
  }, []);

  return {
    run,
    status,
    message,
    validationErrors,
    reset,
  };
}

export default useAsyncTask;