import * as React from "react";
import AuthReducer, { AuthState } from "./AuthReducer";
import AuthContext from "./AuthContext";
import {
  LOGIN_USER_SUCCESSFULL,
  ERROR_MESSAGE,
  MAKING_REQUEST,
  REQUEST_COMPLETED,
  LOG_OUT,
  LOAD_USER,
  REFRESH_TOKEN,
} from "../types/UserTypes";
import {
  Client,
  AuthenticateCommand,
  TokenResponse,
  RefreshTokenCommand,
} from "../../helpers/ApiResources";
import { useHistory } from "react-router-dom";
import appMessageService from "../../services/AppMessageService";

type UserActionProps = {
  children: React.ReactNode;
};

const AuthContextProvider = (props: UserActionProps) => {
  // const baseUrl: string = "https://insite-api-staging.azurewebsites.net";
  // const baseUrl: string = "https://localhost:5001";
  // const baseUrl: string = "https://localhost:44358";
  const baseUrl: string = "https://kalderinsiteapi.azurewebsites.net";
  const initialClient = new Client({ token: null }, baseUrl);

  const history = useHistory();

  const getFromLocalStorage = () => {
    const tokenResponseString = localStorage.getItem("TOKEN_RESPONSE");
    if (tokenResponseString) {
      const tokenResponse = JSON.parse(tokenResponseString) as TokenResponse;
      return tokenResponse;
    }
    return null;
  }

  const saveToLocalStorage = (tokenResponse: TokenResponse) => {
    localStorage.setItem("TOKEN_RESPONSE", JSON.stringify(tokenResponse));
  }

  const clearlocalStorage = () => {
    localStorage.removeItem("TOKEN_RESPONSE");
  }

  const createClient = (token: string) => {
    return new Client({ token: token }, baseUrl)
  }

  const initialUser = getFromLocalStorage();
  const initialState: AuthState = {
    user: initialUser,
    client: initialUser ? createClient(initialUser.token!) : initialClient,
    errorMessage: "",
    loading: false,
  };

  const [authState, dispatch] = React.useReducer(AuthReducer, initialState);

  const login = (command: AuthenticateCommand) => {
    dispatch({ type: MAKING_REQUEST });
    authState.client
      .user_Authenticate(command)
      .then((response) => {
        if (response.result.role === "Administrator") {
          dispatch({
            type: LOGIN_USER_SUCCESSFULL,
            payload: {
              user: response.result,
              client: new Client({ token: response.result.token! }, baseUrl),
            },
          });
          saveToLocalStorage(response.result);
        } else {
          appMessageService.publish("You are not an Administrator", "error");
        }
      })
      .catch((err) => {
        appMessageService.publish("Login error: " + err.title, "error");
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.title,
        });
      })
      .finally(() => {
        dispatch({ type: REQUEST_COMPLETED });
        history.push("/surveys");
      });
  };

  // refresh token request
  //TODO: getting token works fine, but it returns as an error
  const refreshToken = () => {  
    dispatch({ type: REFRESH_TOKEN });
    const command = { refreshToken: initialUser?.refreshToken } as RefreshTokenCommand; 
    authState.client.user_RefreshToken(command).then((response) => { 
      dispatch({
        type: LOAD_USER,
        payload:{
          user: response.result,
          client: new Client({token: response.result.token!}, baseUrl)
        }              
      });
      saveToLocalStorage(response.result);      
      history.go(0);
    }).catch((error) => {
      console.log(error, " error refresh token func");       
      logOut("You are not authorized, please login"); //if token fail logout app state.
    })
  };
   

  // Logout app state
  const logOut = (error?: string) => {
    // dispatch request on removing all tokens
    dispatch({ type: MAKING_REQUEST });
    clearlocalStorage();
    dispatch({
      type: LOG_OUT,
      payload: initialClient,
    });
    if(error) appMessageService.publish(error, "error"); //if error publish appMessageService
    dispatch({ type: REQUEST_COMPLETED });
  };

  return (
    <AuthContext.Provider
      value={{
        user: authState.user,
        loginUser: login,
        logOut,
        refreshToken,
        client: authState.client,
        errorMessage: authState?.errorMessage,
        loading: authState.loading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
