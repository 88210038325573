import { Box } from '@material-ui/core';
import * as React from 'react';
import ForgotPassword from "../../components/User/ForgotPassword";
 

const ForgotPasswordPage:React.FC = ()=> {
    return (
        <Box marginTop="10vh">
            <ForgotPassword />
        </Box>
        
    )
}

export default ForgotPasswordPage;