import React from "react";
import { useParams } from "react-router";
import MaintenanceSheetEditorDataTable from "../../../../components/SurveyEditor/MaintenanceSchedule/MaintenanceSheet/MaintenanceSheetEditorDataTable";
import { Client, UpdateMaintenanceSheetCommand } from "../../../../helpers/ApiResources";
import useAsyncTask from "../../../../hooks/useAsyncTask";
import appMessageService from "../../../../services/AppMessageService";

type MaintenanceScheduleEditorProps = {
  maintenanceId?: string;
};

const MaintenanceSheetPage: React.FC<MaintenanceScheduleEditorProps> = () => {
  const params: any = useParams();

  const updateMaintenanceSheetTask = useAsyncTask(async (client: Client, data: UpdateMaintenanceSheetCommand) => {
    client
      .maintenanceSheet_Update(data.id, data)
      .then(() => {
        appMessageService.publish("Saved", "success")
      })
  });

  const onSubmit = (data: UpdateMaintenanceSheetCommand) => {
    updateMaintenanceSheetTask.run(data);
  };

  return (
    <>
      <MaintenanceSheetEditorDataTable
        maintenanceId={params.id}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default MaintenanceSheetPage;
