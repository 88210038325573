import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import LaborTypeDataTable from "../../components/LaborType/LaborTypeDataTable";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const LaborTypeListPage: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      {/* TODO : add breadcrumbs */}
      {/* TODO : move the toolbar to its own component */}
      <div className={classes.root}>
        <Box className={classes.box}>
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/laborTypes/create"
            startIcon={<Add />}
          >
            New Labor Type
          </Button>
        </Box>
      </div>

      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <LaborTypeDataTable />
        </div>
      </div>
    </>
  );
};

export default LaborTypeListPage;
